import Customer from "@/models/customer";
import { Notification, NotificationEnhanced } from "@/models/notification";
import * as _ from "lodash";
import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import { Command, Payload, BusinessKey, BusinessKeys } from "@/models/command";
import * as js2parser from "js2xmlparser";
import {
  DeleteForSite,
  DeleteNotificationPreferenceVO,
  DeleteProductInstance,
  NotificationPreferenceVO,
  ProductInstanceVO,
  SiteInstanceVO,
  TnInstanceVO
} from "@/models/notificationPreference";
import config from "./config";
import { NotificationType } from "@/models/notification";
import { EmpMessage } from "@/models/empMessage";

export default class Utility {
  static changeGroupNotifications(
    masterNotifications: Array<NotificationEnhanced>,
    notificationsOriginalGroup: Array<Notification>
  ) {
    for (const grpNotif of notificationsOriginalGroup) {
      let flag = false;
      for (const masterNotif of masterNotifications) {
        if (
          _.isEqual(masterNotif.notification.value, grpNotif.value) &&
          _.isEqual(masterNotif.notification.type, grpNotif.type)
        ) {
          flag = true;
          break;
        }
      }

      if (!flag) {
        grpNotif.defaulted = false;
      }
    }
    return notificationsOriginalGroup;
  }
  static getMergedNotifications(
    masterNotifications: Array<NotificationEnhanced>,
    groupNotifications: Array<Notification>
  ) {
    const notifications: Array<NotificationEnhanced> = [];
    for (const groupNotification of groupNotifications) {
      notifications.push(new NotificationEnhanced(groupNotification, false));
    }
    for (const masterNotification of masterNotifications) {
      let flag = false;
      for (const groupNotification of groupNotifications) {
        if (
          _.isEqual(
            masterNotification.notification.value,
            groupNotification.value
          ) &&
          _.isEqual(
            masterNotification.notification.type,
            groupNotification.type
          )
        ) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        notifications.push(masterNotification);
      }
    }

    return notifications;
  }

  static getNotificationsWithGroupNotificationsSelected(
    masterNotifications: Array<NotificationEnhanced>,
    groupNotifications: Array<Notification>
  ): Array<NotificationEnhanced> {
    const groupNotificationsIntersect: Array<NotificationEnhanced> = [];
    for (const masterNotification of masterNotifications) {
      for (const groupNotification of groupNotifications) {
        if (_.isEqual(masterNotification.notification, groupNotification)) {
          groupNotificationsIntersect.push(masterNotification);
          break;
        }
      }
    }
    return groupNotificationsIntersect;
  }

  static getPayloadforSaveGroupNotifications(
    groupId: string,
    groupName: string,
    notificationsEnhanced: Array<NotificationEnhanced>
  ) {
    const notifications: Notification[] = [];
    for (const notificationEnhanced of notificationsEnhanced) {
      notifications.push(notificationEnhanced.notification);
    }
    return [
      {
        siteId: groupId,
        siteName: groupName,
        notifications: notifications
      }
    ];
  }

  static syntaxHighlight(jsonObj: any) {
    let json = "";
    if (typeof jsonObj != "string") {
      json = JSON.stringify(jsonObj, undefined, 2);
    }
    json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function(match) {
        let cls = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (/null/.test(match)) {
          cls = "null";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      }
    );
  }

  preparePayloadDataForEMPMsgForApply(
    customer: Customer,
    selectedProductId: string,
    selectedProductType: string,
    applyNotificationToAll: string,
    replyEmail: string,
    siteId: string,
    applyNotificationToAllForSite: string,
    tns: string[],
    telephoneNumbers: string,
    selectedTns: [],
    notifications: Notification[],
    showGroupTns: boolean,
    userId: string,
    groupName: string
  ) {
    let id: string;
    const env = config.env;

    if (customer != null && customer != undefined && customer.id != undefined) {
      id = customer.id;
    } else {
      id = selectedProductId;
    }

    const businessKeys = this.getBusinessKeys(
      id,
      selectedProductId,
      selectedProductType,
      userId
    );
    const tnInstances: TnInstanceVO[] = [];
    let tnInstance: TnInstanceVO;

    if (
      showGroupTns &&
      selectedTns != null &&
      selectedTns != undefined &&
      !_.isEmpty(selectedTns)
    ) {
      for (const key in selectedTns) {
        tnInstance = new TnInstanceVO(selectedTns[key], notifications);
        tnInstances[key] = tnInstance;
      }
    } else {
      if (tns != null && tns != undefined) {
        for (const i in tns) {
          tnInstance = new TnInstanceVO(tns[i], notifications);

          tnInstances[i] = tnInstance;
        }
      }

      if (telephoneNumbers != null && telephoneNumbers != "") {
        const tnsArray = telephoneNumbers.split(";").filter(item => item);
        let j = 0;
        if (!_.isEmpty(tnInstances)) {
          j = tnInstances.length;
        }
        if (tnsArray != null && tnsArray != undefined && !_.isEmpty(tnsArray)) {
          for (const key in tnsArray) {
            if (!tns.includes(tnsArray[key])) {
              tnInstance = new TnInstanceVO(tnsArray[key], notifications);
              tnInstances[j] = tnInstance;
              j++;
            }
          }
        } else {
          if (!tns.includes(telephoneNumbers)) {
            tnInstance = new TnInstanceVO(telephoneNumbers, notifications);
            tnInstances[j] = tnInstance;
          }
        }
      }
    }

    const sites: SiteInstanceVO[] = [
      new SiteInstanceVO(
        applyNotificationToAllForSite,
        siteId,
        groupName,
        tnInstances
      )
    ];
    const productInstance = new ProductInstanceVO(
      selectedProductId,
      applyNotificationToAll,
      replyEmail,
      sites
    );
    const notificationPreferenceVO = new NotificationPreferenceVO(
      id,
      productInstance
    );
    const payloadObj = new Payload(JSON.stringify(notificationPreferenceVO));
    const timeVal = new Date().toISOString();
    const messageType = `${Utility.getConfig(env, "VUE_APP_MSG_TYPE")}`;
    const commandType = `${Utility.getConfig(env, "VUE_APP_COMMAND_TYPE")}`;
    const messageSource = `${Utility.getConfig(env, "VUE_APP_MSG_SOURCE")}`;

    const commandVal = new Command(
      messageType,
      commandType,
      timeVal,
      id + "-" + timeVal,
      messageSource,
      businessKeys,
      payloadObj
    );
    const empMsg = js2parser.parse("command", commandVal);
    return empMsg;
  }
  preparePayloadDataForProductLevel(
    customer: Customer,
    selectedProductId: string,
    selectedProductType: string,
    applyNotificationToAll: string,
    replyEmail: string,
    siteId: string,
    applyNotificationToAllForSite: string,
    tns: string[],
    telephoneNumbers: string,
    selectedTns: [],
    notifications: Notification[],
    showGroupTns: boolean,
    userId: string,
    groupName: string
  ) {
    let id: string;
    const env = config.env;

    if (customer != null && customer != undefined && customer.id != undefined) {
      id = customer.id;
    } else {
      id = selectedProductId;
    }

    const businessKeys = this.getBusinessKeys(
      id,
      selectedProductId,
      selectedProductType,
      userId
    );

    const tnInstances: TnInstanceVO[] = [];
    let tnInstance: TnInstanceVO;

    if (
      showGroupTns &&
      selectedTns != null &&
      selectedTns != undefined &&
      !_.isEmpty(selectedTns)
    ) {
      for (const key in selectedTns) {
        tnInstance = new TnInstanceVO(selectedTns[key], notifications);
        tnInstances[key] = tnInstance;
      }
    } else {
      if (tns != null && tns != undefined) {
        for (const i in tns) {
          tnInstance = new TnInstanceVO(tns[i], notifications);

          tnInstances[i] = tnInstance;
        }
      }

      if (telephoneNumbers != null && telephoneNumbers != "") {
        const tnsArray = telephoneNumbers.split(";").filter(item => item);
        let j = 0;
        if (!_.isEmpty(tnInstances)) {
          j = tnInstances.length;
        }
        if (tnsArray != null && tnsArray != undefined && !_.isEmpty(tnsArray)) {
          for (const key in tnsArray) {
            if (!tns.includes(tnsArray[key])) {
              tnInstance = new TnInstanceVO(tnsArray[key], notifications);
              tnInstances[j] = tnInstance;
              j++;
            }
          }
        } else {
          if (!tns.includes(telephoneNumbers)) {
            tnInstance = new TnInstanceVO(telephoneNumbers, notifications);
            tnInstances[j] = tnInstance;
          }
        }
      }
    }

    const sites: SiteInstanceVO[] = [
      new SiteInstanceVO(
        applyNotificationToAllForSite,
        siteId,
        groupName,
        tnInstances
      )
    ];
    const productInstance = new ProductInstanceVO(
      selectedProductId,
      applyNotificationToAll,
      replyEmail,
      sites
    );
    const notificationPreferenceVO = new NotificationPreferenceVO(
      id,
      productInstance
    );
    const payloadObj = new Payload(JSON.stringify(notificationPreferenceVO));
    const timeVal = new Date().toISOString();
    const messageType = `${Utility.getConfig(env, "VUE_APP_MSG_TYPE")}`;
    const commandType = `${Utility.getConfig(env, "VUE_APP_COMMAND_TYPE")}`;
    const messageSource = `${Utility.getConfig(env, "VUE_APP_MSG_SOURCE")}`;

    const commandVal = new Command(
      messageType,
      commandType,
      timeVal,
      id + "-" + timeVal,
      messageSource,
      businessKeys,
      payloadObj
    );
    const xml = js2parser.parse("command", commandVal);

    return xml;
  }

  getBusinessKeys(
    id: string,
    selectedProductId: string,
    selectedProductType: string,
    userId: string
  ): BusinessKeys {
    const businesskey1 = new BusinessKey("Customer", "customerId", id);
    const businessKey2 = new BusinessKey(
      "ProductInstance",
      "productInstanceId",
      selectedProductId
    );

    const businessKey3 = new BusinessKey(
      "ProductInstance",
      "productInstanceType",
      selectedProductType
    );

    const businessKey4 = new BusinessKey("User", "userId", userId);
    const businessKeyArray: BusinessKey[] = [
      businesskey1,
      businessKey2,
      businessKey3,
      businessKey4
    ];

    const businessKeys = new BusinessKeys(businessKeyArray);
    return businessKeys;
  }
  prepareEMPdataForAPply(
    customer: Customer,
    selectedProductId: string,
    replyEmail: string,
    data: []
  ) {
    let id: string;
    if (customer != null && customer != undefined && customer.id != undefined) {
      id = customer.id;
    } else {
      id = selectedProductId;
    }

    const sites: SiteInstanceVO[] = [];
    for (const key in data) {
      const emailNotifications: string = data[key]["emailnotifications"];
      const smsNotifications: string = data[key]["smsnotifications"];
      const tn = data[key]["tn"];

      if (_.isEmpty(smsNotifications) && _.isEmpty(emailNotifications)) {
        return "Enter either Email or SMS for given telephone number :" + tn;
      }
      if (_.isEmpty(tn)) {
        return "Please enter value for telephone number .";
      }
      const testTn = Utility.validateTn(tn, " telephone ");
      if (!_.isEmpty(testTn)) {
        return testTn;
      }
      const group: string = data[key]["group"];
      const description = "Bulk Import";
      const notificationArray: Notification[] = [];
      if (smsNotifications) {
        const smsArray = smsNotifications
          .split(";")
          .filter((item: any) => item);
        const testSMSArray = Utility.validateSize(smsArray, "SMSes", tn);
        if (!_.isEmpty(testSMSArray)) {
          return testSMSArray;
        }
        if (!_.isEmpty(smsArray)) {
          for (const i in smsArray) {
            const testSMSVal = Utility.validateTn(
              smsArray[i].trim(),
              "SMS ",
              tn
            );
            if (!_.isEmpty(testSMSVal)) {
              return testSMSVal;
            }
            const notification = new Notification(
              NotificationType.SMS,
              smsArray[i].trim(),
              description,
              false
            );
            notificationArray.push(notification);
          }
        } else {
          const notification = new Notification(
            NotificationType.SMS,
            smsNotifications.trim(),
            description,
            false
          );
          notificationArray.push(notification);
        }
      }

      if (emailNotifications) {
        const emailArray = emailNotifications
          .split(";")
          .filter((item: any) => item);
        const testEmailArray = Utility.validateSize(emailArray, "Emails", tn);
        if (!_.isEmpty(testEmailArray)) {
          return testEmailArray;
        }
        if (!_.isEmpty(emailArray)) {
          for (const i in emailArray) {
            const testEmailVal = Utility.validateEmail(
              emailArray[i].trim(),
              tn
            );
            if (!_.isEmpty(testEmailVal)) {
              return testEmailVal;
            }
            const notification = new Notification(
              NotificationType.EMAIL,
              emailArray[i].trim(),
              description,
              false
            );
            notificationArray.push(notification);
          }
        } else {
          const notification = new Notification(
            NotificationType.EMAIL,
            emailNotifications.trim(),
            description,
            false
          );
          notificationArray.push(notification);
        }
      }

      const tnInstance = new TnInstanceVO(tn, notificationArray);

      if (_.isEmpty(group)) {
        return "Enter valid value of Group for telephone number : " + tn;
      }

      if (group.lastIndexOf("(") < 0 || group.lastIndexOf(")") < 0) {
        return (
          "Please enter correct format of group for telephone number : " +
          tn +
          ". Format for group is Group Name(Group Id)."
        );
      }
      const groupId = group.substring(
        group.lastIndexOf("(") + 1,
        group.lastIndexOf(")")
      );
      const groupName = group.substring(0, group.lastIndexOf("("));

      const siteObj = sites.find(site => site.siteId == groupId);
      if (siteObj) {
        siteObj?.tnInstances?.push(tnInstance);
      } else {
        sites.push(
          new SiteInstanceVO("false", groupId, groupName, [tnInstance])
        );
      }
    }

    const productInstance = new ProductInstanceVO(
      selectedProductId,
      "false",
      replyEmail,
      sites
    );
    const notificationPreferenceVO = new NotificationPreferenceVO(
      id,
      productInstance
    );
    return notificationPreferenceVO;
  }
  prepareEMPdata(
    customer: Customer,
    selectedProductId: string,
    selectedProductType: string,
    replyEmail: string,
    data: [],
    userId: string
  ) {
    let id: string;
    const env = config.env;
    if (customer != null && customer != undefined && customer.id != undefined) {
      id = customer.id;
    } else {
      id = selectedProductId;
    }
    const businessKeys = this.getBusinessKeys(
      id,
      selectedProductId,
      selectedProductType,
      userId
    );

    const sites: SiteInstanceVO[] = [];
    for (const key in data) {
      const emailNotifications: string = data[key]["emailnotifications"];
      const smsNotifications: string = data[key]["smsnotifications"];
      const tn = data[key]["tn"];

      if (_.isEmpty(smsNotifications) && _.isEmpty(emailNotifications)) {
        return "Enter either Email or SMS for given telephone number : " + tn;
      }
      if (_.isEmpty(tn)) {
        return "Please enter value for telephone number .";
      }
      const testTn = Utility.validateTn(tn, " telephone ");
      if (!_.isEmpty(testTn)) {
        return testTn;
      }
      const group: string = data[key]["group"];
      const description = "Bulk Import";
      const notificationArray: Notification[] = [];
      if (smsNotifications) {
        const smsArray = smsNotifications
          .split(",")
          .filter((item: any) => item);
        const testSMSArray = Utility.validateSize(smsArray, "SMSes", tn);
        if (!_.isEmpty(testSMSArray)) {
          return testSMSArray;
        }
        if (!_.isEmpty(smsArray)) {
          for (const i in smsArray) {
            const testSMSVal = Utility.validateTn(smsArray[i], "SMS ", tn);
            if (!_.isEmpty(testSMSVal)) {
              return testSMSVal;
            }
            const notification = new Notification(
              NotificationType.SMS,
              smsArray[i],
              description,
              false
            );
            notificationArray.push(notification);
          }
        } else {
          const notification = new Notification(
            NotificationType.SMS,
            smsNotifications,
            description,
            false
          );
          notificationArray.push(notification);
        }
      }

      if (emailNotifications) {
        const emailArray = emailNotifications
          .split(",")
          .filter((item: any) => item);
        const testEmailArray = Utility.validateSize(emailArray, "Emails", tn);
        if (!_.isEmpty(testEmailArray)) {
          return testEmailArray;
        }
        if (!_.isEmpty(emailArray)) {
          for (const i in emailArray) {
            const testEmailVal = Utility.validateEmail(emailArray[i], tn);
            if (!_.isEmpty(testEmailVal)) {
              return testEmailVal;
            }
            const notification = new Notification(
              NotificationType.EMAIL,
              emailArray[i],
              description,
              false
            );
            notificationArray.push(notification);
          }
        } else {
          const notification = new Notification(
            NotificationType.EMAIL,
            emailNotifications,
            description,
            false
          );
          notificationArray.push(notification);
        }
      }

      const tnInstance = new TnInstanceVO(tn, notificationArray);

      if (_.isEmpty(group)) {
        return "Enter valid value of Group for telephone number " + tn;
      }

      if (group.indexOf("(") < 0 || group.indexOf(")") < 0) {
        return (
          "Please enter correct format of group for telephone number " +
          tn +
          ". Format for group is Group Name(Group Id)."
        );
      }
      const groupId = group.substring(
        group.indexOf("(") + 1,
        group.indexOf(")")
      );
      const groupName = group.substring(0, group.indexOf("("));

      const siteObj = sites.find(site => site.siteId == groupId);
      if (siteObj) {
        siteObj?.tnInstances?.push(tnInstance);
      } else {
        sites.push(
          new SiteInstanceVO("false", groupId, groupName, [tnInstance])
        );
      }
    }

    const productInstance = new ProductInstanceVO(
      selectedProductId,
      "false",
      replyEmail,
      sites
    );
    const notificationPreferenceVO = new NotificationPreferenceVO(
      id,
      productInstance
    );
    const payloadObj = new Payload(JSON.stringify(notificationPreferenceVO));
    const timeVal = new Date().toISOString();
    const messageType = `${Utility.getConfig(env, "VUE_APP_MSG_TYPE")}`;
    const commandType = `${Utility.getConfig(env, "VUE_APP_COMMAND_TYPE")}`;
    const messageSource = `${Utility.getConfig(env, "VUE_APP_MSG_SOURCE")}`;

    const commandVal = new Command(
      messageType,
      commandType,
      timeVal,
      id + "-" + timeVal,
      messageSource,
      businessKeys,
      payloadObj
    );
    return commandVal;
  }

  static convertxmlToString(commandVal: any) {
    const xml = js2parser.parse("command", commandVal);
    return xml;
  }
  getHmacDigest(appSecret: string, epochTime: string): string {
    const bytes = hmacSHA256(epochTime, appSecret);
    const hmacDigest = Base64.stringify(bytes);
    return hmacDigest;
  }

  prepareEmpDataForDelete(
    customer: Customer,
    selectedProductId: string,
    selectedProductType: string,
    userId: string,
    deleteAllNotifications: boolean,
    replyEmail: string,
    selectedGroupName: string,
    selectedGroupId: string,
    deleteAllSiteNotifications: boolean,
    tnRange: string[]
  ) {
    let id: string;
    const env = config.env;

    if (customer != null && customer != undefined && customer.id != undefined) {
      id = customer.id;
    } else {
      id = selectedProductId;
    }

    const businessKeys = this.getBusinessKeys(
      id,
      selectedProductId,
      selectedProductType,
      userId
    );

    const sites: DeleteForSite[] = [];
    if (!deleteAllNotifications) {
      const site = new DeleteForSite(
        selectedGroupId,
        selectedGroupName,
        deleteAllSiteNotifications,
        tnRange
      );

      sites.push(site);
    }
    const productInstance = new DeleteProductInstance(
      selectedProductId,
      deleteAllNotifications,
      replyEmail,
      sites
    );

    const notificationPreferenceVO = new DeleteNotificationPreferenceVO(
      id,
      productInstance
    );
    const payloadObj = new Payload(JSON.stringify(notificationPreferenceVO));
    const timeVal = new Date().toISOString();
    const messageType = `${Utility.getConfig(env, "VUE_APP_MSG_TYPE")}`;
    const commandType = `${Utility.getConfig(
      env,
      "VUE_APP_DELETE_COMMAND_TYPE"
    )}`;
    const messageSource = `${Utility.getConfig(env, "VUE_APP_MSG_SOURCE")}`;

    const commandVal = new Command(
      messageType,
      commandType,
      timeVal,
      id + "-" + timeVal,
      messageSource,
      businessKeys,
      payloadObj
    );
    const xml = js2parser.parse("command", commandVal);
    return xml;
  }

  prepareEmpDataForRemove(
    customer: Customer,
    selectedProductId: string,
    replyEmail: string,
    sites: DeleteForSite[]
  ) {
    let id: string;

    if (customer != null && customer != undefined && customer.id != undefined) {
      id = customer.id;
    } else {
      id = selectedProductId;
    }

    const productInstance = new DeleteProductInstance(
      selectedProductId,
      false,
      replyEmail,
      sites
    );

    const notificationPreferenceVO = new DeleteNotificationPreferenceVO(
      id,
      productInstance
    );

    return notificationPreferenceVO;
  }

  prepareEmpDataForRemoveAll(
    customer: Customer,
    selectedProductId: string,
    selectedProductType: string,
    userId: string,
    replyEmail: string,
    sites: DeleteForSite[]
  ) {
    let id: string;
    const env = config.env;

    if (customer != null && customer != undefined && customer.id != undefined) {
      id = customer.id;
    } else {
      id = selectedProductId;
    }

    const businessKeys = this.getBusinessKeys(
      id,
      selectedProductId,
      selectedProductType,
      userId
    );

    const productInstance = new DeleteProductInstance(
      selectedProductId,
      false,
      replyEmail,
      sites
    );

    const notificationPreferenceVO = new DeleteNotificationPreferenceVO(
      id,
      productInstance
    );
    const payloadObj = new Payload(JSON.stringify(notificationPreferenceVO));
    const timeVal = new Date().toISOString();
    const messageType = `${Utility.getConfig(env, "VUE_APP_MSG_TYPE")}`;
    const commandType = `${Utility.getConfig(
      env,
      "VUE_APP_DELETE_COMMAND_TYPE"
    )}`;
    const messageSource = `${Utility.getConfig(env, "VUE_APP_MSG_SOURCE")}`;

    const commandVal = new Command(
      messageType,
      commandType,
      timeVal,
      id + "-" + timeVal,
      messageSource,
      businessKeys,
      payloadObj
    );
    const xml = js2parser.parse("command", commandVal);
    return xml;
  }

  createDeleteSitesArray(data: []) {
    const sites: DeleteForSite[] = [];
    for (const key in data) {
      const tn = data[key]["tn"];
      if (_.isEmpty(tn)) {
        return "Please enter value for telephone number .";
      }
      const testTn = Utility.validateTn(tn, " telephone ");
      if (!_.isEmpty(testTn)) {
        return testTn;
      }
      const group: string = data[key]["group"];

      if (_.isEmpty(group)) {
        return "Enter valid value of Group for telephone number " + tn;
      }

      if (group.lastIndexOf("(") < 0 || group.lastIndexOf(")") < 0) {
        return (
          "Please enter correct format of group for telephone number : " +
          tn +
          ". Format for group is Group Name(Group Id)."
        );
      }
      const groupId = group.substring(
        group.lastIndexOf("(") + 1,
        group.lastIndexOf(")")
      );
      const groupName = group.substring(0, group.lastIndexOf("("));

      const siteObj = sites.find(site => site.siteId == groupId);
      if (siteObj) {
        siteObj?.tns.push(tn);
      } else {
        sites.push(new DeleteForSite(groupId, groupName, false, [tn]));
      }
    }
    return sites;
  }
  static getConfig(env: string, val: any) {
    switch (env) {
      case "development": {
        return config.development.default[val];
      }
      case "dev": {
        return config.development.default[val];
      }
      case "test1": {
        return config.test1.default[val];
      }
      case "test2": {
        return config.test2.default[val];
      }
      case "prod": {
        return config.prod.default[val];
      }
      case "e2e": {
        return config.e2e.default[val];
      }
    }
  }
  static validateSize(
    emailSMSArray: string[],
    type: string,
    num: string
  ): string {
    if (emailSMSArray.length > 10) {
      return (
        "Only 10 " +
        type +
        " can be added for each telephone number. Please modify the values for telephone number : " +
        num
      );
    }
    return "";
  }
  //SMS number:
  static validateTn(num: string, type: string, num2?: string) {
    if (_.isEmpty(num)) {
      return "Telephone number cannot be empty";
    }
    if (!parseInt(num) || num.startsWith("0") || num.length != 10) {
      let val = "";
      let val2 = "";
      if (_.isEqual(_.trim(type), "SMS")) {
        val = " for telephone number: " + num2;
        val2 = "Multiple SMS numbers should be separated by semicolon.";
      }
      return (
        " Invalid format of " +
        type +
        "number: " +
        num +
        val +
        ". " +
        type +
        " number should be of 10 digits,can't start with 0 or have special characters." +
        val2
      );
    }
    return "";
  }

  static validateEmail(email: string, num?: string) {
    const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let message = " Invalid format of Email: " + email;

    if (!email.trim().match(emailFormat)) {
      if (!_.isEmpty(num)) {
        message +=
          " for telephone number: " +
          num +
          ". Multiple email values should be separated by semicolon.";
      }
      return message;
    }
    return "";
  }

  static checkSizeOfEmailsAndSMS(notifs: Notification[], type: string) {
    const emailNotifs = notifs.filter(notif =>
      _.isEqual(notif.type, NotificationType.EMAIL)
    );

    if (emailNotifs.length > 10) {
      if (type == "telephones") {
        return "Only 10 Emails are allowed.";
      }
      return "Only 10 default Emails are allowed.";
    }
    const SMSNotifs = notifs.filter(notif =>
      _.isEqual(notif.type, NotificationType.SMS)
    );
    if (SMSNotifs.length > 10) {
      if (type == "telephones") {
        return "Only 10 SMSes are allowed.";
      }
      return "Only 10 default SMSes are allowed.";
    }
    return "No error";
  }
  static isFederalAccount(productType: string) {
    const env = config.env;
    if (
      _.isEqual(
        _.lowerCase(productType),
        this.getConfig(env, "VUE_PRODUCT_TYPE")
      )
    ) {
      return true;
    }
    return false;
  }
}
