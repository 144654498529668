
import { Vue, Options } from "vue-class-component";
import { remove, sortBy } from "lodash";

@Options({
  props: ["items", "showSelected", "titleForItems", "titleForSelected"],
  data() {
    return {
      filteredItems: [],
      selectedItemValues: []
    };
  },
  methods: {
    onSelect(item: any) {
      this.$emit("on-select", this.selectedItemValues);
    },
    selectAll(event: any) {
      const checkboxes = document.querySelectorAll("input[multi-selector]");
      for (const index in checkboxes) {
        const value = (checkboxes[index] as HTMLInputElement).value;
        if (value !== undefined) {
          (checkboxes[index] as HTMLInputElement).checked =
            event.target.checked;
          if (event.target.checked) {
            if (this.selectedItemValues.indexOf(value) < 0) {
              this.selectedItemValues.push(value);
            }
          } else {
            remove(this.selectedItemValues, function(n) {
              return n === value;
            });
          }
        }
      }
      this.$emit("on-select", this.selectedItemValues);
    },
    filterItems(event: any) {
      this.filteredItems = this.items.filter(function(item: any) {
        return item.indexOf(event.target.value) > -1;
      });
    }
  },
  mounted() {
    this.filteredItems = sortBy(this.items);
  },
  watch: {
    items(newVal, oldVal) {
      this.filteredItems = sortBy(newVal);
    }
  }
})
export default class MultiSelector extends Vue {}
