<template>
  <div class="tenant">
    <div class="-m--2">
      <message-alert ref="msgAlert" alertClass="-mb--2" />
      <ContentHeader
        :level="level"
        v-on:use-only-defaults="setUseOnlyDefaults"
      />
      <div v-if="isProductIdSelected">
        <div class="chi-grid">
          <div class="chi-col -w--7">
            <p class="-text--h5">
              Default Hosted VoIP or IQ SIP Product Level Notification Data
            </p>
            <p class="-text--sm -mt--0">
              This section is used to manage default account level notifications
            </p>
            <div class="chi-form__item">
              <label class="chi-switch -sm" for="toggleswitch">
                <input
                  type="checkbox"
                  class="chi-switch__input"
                  id="toggleswitch"
                  :disabled="!loggedInUser.isAdminUser"
                  :checked="allowChange"
                  @click="toggleSwitchForAllowChange(allowChange)"
                />
                <span class="chi-switch__content">
                  <span class="chi-switch__thumb"></span>
                </span>
                <span class="chi-switch__label">
                  <span
                    ><b>{{ labelHeader }}</b>
                    {{ labelHeader2 }}
                  </span>
                </span>
              </label>
            </div>
          </div>
          <div class="chi-col">
            <!--<export-to-excel
              v-if="isProductIdSelected"
              @clicked="testMethod"
              :level="level"
              :header="header"
              :sheetName="sheetName"
              :fileName="fileName"
            />-->
            <chi-button
              color="primary"
              @click="exportNotifications"
              v-if="isProductIdSelected"
              >Export Notification Data
            </chi-button>
          </div>
        </div>
        <Notifications
          :global-notifications="notifications"
          disable-defaults="false"
          level="Tenant"
          show-delete="true"
          ref="notificationsRef"
          v-on:set-tenant-level-change="setChangesAttenantLevel()"
          :isViewOnly="loggedInUser.isViewOnly"
        />
        <br />
        <div class="-d--flex -flex--row">
          <div class="-pr--2">
            <ReplyEmail
              :level="level"
              :selectedGroupId="null"
              :npa="[]"
              :nxx="[]"
              :to="[]"
              :from="[]"
              :telephoneNumbers="[]"
              :showGroupTns="true"
              :displayValue="displayVal"
              :isViewOnly="loggedInUser.isViewOnly"
              popUpStatement="Confirm to submit"
              v-on:set-change="setChange"
            />
          </div>
          <div class="-pr--2">
            <DeleteNotifications
              :level="level"
              :selectedGroupId="null"
              :displayValue="displayValDelete"
              :isViewOnly="loggedInUser.isViewOnly"
              popUpStatement="Confirm to delete"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isProductIdSelected">
      <div>
        <!--<chi-button
          color="primary"
          variant="flat"
          @click="siteLevel()"
          :disabled="useOnlyDefaults"
          >Enable unique email notification settings at the Group / Location
          level
        </chi-button>-->
        <route-to-site-or-tn-or-tenant
          :isChanged="isChanged"
          :useOnlyDefaults="allowChange"
          level="Group / Location"
          v-on:site-level="siteLevel"
          modalId="modal-11"
          tooltipId="tooltip-11"
          level2="Product/Tenant"
          classType="-flat"
          title="Enable unique email notification settings at the Group / Location level"
        />
      </div>
      <div>
        <!--<chi-button
          color="primary"
          variant="flat"
          @click="tnLevel()"
          :disabled="useOnlyDefaults"
          >Enable unique email notification settings at the Telephone Number
          level
        </chi-button>-->
        <route-to-site-or-tn-or-tenant
          :isChanged="isChanged"
          :useOnlyDefaults="allowChange"
          level="Telephone number"
          v-on:tn-level="tnLevel"
          modalId="modal-15"
          tooltipId="tooltip-15"
          level2="Product/Tenant"
          classType="-flat"
          title="Enable unique email notification settings at the Telephone Number level"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import ContentHeader from "@/components/ContentHeader.vue";
import MessageAlert from "@/components/MessageAlert.vue";
import Notifications from "@/components/Notifications.vue";
import ReplyEmail from "@/components/ReplyEmail.vue";
import ExportToExcel from "@/utilities/exportToExcel";
import ErrorHandler from "@/utilities/errorHandling";
import DeleteNotifications from "@/components/DeleteNotifications.vue";
import RouteToSiteOrTnOrTenant from "@/components/RouteToSiteOrTnOrTenant.vue";
import * as _ from "lodash";

@Options({
  data() {
    return {
      dangerAlert: "Failed to Save Notification data",
      SuccessAlert: "Notification Data Saved Successfully",
      level: "Tenant",
      displayVal: "Apply default notifications to all Telephone Numbers",
      displayValDelete:
        "Delete default notifications from all Telephone Numbers",
      isDisplay: true,
      labelHeader: "Enable Tenant Defaults only.",
      labelHeader2:
        "Functionality will be disabled at the Group and Telephone Number levels. This setting is managed exclusively by the portal Administrator.",
      header: [
        [
          "Telephone Number",
          "Group Name(Group ID)",
          "EMAIL Notifications",
          "SMS Notifications"
        ]
      ],
      colInfoArray: [
        {
          width: 18
        },
        {
          width: 25
        },
        {
          width: 50
        },
        {
          width: 50
        }
      ],
      sheetName: "notifications",
      fileName: "911_ManagerNotifications.xlsx",
      isChanged: false
    };
  },
  components: {
    ContentHeader,
    MessageAlert,
    Notifications,
    ReplyEmail,
    DeleteNotifications,
    RouteToSiteOrTnOrTenant
  },
  computed: {
    ...mapGetters([
      "isProductIdSelected",
      "customer",
      "notifications",
      "selectedProductType",
      "selectedProductId",
      "exportNotif",
      "loggedInUser",
      "allowChange"
    ])
  },
  methods: {
    ...mapActions([
      "setSuccessMessage",
      "sendEMPmsg",
      "hideMessage",
      "setInfoMessage",
      "setErrorMessage",
      "toggleSwitch"
    ]),
    async toggleSwitchForAllowChange(allowChange: boolean) {
      try {
        await this.toggleSwitch([
          this.selectedProductType,
          this.selectedProductId,
          !allowChange
        ]);
        this.setSuccessMessage("Data Saved Successfully!");
      } catch (e) {
        this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
      }
    },
    toggleUseOnlyDefaults(event: CustomEvent<any>) {
      this.useOnlyDefaults = event.detail;
    },
    siteLevel() {
      if (!this.allowChange) {
        this.$router.push("site");
      }
    },
    tnLevel() {
      if (!this.allowChange) {
        this.$router.push("tn");
      }
    },
    setUseOnlyDefaults() {
      this.useOnlyDefaults = false;
    },
    async exportNotifications() {
      this.hideMessage();
      try {
        await this.$store.dispatch("getAllTnNotifications", [
          this.selectedProductType,
          this.selectedProductId,
          "",
          this.loggedInUser.id
        ]);

        const jsonDataForExcel = [];
        let jsonData: string[] = [];

        for (const key in this.exportNotif) {
          const notif = this.exportNotif[key];

          jsonData.push(notif.tn);
          jsonData.push(notif.siteID);
          if (!_.isEmpty(notif.emailNotifications)) {
            jsonData.push(notif.emailNotifications);
          } else {
            jsonData.push("");
          }

          if (!_.isEmpty(notif.smsNotifications)) {
            jsonData.push(notif.smsNotifications);
          } else {
            jsonData.push("");
          }
          jsonDataForExcel.push(jsonData);
          jsonData = [];
        }

        ExportToExcel.exportToExcel(
          this.header,
          this.fileName,
          this.sheetName,
          jsonDataForExcel,
          this.colInfoArray
        );
      } catch (e) {
        if (e.message.indexOf("404", 0) > -1) {
          this.setInfoMessage(e.message.substring(0, e.message.indexOf("(")));
        } else {
          this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        }
      }
    },
    setChangesAttenantLevel() {
      this.isChanged = true;
      console.log("is changed " + this.isChanged);
    },
    setChange() {
      this.isChanged = false;
    }
  }
})
export default class Tenant extends Vue {}
</script>
