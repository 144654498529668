import { render } from "./TelephoneNumber.vue?vue&type=template&id=6302c2a3"
import script from "./TelephoneNumber.vue?vue&type=script&lang=ts"
export * from "./TelephoneNumber.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6302c2a3"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6302c2a3', script)) {
    api.reload('6302c2a3', script)
  }
  
  module.hot.accept("./TelephoneNumber.vue?vue&type=template&id=6302c2a3", () => {
    api.rerender('6302c2a3', render)
  })

}

script.__file = "src/views/TelephoneNumber.vue"

export default script