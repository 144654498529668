import { render } from "./ContentHeader.vue?vue&type=template&id=61596566"
import script from "./ContentHeader.vue?vue&type=script&lang=ts"
export * from "./ContentHeader.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "61596566"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('61596566', script)) {
    api.reload('61596566', script)
  }
  
  module.hot.accept("./ContentHeader.vue?vue&type=template&id=61596566", () => {
    api.rerender('61596566', render)
  })

}

script.__file = "src/components/ContentHeader.vue"

export default script