import { Module } from "vuex";
import MessageHandler, { Message } from "@/models/message";

const messagesModule: Module<any, any> = {
  state: {
    message: MessageHandler.getEmptyMessage(),
    showMessage: false
  },
  mutations: {
    setMessage(state, message: Message) {
      state.message = message;
      state.showMessage = true;
    },
    setShowMessage(state, showMessage: boolean) {
      state.showMessage = showMessage;
    }
  },
  actions: {
    setSuccessMessage({ commit }, msg: string) {
      console.log("set success message");
      commit("setMessage", MessageHandler.getSuccessMessage(msg));
    },
    setErrorMessage({ commit }, msg: string) {
      commit("setMessage", MessageHandler.getErrorMessage(msg));
    },
    setWarningMessage({ commit }, msg: string) {
      commit("setMessage", MessageHandler.getWarningMessage(msg));
    },
    setInfoMessage({ commit }, msg: string) {
      commit("setMessage", MessageHandler.getInfoMessage(msg));
    },
    hideMessage({ commit }) {
      commit("setMessage", MessageHandler.getEmptyMessage());
      commit("setShowMessage", false);
    }
  },
  getters: {
    message: state => state.message,
    showMessage: state => state.showMessage
  }
};

export default messagesModule;
