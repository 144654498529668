import axios from "axios";
import Spinner from "./spinner";

export default class AxiosInstance {
  public static createInstance() {
    const instance = axios.create({
      headers: {}
    });

    instance.interceptors.request.use(config => {
      Spinner.start();
      return config;
    });

    instance.interceptors.response.use(
      response => {
        Spinner.stop();
        return response;
      },
      error => {
        Spinner.stop();
        return Promise.reject(error);
      }
    );

    return instance;
  }
  public static createInstance2() {
    const instance = axios.create({
      headers: {}
    });

    instance.interceptors.request.use(config => {
      Spinner.start();
      return config;
    });

    instance.interceptors.response.use(
      response => {
        Spinner.stop();
        return response;
      },
      error => {
        Spinner.stop();
        return Promise.reject(error);
      }
    );

    return instance;
  }
  public static createInstance3() {
    const instance = axios.create({
      headers: {
      }
    });

    instance.interceptors.request.use(config => {
      Spinner.start();
      return config;
    });

    instance.interceptors.response.use(
      response => {
        Spinner.stop();
        return response;
      },
      error => {
        Spinner.stop();
        return Promise.reject(error);
      }
    );

    return instance;
  }
}
