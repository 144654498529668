<template>
  <div id="nav">
    <Header />
    <div class="-m--2">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Header from "@/components/Header.vue";

@Options({
  components: {
    Header
  }
})
export default class App extends Vue {}
</script>
<style></style>
