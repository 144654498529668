import { render } from "./ExportToExcel.vue?vue&type=template&id=8fe56ab0"
import script from "./ExportToExcel.vue?vue&type=script&lang=ts"
export * from "./ExportToExcel.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "8fe56ab0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8fe56ab0', script)) {
    api.reload('8fe56ab0', script)
  }
  
  module.hot.accept("./ExportToExcel.vue?vue&type=template&id=8fe56ab0", () => {
    api.rerender('8fe56ab0', render)
  })

}

script.__file = "src/components/ExportToExcel.vue"

export default script