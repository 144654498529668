import { render } from "./DeleteNotifications.vue?vue&type=template&id=14b59c7d"
import script from "./DeleteNotifications.vue?vue&type=script&lang=ts"
export * from "./DeleteNotifications.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "14b59c7d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('14b59c7d', script)) {
    api.reload('14b59c7d', script)
  }
  
  module.hot.accept("./DeleteNotifications.vue?vue&type=template&id=14b59c7d", () => {
    api.rerender('14b59c7d', render)
  })

}

script.__file = "src/components/DeleteNotifications.vue"

export default script