
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import ContentHeader from "@/components/ContentHeader.vue";
import MessageAlert from "@/components/MessageAlert.vue";
import Notifications from "@/components/Notifications.vue";
import ReplyEmail from "@/components/ReplyEmail.vue";
import ExportToExcel from "@/utilities/exportToExcel";
import ErrorHandler from "@/utilities/errorHandling";
import DeleteNotifications from "@/components/DeleteNotifications.vue";
import RouteToSiteOrTnOrTenant from "@/components/RouteToSiteOrTnOrTenant.vue";
import * as _ from "lodash";

@Options({
  data() {
    return {
      dangerAlert: "Failed to Save Notification data",
      SuccessAlert: "Notification Data Saved Successfully",
      level: "Tenant",
      displayVal: "Apply default notifications to all Telephone Numbers",
      displayValDelete:
        "Delete default notifications from all Telephone Numbers",
      isDisplay: true,
      labelHeader: "Enable Tenant Defaults only.",
      labelHeader2:
        "Functionality will be disabled at the Group and Telephone Number levels. This setting is managed exclusively by the portal Administrator.",
      header: [
        [
          "Telephone Number",
          "Group Name(Group ID)",
          "EMAIL Notifications",
          "SMS Notifications"
        ]
      ],
      colInfoArray: [
        {
          width: 18
        },
        {
          width: 25
        },
        {
          width: 50
        },
        {
          width: 50
        }
      ],
      sheetName: "notifications",
      fileName: "911_ManagerNotifications.xlsx",
      isChanged: false
    };
  },
  components: {
    ContentHeader,
    MessageAlert,
    Notifications,
    ReplyEmail,
    DeleteNotifications,
    RouteToSiteOrTnOrTenant
  },
  computed: {
    ...mapGetters([
      "isProductIdSelected",
      "customer",
      "notifications",
      "selectedProductType",
      "selectedProductId",
      "exportNotif",
      "loggedInUser",
      "allowChange"
    ])
  },
  methods: {
    ...mapActions([
      "setSuccessMessage",
      "sendEMPmsg",
      "hideMessage",
      "setInfoMessage",
      "setErrorMessage",
      "toggleSwitch"
    ]),
    async toggleSwitchForAllowChange(allowChange: boolean) {
      try {
        await this.toggleSwitch([
          this.selectedProductType,
          this.selectedProductId,
          !allowChange
        ]);
        this.setSuccessMessage("Data Saved Successfully!");
      } catch (e) {
        this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
      }
    },
    toggleUseOnlyDefaults(event: CustomEvent<any>) {
      this.useOnlyDefaults = event.detail;
    },
    siteLevel() {
      if (!this.allowChange) {
        this.$router.push("site");
      }
    },
    tnLevel() {
      if (!this.allowChange) {
        this.$router.push("tn");
      }
    },
    setUseOnlyDefaults() {
      this.useOnlyDefaults = false;
    },
    async exportNotifications() {
      this.hideMessage();
      try {
        await this.$store.dispatch("getAllTnNotifications", [
          this.selectedProductType,
          this.selectedProductId,
          "",
          this.loggedInUser.id
        ]);

        const jsonDataForExcel = [];
        let jsonData: string[] = [];

        for (const key in this.exportNotif) {
          const notif = this.exportNotif[key];

          jsonData.push(notif.tn);
          jsonData.push(notif.siteID);
          if (!_.isEmpty(notif.emailNotifications)) {
            jsonData.push(notif.emailNotifications);
          } else {
            jsonData.push("");
          }

          if (!_.isEmpty(notif.smsNotifications)) {
            jsonData.push(notif.smsNotifications);
          } else {
            jsonData.push("");
          }
          jsonDataForExcel.push(jsonData);
          jsonData = [];
        }

        ExportToExcel.exportToExcel(
          this.header,
          this.fileName,
          this.sheetName,
          jsonDataForExcel,
          this.colInfoArray
        );
      } catch (e) {
        if (e.message.indexOf("404", 0) > -1) {
          this.setInfoMessage(e.message.substring(0, e.message.indexOf("(")));
        } else {
          this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        }
      }
    },
    setChangesAttenantLevel() {
      this.isChanged = true;
      console.log("is changed " + this.isChanged);
    },
    setChange() {
      this.isChanged = false;
    }
  }
})
export default class Tenant extends Vue {}
