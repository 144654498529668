export enum NotificationType {
  EMAIL = "EMAIL",
  SMS = "SMS"
}

export class Notification {
  constructor(
    public type: NotificationType.EMAIL | NotificationType.SMS,
    public value: string,
    public description: string,
    public defaulted: boolean
  ) {
    // short hand declaration of class properties
  }
}

export class NotificationEnhanced {
  constructor(public notification: Notification, public isSelected: boolean) {}
}
