
import { Vue, Options } from "vue-class-component";
import AddDefaults from "@/components/AddDefaults.vue";
import DeleteDefaults from "@/components/DeleteDefaults.vue";
import { cloneDeep, ceil, chunk, orderBy } from "lodash";
import { NotificationEnhanced } from "@/models/notification";
import { mapActions, mapGetters } from "vuex";
import config from "@/utilities/config";
import Utility from "@/utilities/utility";
import ErrorHandler from "@/utilities/errorHandling";

@Options({
  props: [
    "disableDefaults",
    "level",
    "showDelete",
    "globalNotifications",
    "isViewOnly"
  ],
  components: {
    AddDefaults,
    DeleteDefaults
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 0,
      notifications: [],
      currentSortedProp: "",
      currentSortOrder: "",
      searchText: "",
      selectedNotifications: [],
      selectAllChecked: false
    };
  },
  created() {
    const env = config.env;
    this.pageSize = `${Utility.getConfig(env, "VUE_APP_RECORD_COUNT")}`;
  },
  computed: {
    pages() {
      return ceil(this.notifications.length / this.pageSize);
    },
    paginatedRecords() {
      return chunk(this.notifications, this.pageSize)[this.currentPage - 1];
    },
    ...mapGetters(["selectedProductType", "selectedProductId", "loggedInUser"])
  },
  methods: {
    ...mapActions(["toggleDefaults", "setSuccessMessage", "setErrorMessage"]),
    sortClasses(sortProp: string) {
      if (this.currentSortedProp === sortProp) {
        return this.currentSortOrder === "asc"
          ? "-sorted -ascending"
          : "-sorted -descending";
      } else {
        return "-sorted -ascending -nosort";
      }
    },
    pageChange: function(event: CustomEvent<string>) {
      this.currentPage = event.detail;
    },
    sort(sortProp: string) {
      if (this.currentSortedProp === sortProp) {
        this.currentSortOrder =
          this.currentSortOrder === "asc" ? "desc" : "asc";
      } else {
        this.currentSortedProp = sortProp;
        this.currentSortOrder = "asc";
      }
      this.notifications = orderBy(
        this.notifications,
        function(notif: NotificationEnhanced) {
          if (sortProp === "value" || sortProp === "type") {
            return notif.notification[sortProp]?.trim().toLowerCase();
          } else if (sortProp === "description") {
            return notif.notification.description
              ? notif.notification.description
              : "";
          } else {
            return notif.notification.defaulted;
          }
        },
        this.currentSortOrder
      );
    },
    filterNotifications(event: any) {
      this.currentPage = 1;
      this.notifications = this.globalNotifications.filter(function(
        notif: NotificationEnhanced
      ) {
        return (
          notif.notification.type
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) > -1 ||
          notif.notification.value
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) > -1 ||
          notif.notification.description
            ?.toLowerCase()
            .indexOf(event.target.value.toLowerCase()) > -1
        );
      });
    },
    select(notif: NotificationEnhanced) {
      notif.isSelected = !notif.isSelected;
      let flag = false;

      const checkboxes = document.querySelectorAll(
        "input[notification-selector]"
      );
      for (const index in checkboxes) {
        const value = (checkboxes[index] as HTMLInputElement).value;
        if (value !== undefined) {
          if (!(checkboxes[index] as HTMLInputElement).checked) {
            flag = false;
            break;
          }
          flag = true;
        }
      }

      this.selectAllChecked = flag;

      if (this.level == "tn") {
        this.$emit("selected-notifs", this.selectedNotifications);
      }
    },
    selectAll(event: any) {
      const checkboxes = document.querySelectorAll(
        "input[notification-selector]"
      );
      for (const index in checkboxes) {
        const value = (checkboxes[index] as HTMLInputElement).value;
        if (value !== undefined) {
          (checkboxes[index] as HTMLInputElement).checked =
            event.target.checked;
        }
      }
      if (event.target.checked) {
        this.selectedNotifications = cloneDeep(this.notifications);
        this.selectAllChecked = true;
      } else {
        this.selectedNotifications = [];
        this.selectAllChecked = false;
      }

      if (this.level == "tn") {
        this.$emit("selected-notifs", this.selectedNotifications);
      }
    },
    async toggle(notif: NotificationEnhanced, index: number) {
      if (notif.notification.type == "EMAIL") {
        const emailNotifs = this.globalNotifications.filter(
          (notif: { notification: { defaulted: any; type: string } }) =>
            notif.notification.defaulted && notif.notification.type == "EMAIL"
        );

        if (emailNotifs.length + 1 > 10 && !notif.notification.defaulted) {
          this.$refs["toggle" + index].checked = notif.notification.defaulted;
          this.setErrorMessage("Only 10 default Emails are allowed!");
          return;
        }
      } else if (notif.notification.type == "SMS") {
        const smsNotifs = this.globalNotifications.filter(
          (notif: { notification: { defaulted: any; type: string } }) =>
            notif.notification.defaulted && notif.notification.type == "SMS"
        );

        if (smsNotifs.length + 1 > 10 && !notif.notification.defaulted) {
          this.$refs["toggle" + index].checked = notif.notification.defaulted;
          this.setErrorMessage("Only 10 default SMSes are allowed!");
          return;
        }
      }
      notif.notification.defaulted = !notif.notification.defaulted;

      try {
        await this.$store.dispatch("toggleDefaults", [
          notif,
          this.selectedProductType,
          this.selectedProductId,
          this.loggedInUser.id
        ]);

        this.setSuccessMessage("Data updated successfully!");
        this.$emit("set-tenant-level-change");
      } catch (e) {
        this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
      }
      if (this.disableDefaults !== "true") {
        this.$emit("on-toggle", notif);
      }
    },
    getSelectedNotifications() {
      return this.selectedNotifications;
    },
    setSelectedNotifications(preSelectedNotifications: NotificationEnhanced[]) {
      this.selectedNotifications = preSelectedNotifications;
    },
    getHighlightedSearchText(content: string) {
      if (!this.searchText || !content) {
        return content;
      }
      return content.replace(new RegExp(this.searchText, "gi"), match => {
        return '<span class="-bg--muted">' + match + "</span>";
      });
    },
    removeNotifications() {
      let flag = false;
      for (const i in this.selectedNotifications) {
        if (this.selectedNotifications[i].notification.defaulted) {
          flag = true;
          break;
        }
      }
      this.selectedNotifications = [];
      this.selectAllChecked = false;
      if (flag) {
        this.$emit("set-tenant-level-change");
      }
    },
    callSiteLevel() {
      this.$emit("set-notifications-site");
    },
    callTenantLevel() {
      console.log("call tenant level");
      this.$emit("set-tenant-level-change");
    },
    setCheckAllNotifications(flag: boolean) {
      console.log("set check all");
      this.selectAllChecked = flag;
    }
  },
  mounted() {
    document.addEventListener("chiPageChange", this.pageChange, false);
    this.notifications = cloneDeep(this.globalNotifications);
  },
  watch: {
    globalNotifications(newVal, oldVal) {
      this.notifications = cloneDeep(newVal);
    }
  }
})
export default class Notifications extends Vue {}
