<template>
  <div class="-d--flex">
    <div
      class="-align-self--stretch chi-card -portal"
      style="min-width: 600px;"
    >
      <div class="chi-card__header -sm">
        <div
          class="chi-card__title -d--flex -justify-content--between"
          style="width: 100%;"
        >
          <div>Global Notification Data</div>
          <div class="chi-input__wrapper -icon--left " role="search">
            <input
              class="chi-input"
              type="text"
              placeholder="Search"
              aria-label="Search"
              v-model="searchText"
              @keyup="filterNotifications"
            />
            <i class="chi-icon icon-search"></i>
          </div>
        </div>
      </div>
      <section class="chi-table -fixed--header">
        <div style="max-height: 300px;">
          <table id="notifications">
            <thead>
              <tr>
                <th :class="{ divClass: level == 'site' }">
                  <div v-if="level == 'site'" style="padding: 0px !important">
                    Group<br />
                    Level <br />Default
                  </div>
                  <div style="padding-left :53px;" v-if="level == 'site'">
                    <br />
                    <input
                      class="chi-checkbox__input"
                      type="checkbox"
                      id="chk-1"
                      @change="selectAll"
                      :checked="selectAllChecked"
                    />
                    <label class="chi-checkbox__label" for="chk-1">
                      <div class="-sr--only">Label</div>
                    </label>
                  </div>

                  <div v-else>
                    <input
                      class="chi-checkbox__input"
                      type="checkbox"
                      id="chk-1"
                      @change="selectAll"
                      :checked="selectAllChecked"
                    />
                    <label class="chi-checkbox__label" for="chk-1">
                      <div class="-sr--only">Label</div>
                    </label>
                  </div>
                </th>
                <th :class="sortClasses('defaulted')" style="min-width: 70px">
                  <div
                    @click="sort('defaulted')"
                    style="padding: 0px !important"
                  >
                    Tenant <br />
                    Level <br />
                    Default
                  </div>
                </th>
                <th :class="sortClasses('type')" style="min-width: 100px">
                  <div @click="sort('type')">Category</div>
                </th>
                <th :class="sortClasses('value')" style="min-width: 130px">
                  <div @click="sort('value')">Notification</div>
                </th>
                <th
                  :class="sortClasses('description')"
                  style="min-width: 130px"
                >
                  <div @click="sort('description')">Description</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="{ '-row--info': (index + 1) % 2 }"
                v-for="(notif, index) in paginatedRecords"
                :key="index"
              >
                <td class="-lh--1">
                  <input
                    class="chi-checkbox__input"
                    type="checkbox"
                    :id="'chk-0-' + index"
                    :value="notif"
                    v-model="selectedNotifications"
                    @change="select(notif)"
                    notification-selector
                  />
                  <label class="chi-checkbox__label" :for="'chk-0-' + index">
                    <div class="-sr--only">Label</div>
                  </label>
                </td>
                <td>
                  <div class="chi-form__item">
                    <label class="chi-switch -xs" :for="'toggle' + index">
                      <input
                        type="checkbox"
                        class="chi-switch__input"
                        :id="'toggle' + index"
                        :ref="'toggle' + index"
                        :checked="notif.notification.defaulted"
                        @click="toggle(notif, index)"
                        :disabled="disableDefaults === 'true' || isViewOnly"
                      />
                      <span class="chi-switch__content">
                        <span class="chi-switch__thumb"></span>
                      </span>
                      <span class="chi-switch__label"></span>
                    </label>
                  </div>
                </td>
                <td>
                  <span
                    v-html="getHighlightedSearchText(notif.notification.type)"
                  ></span>
                </td>
                <td
                  style="min-width: 130px; word-break: break-word; overflow-wrap: break-word;"
                >
                  <span
                    v-html="getHighlightedSearchText(notif.notification.value)"
                  ></span>
                </td>
                <td style="min-width: 130px">
                  <span
                    v-html="
                      getHighlightedSearchText(notif.notification.description)
                    "
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <div v-if="notifications.length !== 0">
        <chi-pagination
          id="chi-pagination"
          :pages="pages"
          :current-page="currentPage"
          :results="notifications.length"
          compact
          first-last
        ></chi-pagination>
      </div>
    </div>
    <div class="-ml--2 -d--flex -flex--column">
      <AddDefaults
        :level="level"
        v-on:set-notifications="callSiteLevel"
        v-on:set-changes="callTenantLevel"
        :isViewOnly="isViewOnly"
      />
      <div class="-mt--2" v-if="showDelete === 'true'">
        <DeleteDefaults
          :isViewOnly="isViewOnly"
          :notificationsArray="selectedNotifications"
          @clicked="removeNotifications"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import AddDefaults from "@/components/AddDefaults.vue";
import DeleteDefaults from "@/components/DeleteDefaults.vue";
import { cloneDeep, ceil, chunk, orderBy } from "lodash";
import { NotificationEnhanced } from "@/models/notification";
import { mapActions, mapGetters } from "vuex";
import config from "@/utilities/config";
import Utility from "@/utilities/utility";
import ErrorHandler from "@/utilities/errorHandling";

@Options({
  props: [
    "disableDefaults",
    "level",
    "showDelete",
    "globalNotifications",
    "isViewOnly"
  ],
  components: {
    AddDefaults,
    DeleteDefaults
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 0,
      notifications: [],
      currentSortedProp: "",
      currentSortOrder: "",
      searchText: "",
      selectedNotifications: [],
      selectAllChecked: false
    };
  },
  created() {
    const env = config.env;
    this.pageSize = `${Utility.getConfig(env, "VUE_APP_RECORD_COUNT")}`;
  },
  computed: {
    pages() {
      return ceil(this.notifications.length / this.pageSize);
    },
    paginatedRecords() {
      return chunk(this.notifications, this.pageSize)[this.currentPage - 1];
    },
    ...mapGetters(["selectedProductType", "selectedProductId", "loggedInUser"])
  },
  methods: {
    ...mapActions(["toggleDefaults", "setSuccessMessage", "setErrorMessage"]),
    sortClasses(sortProp: string) {
      if (this.currentSortedProp === sortProp) {
        return this.currentSortOrder === "asc"
          ? "-sorted -ascending"
          : "-sorted -descending";
      } else {
        return "-sorted -ascending -nosort";
      }
    },
    pageChange: function(event: CustomEvent<string>) {
      this.currentPage = event.detail;
    },
    sort(sortProp: string) {
      if (this.currentSortedProp === sortProp) {
        this.currentSortOrder =
          this.currentSortOrder === "asc" ? "desc" : "asc";
      } else {
        this.currentSortedProp = sortProp;
        this.currentSortOrder = "asc";
      }
      this.notifications = orderBy(
        this.notifications,
        function(notif: NotificationEnhanced) {
          if (sortProp === "value" || sortProp === "type") {
            return notif.notification[sortProp]?.trim().toLowerCase();
          } else if (sortProp === "description") {
            return notif.notification.description
              ? notif.notification.description
              : "";
          } else {
            return notif.notification.defaulted;
          }
        },
        this.currentSortOrder
      );
    },
    filterNotifications(event: any) {
      this.currentPage = 1;
      this.notifications = this.globalNotifications.filter(function(
        notif: NotificationEnhanced
      ) {
        return (
          notif.notification.type
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) > -1 ||
          notif.notification.value
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) > -1 ||
          notif.notification.description
            ?.toLowerCase()
            .indexOf(event.target.value.toLowerCase()) > -1
        );
      });
    },
    select(notif: NotificationEnhanced) {
      notif.isSelected = !notif.isSelected;
      let flag = false;

      const checkboxes = document.querySelectorAll(
        "input[notification-selector]"
      );
      for (const index in checkboxes) {
        const value = (checkboxes[index] as HTMLInputElement).value;
        if (value !== undefined) {
          if (!(checkboxes[index] as HTMLInputElement).checked) {
            flag = false;
            break;
          }
          flag = true;
        }
      }

      this.selectAllChecked = flag;

      if (this.level == "tn") {
        this.$emit("selected-notifs", this.selectedNotifications);
      }
    },
    selectAll(event: any) {
      const checkboxes = document.querySelectorAll(
        "input[notification-selector]"
      );
      for (const index in checkboxes) {
        const value = (checkboxes[index] as HTMLInputElement).value;
        if (value !== undefined) {
          (checkboxes[index] as HTMLInputElement).checked =
            event.target.checked;
        }
      }
      if (event.target.checked) {
        this.selectedNotifications = cloneDeep(this.notifications);
        this.selectAllChecked = true;
      } else {
        this.selectedNotifications = [];
        this.selectAllChecked = false;
      }

      if (this.level == "tn") {
        this.$emit("selected-notifs", this.selectedNotifications);
      }
    },
    async toggle(notif: NotificationEnhanced, index: number) {
      if (notif.notification.type == "EMAIL") {
        const emailNotifs = this.globalNotifications.filter(
          (notif: { notification: { defaulted: any; type: string } }) =>
            notif.notification.defaulted && notif.notification.type == "EMAIL"
        );

        if (emailNotifs.length + 1 > 10 && !notif.notification.defaulted) {
          this.$refs["toggle" + index].checked = notif.notification.defaulted;
          this.setErrorMessage("Only 10 default Emails are allowed!");
          return;
        }
      } else if (notif.notification.type == "SMS") {
        const smsNotifs = this.globalNotifications.filter(
          (notif: { notification: { defaulted: any; type: string } }) =>
            notif.notification.defaulted && notif.notification.type == "SMS"
        );

        if (smsNotifs.length + 1 > 10 && !notif.notification.defaulted) {
          this.$refs["toggle" + index].checked = notif.notification.defaulted;
          this.setErrorMessage("Only 10 default SMSes are allowed!");
          return;
        }
      }
      notif.notification.defaulted = !notif.notification.defaulted;

      try {
        await this.$store.dispatch("toggleDefaults", [
          notif,
          this.selectedProductType,
          this.selectedProductId,
          this.loggedInUser.id
        ]);

        this.setSuccessMessage("Data updated successfully!");
        this.$emit("set-tenant-level-change");
      } catch (e) {
        this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
      }
      if (this.disableDefaults !== "true") {
        this.$emit("on-toggle", notif);
      }
    },
    getSelectedNotifications() {
      return this.selectedNotifications;
    },
    setSelectedNotifications(preSelectedNotifications: NotificationEnhanced[]) {
      this.selectedNotifications = preSelectedNotifications;
    },
    getHighlightedSearchText(content: string) {
      if (!this.searchText || !content) {
        return content;
      }
      return content.replace(new RegExp(this.searchText, "gi"), match => {
        return '<span class="-bg--muted">' + match + "</span>";
      });
    },
    removeNotifications() {
      let flag = false;
      for (const i in this.selectedNotifications) {
        if (this.selectedNotifications[i].notification.defaulted) {
          flag = true;
          break;
        }
      }
      this.selectedNotifications = [];
      this.selectAllChecked = false;
      if (flag) {
        this.$emit("set-tenant-level-change");
      }
    },
    callSiteLevel() {
      this.$emit("set-notifications-site");
    },
    callTenantLevel() {
      console.log("call tenant level");
      this.$emit("set-tenant-level-change");
    },
    setCheckAllNotifications(flag: boolean) {
      console.log("set check all");
      this.selectAllChecked = flag;
    }
  },
  mounted() {
    document.addEventListener("chiPageChange", this.pageChange, false);
    this.notifications = cloneDeep(this.globalNotifications);
  },
  watch: {
    globalNotifications(newVal, oldVal) {
      this.notifications = cloneDeep(newVal);
    }
  }
})
export default class Notifications extends Vue {}
</script>
<style scoped>
.chi .chi-table.-fixed--header:before {
  background-color: #edf0f2 !important;
  border-bottom: 0.0625rem solid #d0d4d9;
}

.chi .chi-table.-fixed--header th.-sorted.-ascending.-nosort div:after,
.chi .chi-table.-fixed--header th.-sorted.-descending.-nosort div:after {
  background-image: none;
  content: "\e921\0000A\e91e";
  height: 1.75rem;
}

.hyphenate {
  /* Careful, this breaks the word wherever it is without a hyphen */
  overflow-wrap: break-word;
  word-wrap: break-word;

  /* Adds a hyphen where the word breaks */
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.divClass {
  min-width: 100px;
}
.paddingClass {
  padding-left: 100px;
}
</style>
