import { render } from "./TnRanges.vue?vue&type=template&id=ab7d63c0&scoped=true"
import script from "./TnRanges.vue?vue&type=script&lang=ts"
export * from "./TnRanges.vue?vue&type=script&lang=ts"

import "./TnRanges.vue?vue&type=style&index=0&id=ab7d63c0&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-ab7d63c0"
/* hot reload */
if (module.hot) {
  script.__hmrId = "ab7d63c0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('ab7d63c0', script)) {
    api.reload('ab7d63c0', script)
  }
  
  module.hot.accept("./TnRanges.vue?vue&type=template&id=ab7d63c0&scoped=true", () => {
    api.rerender('ab7d63c0', render)
  })

}

script.__file = "src/components/TnRanges.vue"

export default script