<template>
  <div class="site">
    <div class="-m--2">
      <message-alert alertClass="-mb--2" />
      <ContentHeader :level="level" />
      <div v-if="isProductIdSelected">
        <p class="-text--h5">
          Group / Location Level Notification Data
        </p>
        <p class="-text--sm -mt--0">
          This section is used to manage group / location level notifications
        </p>
        <br />
        <div class="-d--flex -justify-content--start">
          <div class="-pr--2">
            <chi-label>Select Group:</chi-label>
          </div>
          <div class="-pr--2">
            <drop-down
              :items="groups"
              @on-select="onSelectGroup($event)"
              show-item-value="true"
              show-search="true"
              data-target="#modalId"
              id="tooltip-80"
              ref="dropDownRef"
              @set-dispose="disposeModal"
            />

            <div class="chi-backdrop -closed">
              <div class="chi-backdrop__wrapper">
                <section
                  id="modalId"
                  class="chi-modal"
                  role="dialog"
                  aria-label="Modal description"
                  aria-modal="true"
                >
                  <header class="chi-modal__header">
                    <h2 class="chi-modal__title">Confirm to navigate</h2>
                    <button
                      class="chi-button -icon -close"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="close()"
                    >
                      <div class="chi-button__content">
                        <i class="chi-icon icon-x"></i>
                      </div>
                    </button>
                  </header>
                  <div class="chi-modal__content">
                    <p class="-text -m--0">
                      Changes detected for Group {{ selectedGroupId }}. <br />
                      Do you wish to navigate to another group before applying
                      the changes?
                      <br />
                      Click on "Continue" to continue the navigation or on
                      "Cancel" to stay on this group.
                    </p>
                  </div>
                  <footer class="chi-modal__footer">
                    <button
                      class="chi-button"
                      data-dismiss="modal"
                      @click="close()"
                    >
                      Cancel
                    </button>
                    <button
                      class="chi-button -primary"
                      data-dismiss="modal"
                      @click="setGroupId()"
                    >
                      Continue
                    </button>
                  </footer>
                </section>
              </div>
            </div>
          </div>
          <br />
          <div>
            <chi-button
              color="primary"
              :disabled="selectedGroupId === ''"
              @click="get()"
              >Get
            </chi-button>
          </div>
          <div></div>
          <div></div>
          <div style="padding-left:8px;">
            <chi-button
              color="primary"
              @click="exportNotifications"
              :disabled="selectedGroupId === ''"
              >Export Notification Data
            </chi-button>
          </div>
        </div>
        <p class="-text--info" v-if="showNoDefaultsFoundNote">
          <b>Note:</b> No default notifications found for the selected group /
          location
        </p>
        <br />
        <div v-show="showNotifications" class="-d--flex">
          <Notifications
            :global-notifications="notificationsArray"
            disable-defaults="true"
            level="site"
            show-delete="false"
            ref="notificationsRef"
            v-on:set-notifications-site="setSiteLevelNotifications"
            :isViewOnly="loggedInUser.isViewOnly"
          />
        </div>
        <!--<div v-show="showNotifications">-->
        <div
          class="-d--flex -justify-content--start -mt--2"
          v-show="showNotifications"
        >
          <div class="-pr--2">
            <chi-button
              id="saveSiteNotifications"
              color="primary"
              :disabled="selectedGroupId === '' || loggedInUser.isViewOnly"
              >Save Default Notifications
            </chi-button>
          </div>
          <div class="-pr--2">
            <ReplyEmail
              :level="level"
              :selectedGroupId="selectedGroupId"
              :displayValue="displayVal"
              :selectedNotifs="sendNotifications"
              :isViewOnly="loggedInUser.isViewOnly"
              :selectedGroupName="selectedGroupName"
              popUpStatement="Confirm to submit"
              :isSaveNeeded="isSaveNeeded"
              :selectedNotificationsOnSite="getSelectedNotifications()"
              @set-change="setChange"
            />
          </div>
        </div>
        <br />
        <div class="-d--flex" v-show="showNotifications">
          <DeleteNotifications
            :level="level"
            :selectedGroupId="selectedGroupId"
            :displayValue="displayValDelete"
            :isViewOnly="loggedInUser.isViewOnly"
            :selectedGroupName="selectedGroupName"
            popUpStatement="Confirm to delete"
          />
        </div>
        <!--</div>-->
      </div>
    </div>
    <br />
    <div v-if="isProductIdSelected">
      <div>
        <!--<chi-button color="primary" variant="flat" @click="tenantLevel()"
          >Enable unique email notification settings at the Tenant level
        </chi-button>-->

        <route-to-site-or-tn-or-tenant
          :isChanged="isChanged"
          level="Tenant"
          v-on:tenant-level="tenantLevel"
          modalId="modal-20"
          tooltipId="tooltip-20"
          level2="Group/Location"
          classType="-flat"
          title="Enable unique email notification settings at the Tenant level"
        />
      </div>
      <div>
        <!--<chi-button color="primary" variant="flat" @click="$router.push('tn')"
          >Enable unique email notification settings at the Telephone Number
          level
        </chi-button>-->
        <route-to-site-or-tn-or-tenant
          :isChanged="isChanged"
          level="Telephone number"
          v-on:tn-level="tnLevel"
          modalId="modal-21"
          tooltipId="tooltip-21"
          level2="Group/Location"
          classType="-flat"
          title="Enable unique email notification settings at the Telephone Number level"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Header from "@/components/Header.vue";
import ContentHeader from "@/components/ContentHeader.vue";
import Notifications from "@/components/Notifications.vue";
import { mapActions, mapGetters } from "vuex";
import DropDown from "@/components/DropDown.vue";
import MessageAlert from "@/components/MessageAlert.vue";
import ErrorHandler from "@/utilities/errorHandling";
import Utility from "@/utilities/utility";
import ReplyEmail from "@/components/ReplyEmail.vue";
import { NotificationEnhanced } from "@/models/notification";
import ExportToExcel from "@/utilities/exportToExcel";
import DeleteNotifications from "@/components/DeleteNotifications.vue";
import * as _ from "lodash";
import RouteToSiteOrTnOrTenant from "@/components/RouteToSiteOrTnOrTenant.vue";
declare let chi: any;

@Options({
  components: {
    Header,
    ContentHeader,
    Notifications,
    DropDown,
    MessageAlert,
    ReplyEmail,
    DeleteNotifications,
    RouteToSiteOrTnOrTenant
  },
  data() {
    return {
      selectedGroupId: "",
      selectedGroupName: "",
      showNotifications: false,
      showNoDefaultsFoundNote: false,
      level: "sites",
      displayVal: "Apply default notifications to all Telephone Numbers",
      displayValDelete:
        "Delete default notifications from all Telephone Numbers",
      notificationsArray: [],
      selectedNotifications: [],
      sendNotifications: [],
      header: [
        [
          "Telephone Number",
          "Group Name(Group ID)",
          "EMAIL Notifications",
          "SMS Notifications"
        ]
      ],
      colInfoArray: [
        {
          width: 18
        },
        {
          width: 25
        },
        {
          width: 50
        },
        {
          width: 50
        }
      ],
      sheetName: "notifications",
      fileName: "911_ManagerNotifications.xlsx",
      isSaveNeeded: false,
      isChanged: false,
      itemVal: []
    };
  },
  methods: {
    ...mapActions([
      "fetchGroupNotifications",
      "hideMessage",
      "setErrorMessage",
      "setSuccessMessage",
      "saveGroupNotifications",
      "fetchNotifications",
      "setInfoMessage",
      "getAllTnNotifications"
    ]),
    async onSelectGroup(item: any) {
      await chi.modal(document.getElementById("tooltip-80")).dispose();
      await this.hideMessage();
      //chi.modal(document.getElementById("tooltip-80")).dispose();
      this.itemVal = item;
      console.log(
        "selected group " +
          this.selectedGroupId +
          " and item val : " +
          this.itemVal.value
      );
      if (
        this.isChanged &&
        !_.isEmpty(this.selectedGroupId) &&
        this.selectedGroupId !== item.value
      ) {
        console.log("value of isChanged is: " + this.isChanged);
        chi.modal(document.getElementById("tooltip-80")).show();
      } else {
        console.log("chi log");
        if (this.selectedGroupId !== item.value) {
          this.selectedGroupId = item.value;
          this.selectedGroupName = item.label;
          this.showNotifications = false;
          this.showNoDefaultsFoundNote = false;
          this.$refs.notificationsRef.setCheckAllNotifications(false);
        }
      }
    },
    async setGroupId() {
      if (this.selectedGroupId !== this.itemVal.value) {
        this.selectedGroupId = this.itemVal.value;
        this.selectedGroupName = this.itemVal.label;
        this.showNotifications = false;
        this.showNoDefaultsFoundNote = false;
        this.$refs.notificationsRef.setCheckAllNotifications(false);
      }
      this.isChanged = false;
      await chi.modal(document.getElementById("tooltip-80")).hide();
    },
    async close() {
      await chi.modal(document.getElementById("tooltip-80")).hide();
      await this.$refs.dropDownRef.setItemValue(
        this.selectedGroupId,
        this.selectedGroupName
      );
    },
    async disposeModal() {
      await chi.modal(document.getElementById("tooltip-80")).dispose();
    },
    setChange() {
      this.isChanged = false;
    },
    async get() {
      //event.preventDefault();
      //chi.modal(document.getElementById("tooltip-80")).dispose();
      this.isChanged = false;
      this.hideMessage();
      if (this.selectedGroupId == "") {
        return;
      }
      const productId = this.selectedProductId;
      const productType = this.selectedProductType;
      try {
        await this.fetchGroupNotifications([
          this.selectedProductType,
          this.selectedProductId,
          this.selectedGroupId,
          this.loggedInUser.id
        ]);

        const notifOrginalGroup = Utility.changeGroupNotifications(
          this.notifications,
          this.notificationsOriginalGroup
        );

        this.sendNotifications = notifOrginalGroup;
        const selectNotifications: Array<NotificationEnhanced> = [];
        this.selectedNotifications = selectNotifications;
        for (const i in notifOrginalGroup) {
          this.selectedNotifications.push(
            new NotificationEnhanced(notifOrginalGroup[i], false)
          );
        }
        this.notificationsArray = Utility.getMergedNotifications(
          this.notifications,
          notifOrginalGroup
        );

        this.$nextTick(() => {
          this.$refs.notificationsRef.setSelectedNotifications(
            this.selectedNotifications
          );
        });
        if (this.notificationsOriginalGroup.length === 0) {
          this.showNoDefaultsFoundNote = true;
        }
      } catch (e) {
        if (e.message.indexOf("404", 0) > -1) {
          this.setInfoMessage(e.message.substring(0, e.message.indexOf("(")));
        } else {
          this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        }

        this.notificationsArray = this.notifications;
        this.$nextTick(() => {
          this.$refs.notificationsRef.setSelectedNotifications([]);
        });
        this.sendNotifications = [];
      }
      this.showNotifications = true;
    },
    async save(event: CustomEvent<any>) {
      if (this.selectedGroupId == "") {
        return;
      }
      const payload = Utility.getPayloadforSaveGroupNotifications(
        this.selectedGroupId,
        this.selectedGroupName,
        this.$refs.notificationsRef.getSelectedNotifications()
      );
      try {
        await this.saveGroupNotifications([
          this.selectedProductType,
          this.selectedProductId,
          payload
        ]);
        await this.get();
        this.setSuccessMessage(
          "Group/Location defaults have been saved successfully. Notifications still need to be applied."
        );
        this.isChanged = true;
        this.isSaveNeeded = false;
      } catch (e) {
        this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
      }
    },
    appy() {
      console.log("TBD apply()");
    },
    async tenantLevel() {
      this.$router.push("/");
    },
    async tnLevel() {
      this.$router.push("tn");
    },
    setSiteLevelNotifications() {
      console.log("site level ");
      const notifOrginalGroup = Utility.changeGroupNotifications(
        this.notifications,
        this.notificationsOriginalGroup
      );
      this.notificationsArray = Utility.getMergedNotifications(
        this.notifications,
        notifOrginalGroup
      );
      this.isSaveNeeded = true;
      this.isChanged = true;
    },
    async exportNotifications() {
      this.hideMessage();
      this.isChanged = false;
      if (this.selectedGroupId == "") {
        return;
      }
      try {
        await this.$store.dispatch("getAllTnNotifications", [
          this.selectedProductType,
          this.selectedProductId,
          this.selectedGroupId,
          this.loggedInUser.id
        ]);

        ExportToExcel.exportToExcel(
          this.header,
          this.fileName,
          this.sheetName,
          this.exportNotif,
          this.colInfoArray
        );
      } catch (e) {
        if (e.message.indexOf("404", 0) > -1) {
          this.setInfoMessage(e.message.substring(0, e.message.indexOf("(")));
        } else {
          this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        }
      }
    },
    getSelectedNotifications() {
      return _.isEmpty(this.$refs.notificationsRef)
        ? []
        : this.$refs.notificationsRef.getSelectedNotifications();
    }
  },
  computed: {
    ...mapGetters([
      "isProductIdSelected",
      "selectedProductId",
      "selectedProductType",
      "groups",
      "notificationsOriginalGroup",
      "notifications",
      "exportNotif",
      "loggedInUser"
    ])
  },
  mounted() {
    document
      .getElementById("saveSiteNotifications")
      ?.addEventListener("chiClick", this.save, false);
  }
})
export default class Site extends Vue {}
</script>
