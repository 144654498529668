
import { Vue, Options } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";

import { MessageType } from "@/models/message";

@Options({
  props: ["alertClass"],
  methods: {
    ...mapActions([
      "setSuccessMessage",
      "setErrorMessage",
      "setInfoMessage",
      "setWarningMessage",
      "hideMessage"
    ])
  },
  computed: {
    ...mapGetters(["message", "showMessage"])
  },
  watch: {
    showMessage(newVal, oldVal) {
      if (document.documentElement && newVal && !oldVal) {
        document.documentElement.scrollTop = 0;
      }
    }
  }
})
export default class MessageAlert extends Vue {}
