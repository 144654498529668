<template>
  <div>
    <div class="chi-card -portal">
      <div class="chi-card__header -sm">
        <div
          class="chi-card__title -d--flex -justify-content--between"
          style="width: 100%;"
        >
          <div>
            <label class="chi-label" for="example__xl"
              >Enter semicolon separated Telephone Number(s):</label
            >
          </div>
        </div>
      </div>

      <div class="chi-card__content">
        <div class="chi-card__caption">
          <div>
            <textarea
              class="chi-input"
              id="example__xl"
              placeholder="Enter semicolon separated Telephone Number(s)"
              @keypress="allowNumbersAndCommaOnly"
              v-model="strTns"
              @input="onInput"
              :class="{ '-danger': !isValidInput }"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["on-change"],
  data() {
    return {
      strTns: ""
    };
  },
  methods: {
    allowNumbersAndCommaOnly(event: any) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || (charCode > 57 && charCode !== 59)) {
        event.preventDefault();
      }
    },
    onInput() {
      this.$emit("on-change", this.strTns);
    }
  },
  computed: {
    isValidInput() {
      const tns = this.strTns.split(";");
      if (tns[0]) {
        for (const tn of tns) {
          if (!/^[1-9]\d{9}$/.test(tn)) {
            return false;
          }
        }
      }
      return true;
    }
  }
})
export default class CommaSeperatedTns extends Vue {}
</script>
