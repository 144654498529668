
import { Options, Vue } from "vue-class-component";
import { mapActions } from "vuex";
declare let chi: any;

@Options({
  props: {
    npa: [],
    nxx: [],
    to: [],
    from: []
  },
  data() {
    return {
      maxRows: 2,
      telephoneNumbers: "",
      arr: [[]],
      errorField: false
    };
  },
  methods: {
    addNewRows() {
      this.maxRows += 1;
      this.arr[this.maxRows - 1] = [];
      for (let i = 0; i < 4; i++) {
        this.arr[this.maxRows - 1][i] = 0;
      }
    },
    eventEmit(value: string) {
      if (!this.splitNumbersAndValidate(value)) {
        this.errorField = true;
      } else {
        this.errorField = false;
      }
      this.$emit("telephone-numbers", value);
      this.telephoneNumbers = value;
    },
    validateData(val: string, row: number, col: number) {
      if (
        val != undefined &&
        (((col == 0 || col == 1) && val.length != 3) ||
          (!Number(val) && val != "0000" && val != "000") ||
          ((col == 2 || col == 3) && val.length != 4))
      ) {
        this.arr[row][col] = 1;
      } else {
        this.arr[row][col] = 0;
      }
    },
    setValues() {
      for (let i = 0; i < 2; i++) {
        this.arr[i] = [];
        for (let j = 0; j < 4; j++) {
          this.arr[i][j] = 0;
        }
      }
    },
    fieldDataColorCheck(row: number, column: number) {
      if (this.arr[row][column] != undefined) {
        if (this.arr[row][column] == 1) {
          return true;
        }
      }
      return false;
    },
    splitNumbersAndValidate(telephoneNumbers: string) {
      if (telephoneNumbers != "" && telephoneNumbers != null) {
        const arr: string[] = telephoneNumbers.split(",");
        if (arr == null) {
          return this.validateTelephoneNumber(telephoneNumbers);
        } else {
          for (const index in arr) {
            if (!this.validateTelephoneNumber(arr[index])) {
              return false;
            }
          }
        }
      }
      return true;
    },
    validateTelephoneNumber(telephoneNumber: string) {
      if (
        !parseInt(telephoneNumber) ||
        telephoneNumber.startsWith("0") ||
        telephoneNumber.length != 10
      ) {
        return false;
      }
      return true;
    },
    returnErrorField() {
      return this.errorField;
    }
  },
  mounted() {
    chi.tooltip(document.getElementById("tooltip-4"));
  },
  created() {
    this.setValues();
  }
})
export default class AddTelephoneNumbers extends Vue {}
