<template>
  <div
    id="message-alert-id"
    :class="[showMessage ? '-d--block' : '-d--none', alertClass]"
  >
    <!-- Success -->
    <div
      v-if="message.type === 'success'"
      class="chi-alert -success -lg -b--success-light -bg--success-lighter"
      role="alert"
    >
      <i class="chi-alert__icon chi-icon icon-circle-check"></i>
      <div class="chi-alert__content">
        <p class="chi-alert__title -text--lg">{{ message.title }}</p>
        <p class="chi-alert__text">
          {{ message.message }}
        </p>
      </div>
    </div>

    <!-- Warning -->
    <div
      v-if="message.type === 'warning'"
      class="chi-alert -warning -lg -b--warning-light -bg--warning-lighter"
      role="alert"
    >
      <i class="chi-alert__icon chi-icon icon-warning"></i>
      <div class="chi-alert__content">
        <p class="chi-alert__title -text--lg">{{ message.title }}</p>
        <p class="chi-alert__text">
          {{ message.message }}
        </p>
      </div>
    </div>

    <!-- Danger -->
    <div
      v-if="message.type === 'danger'"
      class="chi-alert -danger -lg -b--danger-light -bg--danger-lighter"
      role="alert"
    >
      <i class="chi-alert__icon chi-icon icon-circle-x"></i>
      <div class="chi-alert__content">
        <p class="chi-alert__title -text--lg">{{ message.title }}</p>
        <p class="chi-alert__text">
          {{ message.message }}
        </p>
      </div>
    </div>

    <!-- Info -->
    <div
      v-if="message.type === 'info'"
      class="chi-alert -info -lg -b--info-light -bg--info-lighter"
      role="alert"
    >
      <i class="chi-alert__icon chi-icon icon-circle-info"></i>
      <div class="chi-alert__content">
        <p class="chi-alert__title -text--lg">{{ message.title }}</p>
        <p class="chi-alert__text">
          {{ message.message }}
        </p>
      </div>
      <button
        class="chi-alert__close-button chi-button -icon -close"
        aria-label="Close"
        @click="hideMessage"
      >
        <div class="chi-button__content">
          <i class="chi-icon icon-x"></i>
        </div>
      </button>
    </div>

    <div
      v-if="message.type === 'loading'"
      class="chi-alert -info -lg -b--info-light -bg--info-lighter"
      role="alert"
    >
      <div class="chi-alert__icon">
        <svg class="chi-spinner -info -sm--2" viewBox="0 0 66 66">
          <title>Loading</title>
          <circle
            class="path"
            cx="33"
            cy="33"
            r="30"
            fill="none"
            stroke-width="6"
          ></circle>
        </svg>
      </div>
      <div class="chi-alert__content">
        <p class="chi-alert__title -text--lg">{{ message.title }}</p>
        <p class="chi-alert__text">
          {{ message.message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";

import { MessageType } from "@/models/message";

@Options({
  props: ["alertClass"],
  methods: {
    ...mapActions([
      "setSuccessMessage",
      "setErrorMessage",
      "setInfoMessage",
      "setWarningMessage",
      "hideMessage"
    ])
  },
  computed: {
    ...mapGetters(["message", "showMessage"])
  },
  watch: {
    showMessage(newVal, oldVal) {
      if (document.documentElement && newVal && !oldVal) {
        document.documentElement.scrollTop = 0;
      }
    }
  }
})
export default class MessageAlert extends Vue {}
</script>
