
import { Options, Vue } from "vue-class-component";
import { mapActions } from "vuex";
import * as _ from "lodash";
import Utility from "@/utilities/utility";
import EmpValidations from "@/utilities/empValidations";
declare let chi: any;

@Options({
  props: {
    isChanged: false,
    useOnlyDefaults: false,
    level: "",
    modalId: "",
    tooltipId: "",
    level2: "",
    classType: "",
    title: "",
    npa: [],
    nxx: [],
    to: [],
    from: [],
    telephoneNumbers: "",
    selectedTns: [],
    selectedNotifs: []
  },
  mounted() {
    this.hideMessage();
    //chi.tooltip(document.getElementById("tooltip-11"));
  },
  methods: {
    ...mapActions([
      "deleteNotifications",
      "setSuccessMessage",
      "hideMessage",
      "setErrorMessage"
    ]),
    isChangeDetected() {
      this.hideMessage();
      chi.modal(document.getElementById(this.tooltipId)).dispose();
      if (
        (this.isChanged && this.level2 != "Telephone number") ||
        (this.isChanged &&
          this.level2 == "Telephone number" &&
          this.checkIfTnsAndNotifsSelected())
      ) {
        console.log("route to site");
        chi.modal(document.getElementById(this.tooltipId)).show();
      } else {
        chi.modal(document.getElementById(this.tooltipId)).hide();
        this.navigate();
      }
    },
    checkIfTnsAndNotifsSelected() {
      if (
        !_.isEmpty(this.selectedNotifs) &&
        (!_.isEmpty(this.selectedTns) ||
          !_.isEmpty(this.telephoneNumbers) ||
          (!this.checkLengthAndEmpty(this.npa) &&
            !this.checkLengthAndEmpty(this.nxx) &&
            !this.checkLengthAndEmpty(this.from) &&
            !this.checkLengthAndEmpty(this.to)))
      ) {
        console.log(
          "telephone numbers : " +
            JSON.stringify(this.nxx) +
            JSON.stringify(this.npa) +
            JSON.stringify(this.to) +
            JSON.stringify(this.from) +
            this.npa.length
        );
        return true;
      }
      return false;
    },
    checkLengthAndEmpty(val: string[]) {
      if (
        EmpValidations.checkForNullAndEmpty(val) ||
        (val.length == 1 && val[0] == "")
      ) {
        return true;
      }
      return false;
    },
    navigate() {
      if (this.level == "Group / Location") {
        this.$emit("site-level");
      } else if (this.level == "Telephone number") {
        this.$emit("tn-level");
      } else if (this.level == "Tenant") {
        this.$emit("tenant-level");
      } else if (this.level == "Get") {
        this.$emit("get-val");
      }
    }
  }
})
export default class RouteToSite extends Vue {}
