
import { Options, Vue } from "vue-class-component";
import TnRange from "@/models/tnRange";

declare let chi: any;

@Options({
  props: ["title"],
  emits: ["on-change"],
  data() {
    return {
      tnRanges: [new TnRange("", "", "", ""), new TnRange("", "", "", "")]
    };
  },
  methods: {
    allowNumbersOnly(event: any) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    onPaste(event: any) {
      const clipboardData =
        event.clipboardData || (window as any).clipboardData;
      if (!/^[0-9]+$/.test(clipboardData.getData("text"))) {
        event.preventDefault();
      }
    },
    onChange(event: any) {
      this.$emit("on-change", this.tnRanges);
    },
    addNewRows() {
      this.tnRanges.push(new TnRange("", "", "", ""));
      this.$nextTick(() => {
        chi.tooltip(
          document.querySelectorAll("button[data-tooltip='Delete Row']")
        );
      });
      this.onChange();
      document.getElementById("tooltip-addmore")?.blur();
    },
    deleteRow(index: number) {
      chi
        .tooltip(document.getElementById("tooltip-deleterow-" + index))
        .dispose();
      this.tnRanges.splice(index, 1);
      this.$nextTick(() => {
        chi.tooltip(
          document.querySelectorAll("button[data-tooltip='Delete Row']")
        );
      });
      this.onChange();
    }
  },
  mounted() {
    chi.tooltip(document.getElementById("tooltip-addmore"));
    chi.tooltip(document.querySelectorAll("button[data-tooltip='Delete Row']"));
  }
})
export default class TnRanges extends Vue {}
