
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["on-change"],
  data() {
    return {
      strTns: ""
    };
  },
  methods: {
    allowNumbersAndCommaOnly(event: any) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || (charCode > 57 && charCode !== 59)) {
        event.preventDefault();
      }
    },
    onInput() {
      this.$emit("on-change", this.strTns);
    }
  },
  computed: {
    isValidInput() {
      const tns = this.strTns.split(";");
      if (tns[0]) {
        for (const tn of tns) {
          if (!/^[1-9]\d{9}$/.test(tn)) {
            return false;
          }
        }
      }
      return true;
    }
  }
})
export default class CommaSeperatedTns extends Vue {}
