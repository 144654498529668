
import { Options, Vue } from "vue-class-component";
import Header from "@/components/Header.vue";
import ContentHeader from "@/components/ContentHeader.vue";
import Notifications from "@/components/Notifications.vue";
import { mapActions, mapGetters } from "vuex";
import DropDown from "@/components/DropDown.vue";
import MessageAlert from "@/components/MessageAlert.vue";
import ErrorHandler from "@/utilities/errorHandling";
import Utility from "@/utilities/utility";
import ReplyEmail from "@/components/ReplyEmail.vue";
import { NotificationEnhanced } from "@/models/notification";
import ExportToExcel from "@/utilities/exportToExcel";
import DeleteNotifications from "@/components/DeleteNotifications.vue";
import * as _ from "lodash";
import RouteToSiteOrTnOrTenant from "@/components/RouteToSiteOrTnOrTenant.vue";
declare let chi: any;

@Options({
  components: {
    Header,
    ContentHeader,
    Notifications,
    DropDown,
    MessageAlert,
    ReplyEmail,
    DeleteNotifications,
    RouteToSiteOrTnOrTenant
  },
  data() {
    return {
      selectedGroupId: "",
      selectedGroupName: "",
      showNotifications: false,
      showNoDefaultsFoundNote: false,
      level: "sites",
      displayVal: "Apply default notifications to all Telephone Numbers",
      displayValDelete:
        "Delete default notifications from all Telephone Numbers",
      notificationsArray: [],
      selectedNotifications: [],
      sendNotifications: [],
      header: [
        [
          "Telephone Number",
          "Group Name(Group ID)",
          "EMAIL Notifications",
          "SMS Notifications"
        ]
      ],
      colInfoArray: [
        {
          width: 18
        },
        {
          width: 25
        },
        {
          width: 50
        },
        {
          width: 50
        }
      ],
      sheetName: "notifications",
      fileName: "911_ManagerNotifications.xlsx",
      isSaveNeeded: false,
      isChanged: false,
      itemVal: []
    };
  },
  methods: {
    ...mapActions([
      "fetchGroupNotifications",
      "hideMessage",
      "setErrorMessage",
      "setSuccessMessage",
      "saveGroupNotifications",
      "fetchNotifications",
      "setInfoMessage",
      "getAllTnNotifications"
    ]),
    async onSelectGroup(item: any) {
      await chi.modal(document.getElementById("tooltip-80")).dispose();
      await this.hideMessage();
      //chi.modal(document.getElementById("tooltip-80")).dispose();
      this.itemVal = item;
      console.log(
        "selected group " +
          this.selectedGroupId +
          " and item val : " +
          this.itemVal.value
      );
      if (
        this.isChanged &&
        !_.isEmpty(this.selectedGroupId) &&
        this.selectedGroupId !== item.value
      ) {
        console.log("value of isChanged is: " + this.isChanged);
        chi.modal(document.getElementById("tooltip-80")).show();
      } else {
        console.log("chi log");
        if (this.selectedGroupId !== item.value) {
          this.selectedGroupId = item.value;
          this.selectedGroupName = item.label;
          this.showNotifications = false;
          this.showNoDefaultsFoundNote = false;
          this.$refs.notificationsRef.setCheckAllNotifications(false);
        }
      }
    },
    async setGroupId() {
      if (this.selectedGroupId !== this.itemVal.value) {
        this.selectedGroupId = this.itemVal.value;
        this.selectedGroupName = this.itemVal.label;
        this.showNotifications = false;
        this.showNoDefaultsFoundNote = false;
        this.$refs.notificationsRef.setCheckAllNotifications(false);
      }
      this.isChanged = false;
      await chi.modal(document.getElementById("tooltip-80")).hide();
    },
    async close() {
      await chi.modal(document.getElementById("tooltip-80")).hide();
      await this.$refs.dropDownRef.setItemValue(
        this.selectedGroupId,
        this.selectedGroupName
      );
    },
    async disposeModal() {
      await chi.modal(document.getElementById("tooltip-80")).dispose();
    },
    setChange() {
      this.isChanged = false;
    },
    async get() {
      //event.preventDefault();
      //chi.modal(document.getElementById("tooltip-80")).dispose();
      this.isChanged = false;
      this.hideMessage();
      if (this.selectedGroupId == "") {
        return;
      }
      const productId = this.selectedProductId;
      const productType = this.selectedProductType;
      try {
        await this.fetchGroupNotifications([
          this.selectedProductType,
          this.selectedProductId,
          this.selectedGroupId,
          this.loggedInUser.id
        ]);

        const notifOrginalGroup = Utility.changeGroupNotifications(
          this.notifications,
          this.notificationsOriginalGroup
        );

        this.sendNotifications = notifOrginalGroup;
        const selectNotifications: Array<NotificationEnhanced> = [];
        this.selectedNotifications = selectNotifications;
        for (const i in notifOrginalGroup) {
          this.selectedNotifications.push(
            new NotificationEnhanced(notifOrginalGroup[i], false)
          );
        }
        this.notificationsArray = Utility.getMergedNotifications(
          this.notifications,
          notifOrginalGroup
        );

        this.$nextTick(() => {
          this.$refs.notificationsRef.setSelectedNotifications(
            this.selectedNotifications
          );
        });
        if (this.notificationsOriginalGroup.length === 0) {
          this.showNoDefaultsFoundNote = true;
        }
      } catch (e) {
        if (e.message.indexOf("404", 0) > -1) {
          this.setInfoMessage(e.message.substring(0, e.message.indexOf("(")));
        } else {
          this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        }

        this.notificationsArray = this.notifications;
        this.$nextTick(() => {
          this.$refs.notificationsRef.setSelectedNotifications([]);
        });
        this.sendNotifications = [];
      }
      this.showNotifications = true;
    },
    async save(event: CustomEvent<any>) {
      if (this.selectedGroupId == "") {
        return;
      }
      const payload = Utility.getPayloadforSaveGroupNotifications(
        this.selectedGroupId,
        this.selectedGroupName,
        this.$refs.notificationsRef.getSelectedNotifications()
      );
      try {
        await this.saveGroupNotifications([
          this.selectedProductType,
          this.selectedProductId,
          payload
        ]);
        await this.get();
        this.setSuccessMessage(
          "Group/Location defaults have been saved successfully. Notifications still need to be applied."
        );
        this.isChanged = true;
        this.isSaveNeeded = false;
      } catch (e) {
        this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
      }
    },
    appy() {
      console.log("TBD apply()");
    },
    async tenantLevel() {
      this.$router.push("/");
    },
    async tnLevel() {
      this.$router.push("tn");
    },
    setSiteLevelNotifications() {
      console.log("site level ");
      const notifOrginalGroup = Utility.changeGroupNotifications(
        this.notifications,
        this.notificationsOriginalGroup
      );
      this.notificationsArray = Utility.getMergedNotifications(
        this.notifications,
        notifOrginalGroup
      );
      this.isSaveNeeded = true;
      this.isChanged = true;
    },
    async exportNotifications() {
      this.hideMessage();
      this.isChanged = false;
      if (this.selectedGroupId == "") {
        return;
      }
      try {
        await this.$store.dispatch("getAllTnNotifications", [
          this.selectedProductType,
          this.selectedProductId,
          this.selectedGroupId,
          this.loggedInUser.id
        ]);

        ExportToExcel.exportToExcel(
          this.header,
          this.fileName,
          this.sheetName,
          this.exportNotif,
          this.colInfoArray
        );
      } catch (e) {
        if (e.message.indexOf("404", 0) > -1) {
          this.setInfoMessage(e.message.substring(0, e.message.indexOf("(")));
        } else {
          this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        }
      }
    },
    getSelectedNotifications() {
      return _.isEmpty(this.$refs.notificationsRef)
        ? []
        : this.$refs.notificationsRef.getSelectedNotifications();
    }
  },
  computed: {
    ...mapGetters([
      "isProductIdSelected",
      "selectedProductId",
      "selectedProductType",
      "groups",
      "notificationsOriginalGroup",
      "notifications",
      "exportNotif",
      "loggedInUser"
    ])
  },
  mounted() {
    document
      .getElementById("saveSiteNotifications")
      ?.addEventListener("chiClick", this.save, false);
  }
})
export default class Site extends Vue {}
