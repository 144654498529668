import * as _ from "lodash";
export default class EmpValidations {
  public static SUCCESS = "success";
  static validateTelephoneNumber(telephoneNumber: string): string {
    if (
      !parseInt(telephoneNumber) ||
      telephoneNumber.startsWith("0") ||
      telephoneNumber.length != 10
    ) {
      return telephoneNumber;
    }
    return this.SUCCESS;
  }

  static splitNumbersAndValidate(telephoneNumbers: string): string {
    if (telephoneNumbers != "" && telephoneNumbers != null) {
      const arr: string[] = telephoneNumbers.split(";").filter(item => item);
      if (arr == null || _.isEmpty(arr)) {
        return EmpValidations.validateTelephoneNumber(telephoneNumbers);
      } else {
        for (const index in arr) {
          if (
            !_.isEqual(
              EmpValidations.validateTelephoneNumber(arr[index].trim()),
              this.SUCCESS
            )
          ) {
            return arr[index].trim();
          }
        }
      }
    }
    return this.SUCCESS;
  }

  static checkEmail(emailVal: string): string {
    const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailVal == "" || emailVal == null) {
      return "Reply email field cannot be empty.";
    }
    if (!emailVal.trim().match(emailFormat)) {
      return "Invalid format for reply email: " + emailVal;
    }
    return this.SUCCESS;
  }

  static checkForNullAndEmpty(val: any): boolean {
    if (val == null || val == undefined || _.isEmpty(val)) {
      return true;
    }
    return false;
  }

  static emptyValues(
    nxx: any,
    npa: any,
    to: any,
    from: any,
    telephoneNumbers: string
  ) {
    if (
      this.checkForNullAndEmpty(nxx) &&
      this.checkForNullAndEmpty(npa) &&
      this.checkForNullAndEmpty(to) &&
      this.checkForNullAndEmpty(from) &&
      this.checkForNullAndEmpty(telephoneNumbers)
    ) {
      return "Enter either telephone numbers or Tn Ranges.";
    }
    return this.SUCCESS;
  }

  static calculateMaxLen(
    num1: number,
    num2: number,
    num3: number,
    num4: number
  ): number {
    let maxlen = num1;
    if (num2 > maxlen) {
      maxlen = num2;
      if (num3 > maxlen) {
        maxlen = num3;
      } else if (num4 > maxlen) {
        maxlen = num4;
      }
    } else if (num3 > maxlen) {
      maxlen = num3;
      if (num4 > maxlen) {
        maxlen = num4;
      }
    } else if (num4 > maxlen) {
      maxlen = num4;
    }

    return maxlen;
  }

  static checkTnRanges(
    val: string,
    type: string,
    tnLen: number,
    fromVal: string
  ): string {
    if (val == null || val == "") {
      return type + " cannot be empty . Fill it with " + tnLen + " digits.";
    } else if (type == "NPA" && val.startsWith("0")) {
      return type + " cannot start with 0";
    } else if (!parseInt(val) && val != "0000" && val != "000") {
      return type + " can only contain digits." + "Change the value : " + val;
    } else if (val.length != tnLen) {
      return (
        type +
        " with value " +
        val +
        " is of incorrect length. Enter " +
        tnLen +
        " digits."
      );
    } else if (type == "TO" && val < fromVal) {
      return (
        " FROM value cannot be greater than TO value for FROM value : " +
        fromVal +
        " and TO value : " +
        val
      );
    }

    return this.SUCCESS;
  }

  static validateTns(npa: any, nxx: any, to: any, from: any) {
    const npaLen = npa.length;
    const nxxLen = nxx.length;
    const toLen = to.length;
    const fromLen = from.length;

    const maxlen = EmpValidations.calculateMaxLen(
      npaLen,
      nxxLen,
      toLen,
      fromLen
    );
    let j = 0;
    const tnRange = [];
    for (let i = 0; i < maxlen; i++) {
      const npaTnRange = EmpValidations.checkTnRanges(npa[i], "NPA", 3, "");
      const nxxTnRange = EmpValidations.checkTnRanges(nxx[i], "NXX", 3, "");
      const fromTnRange = EmpValidations.checkTnRanges(from[i], "FROM", 4, "");
      const toTnRange = EmpValidations.checkTnRanges(to[i], "TO", 4, from[i]);
      if (!_.isEqual(npaTnRange, this.SUCCESS)) {
        return npaTnRange;
      } else if (!_.isEqual(nxxTnRange, this.SUCCESS)) {
        return nxxTnRange;
      } else if (!_.isEqual(fromTnRange, this.SUCCESS)) {
        return fromTnRange;
      } else if (!_.isEqual(toTnRange, this.SUCCESS)) {
        return toTnRange;
      } else {
        //need to change this
        let startRange = npa[i] + nxx[i] + from[i];
        const endRange = npa[i] + nxx[i] + to[i];
        while (startRange <= endRange) {
          tnRange[j] = _.toString(startRange);
          startRange++;
          j++;
        }
      }
    }
    return tnRange;
  }

  static getArrayOfTns(tnRange: string[], tns: string) {
    const arr: string[] = tns.split(";");
    if (_.isEmpty(arr)) {
      tnRange.push(tns);
    } else {
      arr.forEach(a => {
        tnRange.push(a);
      });
    }
  }
}
