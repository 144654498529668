
import { Notification } from "@/models/notification";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import * as _ from "lodash";
import ErrorHandler from "@/utilities/errorHandling";

declare let chi: any;

@Options({
  props: {
    notificationsArray: [],
    isViewOnly: false
  },
  mounted() {
    this.hideMessage();
    chi.tooltip(document.getElementById("tooltip-2"));
  },
  methods: {
    ...mapActions([
      "deleteNotifications",
      "setSuccessMessage",
      "hideMessage",
      "setErrorMessage",
      "fetchNotifications"
    ]),
    checkNotificationSelected() {
      this.hideMessage();
      chi.modal(document.getElementById("tooltip-2")).dispose();
      if (
        this.notificationsArray != null &&
        !_.isEmpty(this.notificationsArray)
      ) {
        chi.modal(document.getElementById("tooltip-2")).show();
      } else {
        this.setErrorMessage("Please select notifications to delete.");

        chi.modal(document.getElementById("tooltip-2")).hide();
      }
    },
    async checkNotificationSelectedAndDelete() {
      const notificationsEnhancedArray: Notification[] = [];

      for (let i = 0; i < this.notificationsArray.length; i++) {
        notificationsEnhancedArray[i] = this.notificationsArray[i].notification;
      }
      try {
        await this.deleteNotifications([
          this.selectedProductType,
          this.selectedProductId,
          notificationsEnhancedArray,
          this.loggedInUser.id
        ]);
        this.setSuccessMessage(
          notificationsEnhancedArray.length +
            " record(s) deleted successfully! This will not delete applied notifications."
        );
        this.$emit("clicked", "removeNotifications");
      } catch (e) {
        this.setErrorMessage("Delete not successful!");
      }

      try {
        await this.fetchNotifications([
          {
            id: this.selectedProductId,
            type: this.selectedProductType
          },
          this.loggedInUser.id
        ]);
      } catch (e) {
        if (e.message.indexOf("404", 0) > -1) {
          this.setInfoMessage(e.message.substring(0, e.message.indexOf("(")));
        } else {
          this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        }
      }
    },
    close() {
      this.isNotificationSelected = true;
    }
  },
  computed: {
    ...mapGetters([
      "notifications",
      "selectedProductType",
      "selectedProductId",
      "API_DATA",
      "loggedInUser"
    ])
  }
})
export default class DeleteDefaults extends Vue {}
