import { Notification } from "@/models/notification";

export class NotificationPreferenceVO {
  constructor(
    private accountId: string,
    private productInstance: ProductInstanceVO
  ) {}
}

export class ProductInstanceVO {
  constructor(
    private productId: string,
    private applyNotificationToAll: string,
    private replyEmail: string,
    private sites: SiteInstanceVO[] | null
  ) {}
}

export class SiteInstanceVO {
  constructor(
    public applyNotificationToAll: string,
    public siteId: string,
    public siteName: string,
    public tnInstances: TnInstanceVO[] | null
  ) {}
}

export class TnInstanceVO {
  constructor(private tn: string, private notifications: Notification[]) {}
}

export class DeleteProductInstance {
  constructor(
    private productId: string,
    private deleteAllNotifications: boolean,
    private replyEmail: string,
    private sites: DeleteForSite[] | null
  ) {}
}

export class DeleteForSite {
  constructor(
    public siteId: string,
    public siteName: string,
    public deleteAllNotifications: boolean,
    public tns: string[]
  ) {}
}

export class DeleteNotificationPreferenceVO {
  constructor(
    private accountId: string,
    private productInstance: DeleteProductInstance
  ) {}
}
