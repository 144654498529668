<template>
  <div class="chi-grid" v-if="showContentHeader">
    <div class="chi-col -w--2">
      <chi-label for="product-type">Customer ID:</chi-label>
      <p id="product-type" class="-text--md">{{ customer.id }}</p>
    </div>
    <div class="chi-col -w--2">
      <chi-label for="product-type">Customer Name:</chi-label>
      <p id="product-type" class="-text--md">{{ customer.name }}</p>
    </div>
    <div class="chi-col -w--2" v-if="customer.isHasSingleProduct()">
      <chi-label for="product-id-1">Product ID:</chi-label>
      <p id="product-id-1" class="-text--md">
        {{ customer.products[0].id }}
      </p>
    </div>
    <div class="chi-col -w--2" v-else>
      <chi-label for="product-id-2">Product ID:</chi-label>
      <div class="chi-dropdown" id="product-id-2">
        <button
          id="dropdown-animate"
          class="chi-button chi-dropdown__trigger -animate"
        >
          {{ selectedProductId }}
        </button>
        <div class="chi-dropdown__menu">
          <a
            class="chi-dropdown__menu-item"
            href="#"
            v-for="id in productIds"
            :key="id"
            @click="onProductSelect(id)"
            >{{ id }}</a
          >
        </div>
      </div>
    </div>
    <div class="chi-col -w--2">
      <chi-label for="product-type">Product Type:</chi-label>
      <p id="product-type" class="-text--md">{{ selectedProductType }}</p>
    </div>
    <div class="chi-col">
      <!--<chi-button
        color="primary"
        variant="flat"
        @click="exportNotifications()"
        v-if="isProductIdSelected"
        >Export Notification Data </chi-button
      ><br />
      <chi-button
        color="primary"
        variant="flat"
        @click="importNotifications()"
        v-if="isProductIdSelected"
        >Import Notification Data
      </chi-button>
      -->
    </div>
  </div>
  <div v-if="showContentHeader && isProductIdSelected">
    <div class="chi-divider"></div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapActions } from "vuex";
import Customer, { Group, Product } from "@/models/customer";
import ErrorHandler from "@/utilities/errorHandling";
import * as _ from "lodash";
import ImportFromExcel from "@/components/ImportFromExcel.vue";
import ExportToExcel from "@/components/ExportToExcel.vue";
import User from "@/models/user";

declare let chi: any;

@Options({
  props: {
    level: ""
  },
  components: {
    ImportFromExcel,
    ExportToExcel
  },
  data() {
    return {
      showContentHeader: true
    };
  },
  methods: {
    ...mapActions([
      "setLoggedInUser",
      "setCustomer",
      "updateSelectedProductId",
      "fetchNotifications",
      "fetchGroupsForSelectedProduct",
      "hideMessage",
      "setErrorMessage",
      "setWarningMessage",
      "setInfoMessage",
      "getAllTnNotifications",
      "validateToken",
      "getAllowChange"
    ]),
    async onProductSelect(id: string) {
      //this.setSpinDisplay(true);
      this.hideMessage();
      await this.updateSelectedProductId(id);
      this.$store.commit("setNotifications", []);
      try {
        if (this.$route.name === "site" || this.$route.name === "tn") {
          await this.fetchGroupsForSelectedProduct();
        }
        await this.fetchNotifications([
          {
            id: id,
            type: this.selectedProductType
          },
          this.loggedInUser.id
        ]);

        if (this.level == "Tenant") {
          await this.getAllowChange([this.selectedProductType, id]);
          //this.$emit("use-only-defaults");
        }
      } catch (e) {
        if (e.message.indexOf("404", 0) > -1) {
          this.setInfoMessage(e.message.substring(0, e.message.indexOf("(")));
        } else {
          this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        }
      }
    },
    importNotifications() {
      console.log("TBD: import");
    },
    async validateTokenData(userid: string, token: string) {
      try {
        await this.validateToken([userid, token]);
      } catch (e) {
        this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
      }
    }
  },
  computed: {
    ...mapGetters([
      "loggedInUser",
      "customer",
      "productIds",
      "selectedProductType",
      "selectedProductId",
      "notifications",
      "isProductIdSelected",
      "exportNotif",
      "userValidated",
      "loggedInUser"
    ])
  },
  mounted() {
    if (!this.customer.isHasSingleProduct()) {
      chi.dropdown(document.getElementById("dropdown-animate"));
    }
    if (
      this.customer.products.length == 1 &&
      this.selectedProductId == this.customer.id
    ) {
      this.showContentHeader = false;
    }
  },
  async created() {
    if (this.$route.query.userid && this.$route.query.token) {
      await this.validateTokenData(
        this.$route.query.userid,
        this.$route.query.token
      );
    }
    if (
      window.location.origin !== "http://localhost:8080" &&
      !this.userValidated
    ) {
      this.setErrorMessage(
        "Application error has occured. Please contact system admin."
      );
      return;
    }
    if (_.isEmpty(this.loggedInUser.id)) {
      this.setLoggedInUser(
        new User(
          this.$route.query.userid,
          this.$route.query.userrole,
          this.$route.query.isviewonly
            ? (this.$route.query.isviewonly as string).toLowerCase() === "true"
            : false,
          this.$route.query.isadminuser
            ? (this.$route.query.isadminuser as string).toLowerCase() === "true"
            : false
        )
      );
    }

    if (this.$route.query.custid) {
      const productArray: Product[] = [];
      if (this.$route.query.products) {
        const aoaProducts = JSON.parse(this.$route.query.products);
        for (const arr of aoaProducts) {
          const groups: Group[] = [];
          if (arr[2] && Array.isArray(arr[2]) && arr[2].length > 0) {
            for (const groupId of arr[2]) {
              groups.push(new Group(groupId, ""));
            }
          }
          productArray.push(new Product(arr[0], arr[1], groups));

          if (aoaProducts.length === 1 && this.$route.query.custid === arr[0]) {
            this.showContentHeader = false;
          }
        }
      }

      this.setCustomer(
        new Customer(
          this.$route.query.custid,
          this.$route.query.custname,
          productArray
        )
      );

      if (productArray != null && productArray.length == 1) {
        this.onProductSelect(productArray[0].id);
      }
    } else if (
      this.level == "Tenant" &&
      (this.selectedProductId != "" || !_.isEmpty(this.selectedProductId))
    ) {
      this.onProductSelect(this.selectedProductId);
    }
  }
})
export default class ContentHeader extends Vue {}
</script>
