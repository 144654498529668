import { render } from "./Notifications.vue?vue&type=template&id=43a3e4e8&scoped=true"
import script from "./Notifications.vue?vue&type=script&lang=ts"
export * from "./Notifications.vue?vue&type=script&lang=ts"

import "./Notifications.vue?vue&type=style&index=0&id=43a3e4e8&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-43a3e4e8"
/* hot reload */
if (module.hot) {
  script.__hmrId = "43a3e4e8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('43a3e4e8', script)) {
    api.reload('43a3e4e8', script)
  }
  
  module.hot.accept("./Notifications.vue?vue&type=template&id=43a3e4e8&scoped=true", () => {
    api.rerender('43a3e4e8', render)
  })

}

script.__file = "src/components/Notifications.vue"

export default script