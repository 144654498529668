import { render } from "./MultiSelector.vue?vue&type=template&id=4fb7e790"
import script from "./MultiSelector.vue?vue&type=script&lang=ts"
export * from "./MultiSelector.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4fb7e790"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4fb7e790', script)) {
    api.reload('4fb7e790', script)
  }
  
  module.hot.accept("./MultiSelector.vue?vue&type=template&id=4fb7e790", () => {
    api.rerender('4fb7e790', render)
  })

}

script.__file = "src/components/MultiSelector.vue"

export default script