import { render } from "./CommaSeperatedTns.vue?vue&type=template&id=5ac2ba5e"
import script from "./CommaSeperatedTns.vue?vue&type=script&lang=ts"
export * from "./CommaSeperatedTns.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5ac2ba5e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5ac2ba5e', script)) {
    api.reload('5ac2ba5e', script)
  }
  
  module.hot.accept("./CommaSeperatedTns.vue?vue&type=template&id=5ac2ba5e", () => {
    api.rerender('5ac2ba5e', render)
  })

}

script.__file = "src/components/CommaSeperatedTns.vue"

export default script