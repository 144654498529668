export enum MessageType {
  // success, warning, danger, info
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "danger",
  INFO = "info",
  LODADING = "loading"
}

export enum MessageIcon {
  // circle-info, circle-check, circle-warning
  SUCCESS = "circle-check",
  WARNING = "circle-warning",
  ERROR = "circle-warning",
  INFO = "circle-info"
}

export enum MessageTitle {
  SUCCESS = "Success",
  WARNING = "Needs Attention",
  ERROR = "Error",
  INFO = "Info"
}

export interface Message {
  message: string;
  icon: string;
  type: string;
  title: string;
}

export default class MessageHandler {
  static getSuccessMessage(msg: string): Message {
    const message: Message = {
      message: msg,
      title: MessageTitle.SUCCESS,
      icon: MessageIcon.SUCCESS,
      type: MessageType.SUCCESS
    };
    return message;
  }

  static getEmptyMessage(): Message {
    const message: Message = {
      message: "",
      title: "",
      icon: "",
      type: ""
    };
    return message;
  }

  static getErrorMessage(msg: string): Message {
    const message: Message = {
      message: msg,
      title: MessageTitle.ERROR,
      icon: MessageIcon.ERROR,
      type: MessageType.ERROR
    };
    return message;
  }

  static getInfoMessage(msg: string): Message {
    const message: Message = {
      message: msg,
      title: MessageTitle.INFO,
      icon: MessageIcon.INFO,
      type: MessageType.INFO
    };
    return message;
  }

  static getLoadingMessage(msg: string): Message {
    const message: Message = {
      message: msg,
      title: MessageTitle.INFO,
      icon: MessageIcon.INFO,
      type: MessageType.LODADING
    };
    return message;
  }

  static getWarningMessage(msg: string): Message {
    const message: Message = {
      message: msg,
      title: MessageTitle.WARNING,
      icon: MessageIcon.WARNING,
      type: MessageType.WARNING
    };
    return message;
  }
}
