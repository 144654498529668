
import { Options, Vue } from "vue-class-component";
import xlsx from "xlsx";
import MessageAlert from "@/components/MessageAlert.vue";
import { mapGetters } from "vuex";
import Utility from "@/utilities/utility";
import * as _ from "lodash";

declare let chi: any;

@Options({
  props: ["header", "raw", "skipHeader", "isDisabled"], // both header and raw are mandatory properties
  data() {
    return {
      jsonData: {},
      emailVal: "",
      fileLabel: "No file chosen",
      invalidData: false
    };
  },
  components: {
    MessageAlert
  },
  methods: {
    close() {
      this.$store.commit("SET_API_DATA_IMPORT_DEFAULT");
      this.emailVal = "";
      this.jsonData = {};
      this.fileLabel = "No file chosen";
    },
    async importFromExcel() {
      this.$store.commit("SET_API_DATA_IMPORT_DEFAULT");
      if (_.isEmpty(this.emailVal)) {
        this.$store.commit(
          "SET_API_DATA_IMPORT_ERROR",
          "Reply Email value cannot be empty."
        );
        return;
      }
      const val = Utility.validateEmail(this.emailVal);
      if (!_.isEmpty(val)) {
        this.$store.commit("SET_API_DATA_IMPORT_ERROR", val);
        return;
      }
      await this.$emit("on-import", {
        jsonData: this.jsonData,
        emailVal: this.emailVal.trim(),
        invalidData: this.invalidData
      });
      if (!this.API_DATA.import.error) {
        chi.modal(document.getElementById("modal-trigger-10")).hide();
      }
    }
  },
  mounted() {
    // Modal Window'
    this.fileLabel = "No file chosen";
    chi.modal(document.getElementById("modal-trigger-10"));

    // Upload file
    const inputFiles = document.querySelectorAll(
      'input[type="file"].chi-file-input'
    );
    Array.prototype.forEach.call(inputFiles, input => {
      const label = input.nextElementSibling;

      input.addEventListener("change", (e: Event) => {
        let fileName: string | undefined = "";
        let file: File | null | undefined;

        const inputElement = e.target;
        if (inputElement instanceof HTMLInputElement) {
          if (inputElement.files && inputElement.files.length > 1) {
            fileName = inputElement.files.length + " files selected";
          } else {
            fileName = inputElement.value.split("\\").pop();
          }
          file = inputElement.files?.item(0);
        }
        if (fileName) {
          this.fileLabel = fileName;
        }
        console.log("file type is " + file?.type);
        if (
          file?.type !=
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
          file?.type != "application/vnd.ms-excel"
        ) {
          this.jsonData = {};
          this.invalidData = true;
          return;
        } else {
          this.invalidData = false;
        }
        console.log("file name " + fileName);
        // Reading file content into workbook
        const reader = new FileReader();

        reader.onload = pe => {
          if (pe.target?.result instanceof ArrayBuffer) {
            const data = new Uint8Array(pe.target?.result);
            const workbook = xlsx.read(data, { type: "array" });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const sheetData = xlsx.utils.sheet_to_json(firstSheet, {
              header: this.header,
              raw: this.raw
            });
            if (this.skipHeader === "true") {
              sheetData.splice(0, 1); // removing the header
            }
            this.jsonData = sheetData;
          }
        };
        if (file instanceof File) {
          reader.readAsArrayBuffer(file);
        }

        this.$refs.fileInput.value = "";
      });
    });
  },
  computed: {
    ...mapGetters(["API_DATA"])
  }
})
export default class ImportFromExcel extends Vue {}
