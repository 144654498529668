<template>
  <div class="chi-card -portal">
    <div class="chi-card__header -sm">
      <div
        class="chi-card__title -d--flex -justify-content--between"
        style="width: 100%;"
      >
        <div>{{ title }}</div>
        <div>
          <button
            id="tooltip-addmore"
            class="chi-button"
            data-tooltip="Add More."
            @click="addNewRows()"
          >
            <chi-icon icon="plus" color="primary"></chi-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="chi-card__content">
      <div
        class="chi-card__caption"
        style="max-height: 180px; overflow-y: auto;"
      >
        <table class="chi-table -portal -sm">
          <thead>
            <tr>
              <th>NPA</th>
              <th></th>
              <th>NXX</th>
              <th></th>
              <th>FROM</th>
              <th></th>
              <th>TO</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(tnRange, index) in tnRanges"
              :key="index"
              :class="{ '-row--danger': !tnRange.isValidTnRange() }"
            >
              <td>
                <input
                  class="chi-input"
                  type="text"
                  size="3"
                  maxlength="3"
                  @keypress="allowNumbersOnly"
                  @paste="onPaste"
                  style="min-width: 52px"
                  placeholder="NPA"
                  v-model="tnRange.npa"
                  :class="{ '-danger': !tnRange.isValidNpa() }"
                  @input="onChange"
                />
              </td>
              <td style="text-align: center">
                <span>-</span>
              </td>
              <td>
                <input
                  class="chi-input"
                  type="text"
                  size="3"
                  maxlength="3"
                  @keypress="allowNumbersOnly"
                  @paste="onPaste"
                  style="min-width: 52px"
                  placeholder="NXX"
                  v-model="tnRange.nxx"
                  :class="{ '-danger': !tnRange.isValidNxx() }"
                  @input="onChange"
                />
              </td>
              <td style="text-align: center">
                <span>-</span>
              </td>
              <td>
                <input
                  class="chi-input"
                  type="text"
                  size="4"
                  maxlength="4"
                  @keypress="allowNumbersOnly"
                  @paste="onPaste"
                  style="min-width: 60px"
                  placeholder="XXXX"
                  v-model="tnRange.from"
                  :class="{ '-danger': !tnRange.isValidFrom() }"
                  @input="onChange"
                />
              </td>
              <td style="text-align: center">
                <span>TO</span>
              </td>
              <td>
                <div class="-d--flex -justify-content--between">
                  <div class="-flex--grow1">
                    <input
                      class="chi-input"
                      type="text"
                      size="4"
                      maxlength="4"
                      @keypress="allowNumbersOnly"
                      @paste="onPaste"
                      style="min-width: 60px"
                      placeholder="XXXX"
                      v-model="tnRange.to"
                      :class="{ '-danger': !tnRange.isValidTo() }"
                      @input="onChange"
                    />
                  </div>
                  <div class="-ml--1">
                    <button
                      :id="'tooltip-deleterow-' + index"
                      class="chi-button"
                      data-tooltip="Delete Row"
                      @click="deleteRow(index)"
                    >
                      <chi-icon icon="delete" color="warning"></chi-icon>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TnRange from "@/models/tnRange";

declare let chi: any;

@Options({
  props: ["title"],
  emits: ["on-change"],
  data() {
    return {
      tnRanges: [new TnRange("", "", "", ""), new TnRange("", "", "", "")]
    };
  },
  methods: {
    allowNumbersOnly(event: any) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    onPaste(event: any) {
      const clipboardData =
        event.clipboardData || (window as any).clipboardData;
      if (!/^[0-9]+$/.test(clipboardData.getData("text"))) {
        event.preventDefault();
      }
    },
    onChange(event: any) {
      this.$emit("on-change", this.tnRanges);
    },
    addNewRows() {
      this.tnRanges.push(new TnRange("", "", "", ""));
      this.$nextTick(() => {
        chi.tooltip(
          document.querySelectorAll("button[data-tooltip='Delete Row']")
        );
      });
      this.onChange();
      document.getElementById("tooltip-addmore")?.blur();
    },
    deleteRow(index: number) {
      chi
        .tooltip(document.getElementById("tooltip-deleterow-" + index))
        .dispose();
      this.tnRanges.splice(index, 1);
      this.$nextTick(() => {
        chi.tooltip(
          document.querySelectorAll("button[data-tooltip='Delete Row']")
        );
      });
      this.onChange();
    }
  },
  mounted() {
    chi.tooltip(document.getElementById("tooltip-addmore"));
    chi.tooltip(document.querySelectorAll("button[data-tooltip='Delete Row']"));
  }
})
export default class TnRanges extends Vue {}
</script>
<style scoped>
.chi .chi-table.-portal.-sm td,
.chi .chi-table.-portal.-sm th {
  padding: 0.25rem;
}

.chi .chi-card__content {
  padding: 0.5rem;
}
</style>
