export default class ErrorHandler {
  static getErrorMessage(error: any): string {
    let errorMessage = "Failed to parse the error response";
    if (typeof error === "string") {
      errorMessage = error;
    } else if (error.response) {
      if (error.response.data) {
        if (error.response.data.status) {
          if (error.response.data.message) {
            errorMessage =
              error.response.data.message +
              " (" +
              error.response.data.status +
              ")";
          } else if (error.response.data.error) {
            errorMessage =
              error.response.data.error +
              " (" +
              error.response.data.status +
              ")";
          }
        } else if (error.response.data.errorCode) {
          errorMessage =
            error.response.data.errorMessage +
            " (" +
            error.response.data.errorCode +
            ")";
        }
      } else if (error.response.errorCode) {
        errorMessage =
          error.response.errorMessage + " (" + error.response.errorCode + ")";
      }
    } else if (error.errorCode) {
      errorMessage = error.errorMessage + " (" + error.errorCode + ")";
    } else if (error.message) {
      errorMessage = error.message;
    }
    return errorMessage;
  }

  static getDisplayErrorMessage(error: any): string {
    return ErrorHandler.getErrorMessage(error);
  }
}
