import { render } from "./MessageAlert.vue?vue&type=template&id=75237176"
import script from "./MessageAlert.vue?vue&type=script&lang=ts"
export * from "./MessageAlert.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "75237176"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('75237176', script)) {
    api.reload('75237176', script)
  }
  
  module.hot.accept("./MessageAlert.vue?vue&type=template&id=75237176", () => {
    api.rerender('75237176', render)
  })

}

script.__file = "src/components/MessageAlert.vue"

export default script