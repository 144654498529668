import { createStore } from "vuex";
import customerAndProducts from "@/store/modules/customerAndProducts";
import notificationDefaults from "@/store/modules/notificationDefaults";
import messages from "@/store/modules/messages";

const store = createStore({
  state: {
    noHeader: true
  },
  mutations: {},
  actions: {},
  getters: {
    isHasNoHeader: state => state.noHeader
  },
  modules: {
    customerAndProducts,
    notificationDefaults,
    messages
  }
});

export default store;
