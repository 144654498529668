
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import { Notification } from "@/models/notification";
import * as _ from "lodash";
import ErrorHandler from "@/utilities/errorHandling";
declare let chi: any;

@Options({
  props: ["level", "isViewOnly"],
  data() {
    return {
      notifType: "EMAIL",
      isDangerAlert: false,
      dangerAlert: "",
      emailSmsVal: "",
      description: "",
      checked: false,
      emailSmsArrayVal: [],
      successMsg:
        "Preferences have been saved successfully. Notifications still need to be applied.",
      successMsgForSite:
        "Notifications have been saved successfully. Defaults still need to be saved and applied.",
      count: 0
    };
  },
  mounted() {
    chi.modal(document.getElementById("tooltip-1"));
    chi.tooltip(document.getElementById("tooltip-1"));
    this.$store.commit("SET_API_DATA_DEFAULT");
    this.hideMessage();
  },
  methods: {
    ...mapActions([
      "addNotifications",
      "setSuccessMessage",
      "hideMessage",
      "fetchNotifications",
      "setErrorMessage"
    ]),
    async saveAndClose() {
      this.hideMessage();
      if (await this.validateData(false)) {
        if (this.level == "site") {
          this.setSuccessMessage(this.successMsgForSite);
        } else {
          this.setSuccessMessage(this.successMsg);
        }
        this.close();
        chi.modal(document.getElementById("tooltip-1")).hide();
        console.log(
          "value of level " + this.level + " and count is " + this.count
        );
        if (this.level == "site") {
          this.$emit("set-notifications");
        } else if (this.level == "Tenant" && this.count > 0) {
          console.log("set changes called");
          this.$emit("set-changes");
        }
      }
    },
    async validateData(flag: boolean) {
      this.hideMessage();
      this.$store.commit("SET_API_DATA_DEFAULT");
      if (this.validateNotification() && this.validateEmailSMSArray()) {
        let i: number;
        const notificationArray: Notification[] = [];

        for (i = 0; i < this.emailSmsArrayVal.length; i++) {
          const notification: Notification = new Notification(
            this.notifType,
            this.emailSmsArrayVal[i],
            this.description,
            this.checked
          );
          notificationArray[i] = notification;
        }
        if (this.checked) {
          this.count++;
        }
        if (await this.addNotifications(notificationArray)) {
          const returnVal = await this.getNotifications();
          if (!returnVal) {
            return false;
          }
          if (this.level == "site" && flag) {
            this.$emit("set-notifications");
          } else if (this.level == "Tenant" && flag && this.count > 0) {
            this.$emit("set-changes");
          }
          this.emptyFields();
          return true;
        }
      }
      return false;
    },
    async addNotifications(notificationArray: Notification[]) {
      try {
        await this.$store.dispatch("addNotifications", [
          notificationArray,
          this.selectedProductType,
          this.selectedProductId,
          this.loggedInUser.id
        ]);
        if (this.level == "site") {
          this.$store.commit(
            "SET_API_DATA_ADD_SUCCESS",
            this.successMsgForSite
          );
        } else {
          this.$store.commit("SET_API_DATA_ADD_SUCCESS", this.successMsg);
        }
        return true;
      } catch (e) {
        this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        return false;
      }
    },
    async getNotifications() {
      try {
        await this.fetchNotifications([
          {
            id: this.selectedProductId,
            type: this.selectedProductType
          },
          this.loggedInUser.id
        ]);
        return true;
      } catch (e) {
        this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        return false;
      }
    },
    close() {
      this.emptyFields();
      this.$store.commit("SET_API_DATA_DEFAULT");
    },
    emptyFields() {
      this.notifType = "EMAIL";
      this.isDangerAlert = false;
      this.dangerAlert = "";
      this.emailSmsVal = "";
      this.checked = false;
      this.description = "";
      this.emailSmsArrayVal = [];
      chi.tooltip(document.getElementById("tooltip-1"));
    },
    validateNotification() {
      if (this.notifType == "" || this.notifType == null) {
        this.isDangerAlert = true;
        this.dangerAlert = "Select Notification Type .";
        return false;
      }
      this.isDangerAlert = false;
      this.dangerAlert = "";
      return true;
    },
    removeAlert() {
      this.isDangerAlert = false;
      this.$store.commit("SET_API_DATA_DEFAULT");
    },
    validateEmailSMSArray() {
      const emailSmsArray = this.emailSmsVal;

      if (_.isEmpty(emailSmsArray)) {
        this.isDangerAlert = true;
        this.dangerAlert = "Email/SMS value cannot be empty. ";
        return false;
      }

      const arr: string[] = emailSmsArray
        .split(";")
        .filter((item: any) => item.trim() != "");
      if (arr == null) {
        this.emailSmsArrayVal[0] = emailSmsArray.trim();
        return this.validateEmailSMS(emailSmsArray);
      } else {
        this.emailSmsArrayVal = arr;
        let i: number;
        for (i = 0; i < arr.length; i++) {
          if (!this.validateEmailSMS(arr[i].trim())) {
            return false;
          }
        }
        return true;
      }
    },
    validateEmailSMS(emailSmsValue: string): boolean {
      if (emailSmsValue != null && emailSmsValue != "") {
        if (this.notifType == "EMAIL") {
          const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!emailSmsValue.match(emailFormat)) {
            this.isDangerAlert = true;
            this.dangerAlert +=
              " Invalid format for Email " +
              emailSmsValue +
              ". (Multiple email values should be separated by semicolon.)";
            return false;
          }
        } else if (this.notifType == "SMS") {
          if (
            !parseInt(emailSmsValue) ||
            emailSmsValue.startsWith("0") ||
            emailSmsValue.length != 10
          ) {
            this.isDangerAlert = true;
            this.dangerAlert +=
              " Invalid format for SMS number: " +
              emailSmsValue +
              ". (SMS number should be of 10 digits,can't start with 0 or have special characters. Multiple SMS numbers should be separated by semicolon.)";
            return false;
          }
        }
      } else {
        if (this.notifType != "" && this.notifType != null) {
          this.isDangerAlert = true;
          this.dangerAlert += " Email/SMS field can't be empty.";
          return false;
        }
      }

      return true;
    },
    validateDescription() {
      if (
        (this.notifType != "" &&
          this.notifType != null &&
          this.description == "") ||
        this.description == null
      ) {
        this.isDangerAlert = true;
        this.dangerAlert += " Description field can't be empty.";
      }
    },
    hideMessages() {
      chi.tooltip(document.getElementById("tooltip-1")).dispose();
      this.$store.commit("SET_API_DATA_DEFAULT");
      this.hideMessage();
    }
  },
  computed: {
    ...mapGetters([
      "notifications",
      "API_DATA",
      "selectedProductType",
      "selectedProductId",
      "loggedInUser"
    ])
  }
})
export default class AddDefaults extends Vue {}
