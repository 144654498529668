import { toInteger } from "lodash";

export default class TnRange {
  constructor(
    public npa: string,
    public nxx: string,
    public from: string,
    public to: string
  ) {}

  isValidTnRange() {
    if (
      this.isEmpty() ||
      (/^[1-9]\d{2}$/.test(this.npa) &&
        /^\d{3}$/.test(this.nxx) &&
        /^\d{4}$/.test(this.from) &&
        /^\d{4}$/.test(this.to) &&
        toInteger(this.to) >= toInteger(this.from))
    ) {
      return true;
    }
    return false;
  }

  getRowClass() {
    if (!this.isEmpty()) {
      if (this.isValidTnRange()) {
        return "--row-success";
      } else {
        return "--row-danger";
      }
    }
    return "";
  }

  isValidNpa() {
    if (!this.npa || this.npa?.match("^[1-9]\\d{2}$")) {
      return true;
    }
    return false;
  }

  isValidNxx() {
    if (!this.nxx || this.nxx?.match("^\\d{3}$")) {
      return true;
    }
    return false;
  }

  isValidFrom() {
    if (!this.from || this.from?.match("^\\d{4}$")) {
      return true;
    }
    return false;
  }

  isValidTo() {
    if (!this.to || this.to?.match("^\\d{4}$")) {
      return true;
    }
    return false;
  }

  isEmpty() {
    if (this.npa || this.nxx || this.from || this.to) {
      return false;
    }
    return true;
  }
}
