import * as Notification from "@/models/notification";
import { toNumber } from "lodash";
import { Module } from "vuex";
import ErrorHandler from "@/utilities/errorHandling";
import config from "@/utilities/config";
import Utility from "@/utilities/utility";
import { ExportNotification } from "@/models/exportNotification";
import AxiosInstance from "@/utilities/http";
import * as _ from "lodash";
import { EmpMessage } from "@/models/empMessage";

const notificationModule: Module<any, any> = {
  state: {
    notificationsEnhanced: Array<Notification.NotificationEnhanced>(), // master list of notifications for product, site and TN

    selectedGroupNotificationsOriginal: Array<Notification.Notification>(),
    api: {
      save: {
        error: false,
        success: false,
        message: ""
      },
      import: {
        error: false,
        success: false,
        message: ""
      }
    },
    chunkOfNotifications: Array<Notification.NotificationEnhanced>(),
    selectedNotifications: Array<Notification.NotificationEnhanced>(),
    exportNotification: Array<ExportNotification>(),
    encryptedNotifications: Array<Notification.Notification>(),
    encryptedData: ""
  },
  mutations: {
    setNotifications(state, data) {
      state.notificationsEnhanced = data;
    },
    setSelectedGroupNotificationsOriginal(state, data) {
      state.selectedGroupNotificationsOriginal = data;
    },
    toggleSelect(state, index) {
      state.notificationsEnhanced[index].isSelected = !state
        .notificationsEnhanced[index].isSelected;
    },
    toggleDefault(state, index) {
      state.notificationsEnhanced[index].notification.defaulted = !state
        .notificationsEnhanced[index].notification.defaulted;
    },
    addNotifications: (state, data) =>
      state.notificationsEnhanced.unshift(data),
    SET_API_DATA_ADD_SUCCESS: (state, data) => {
      state.api.save.success = true;
      state.api.save.error = false;
      state.api.save.message = data;
    },
    SET_API_DATA_ADD_ERROR: (state, data) => {
      state.api.save.success = false;
      state.api.save.error = true;
      state.api.save.message = data;
    },
    SET_API_DATA_DEFAULT: state => {
      state.api.save.success = false;
      state.api.save.error = false;
      state.api.save.message = "";
    },
    SET_API_DATA_IMPORT_SUCCESS: (state, data) => {
      state.api.import.success = true;
      state.api.import.error = false;
      state.api.import.message = data;
    },
    SET_API_DATA_IMPORT_ERROR: (state, data) => {
      state.api.import.success = false;
      state.api.import.error = true;
      state.api.import.message = data;
    },
    SET_API_DATA_IMPORT_DEFAULT: state => {
      state.api.import.success = false;
      state.api.import.error = false;
      state.api.import.message = "";
    },
    populateNotificationDefault: (state, index) => {
      const env = config.env;
      const recordCOuntVal = `config.${env}.default.VUE_APP_RECORD_COUNT`;
      const recordCount = toNumber(recordCOuntVal);
      const upperLimit = index * recordCount;
      let lowerLimit = (index - 1) * recordCount;
      state.chunkOfNotifications = [];
      if (lowerLimit >= state.notificationsEnhanced.length) {
        lowerLimit = state.notificationsEnhanced.length;
      }
      state.chunkOfNotifications = state.notificationsEnhanced.slice(
        lowerLimit,
        upperLimit
      );
    },
    deleteNotifications: state => {
      const notificationsEnhancedArray: Notification.NotificationEnhanced[] = [];
      let i = 0;
      for (const key in state.notificationsEnhanced) {
        if (!state.notificationsEnhanced[key].isSelected) {
          notificationsEnhancedArray[i] = state.notificationsEnhanced[key];
          i++;
        }
      }
      state.notificationsEnhanced = notificationsEnhancedArray;
    },
    setNotificationsAfterAdd: (state, data) => {
      let key: any;
      let temp: Notification.NotificationEnhanced;
      let i = 0;
      for (key in state.notificationsEnhanced) {
        for (const val in data) {
          if (
            state.notificationsEnhanced[key].notification.type ==
              data[val].type &&
            state.notificationsEnhanced[key].notification.value ==
              data[val].value
          ) {
            const NotificationEnhanced = new Notification.NotificationEnhanced(
              data[val],
              false
            );
            temp = state.notificationsEnhanced[i];
            state.notificationsEnhanced[i] = NotificationEnhanced;
            state.notificationsEnhanced[key] = temp;
            i++;
            break;
          }
        }
      }
    },
    setSelectedNotifications: (state, data) => {
      state.selectedNotifications = data;
    },
    updateNotifications: (state, data) => {
      const itemIndex = state.notificationsEnhanced.findIndex(
        (item: { notification: { value: any } }) =>
          item.notification.value == data.notification.value
      );

      state.notificationsEnhanced[itemIndex] = data;
    },
    setExportNotifications: (state, data) => {
      state.exportNotification = data;
    },
    setEncryptedNotifications: (state, data) => {
      state.encryptedNotifications = data;
    },
    setEncryptedData: (state, data) => {
      state.encryptedData = data;
    }
  },
  actions: {
    async fetchNotifications({ commit }, [product, userId]) {
      const env = config.env;
      const url = `${Utility.getConfig(env, "VUE_APP_VOICE_911_URL")}${
        product.type
      }/${product.id}/${userId}?queryParam=notificationprefs`;

      await AxiosInstance.createInstance()
        .get(url)
        .then(response => {
          const notificationsEnhanced: Notification.NotificationEnhanced[] = [];
          for (const notification of response.data.notifications) {
            notificationsEnhanced.push(
              new Notification.NotificationEnhanced(notification, false)
            );
          }

          commit("setNotifications", notificationsEnhanced);
          commit("populateNotificationDefault", 1);
        })
        .catch(err => {
          const notificationsEnhanced: Notification.NotificationEnhanced[] = [];
          commit("setNotifications", notificationsEnhanced);
          throw new Error(ErrorHandler.getErrorMessage(err));
        });
    },
    async fetchGroupNotifications(
      { commit },
      [productType, productId, siteId, userId]
    ) {
      const notificationsOriginal: Notification.Notification[] = [];
      const env = config.env;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}site/${productType}/${productId}/${siteId}/${userId}?queryParam=notificationprefs`;

      await AxiosInstance.createInstance()
        .get(url)
        .then(response => {
          for (const notification of response.data.notifications) {
            notificationsOriginal.push(
              notification as Notification.Notification
            );
          }
          commit(
            "setSelectedGroupNotificationsOriginal",
            notificationsOriginal
          );
        })
        .catch(err => {
          throw new Error(ErrorHandler.getErrorMessage(err));
        });
    },
    async saveGroupNotifications(
      { commit },
      [productType, productId, payload]
    ) {
      const env = config.env;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}site/${productType}/${productId}`;
      await AxiosInstance.createInstance()
        .post(url, payload)
        .then(response => {
          if (response.data.code !== 200) {
            throw response.data;
          }
        })
        .catch(err => {
          throw new Error(
            ErrorHandler.getErrorMessage(err.response.data.errorMessage)
          );
        });
    },
    async addNotifications(
      { commit, dispatch },
      [notifications, productType, productId, userId]
    ) {
      const env = config.env;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}productInstance/${productType}/${productId}`;
      await AxiosInstance.createInstance()
        .post(url, { notifications: notifications, userId: userId })
        .then(response => {
          /*if (response.data.code == 200) {
            commit("SET_API_DATA_ADD_SUCCESS", "Data Saved Successfully");
          }*/
        })
        .catch(error => {
          commit("SET_API_DATA_ADD_ERROR", error.response.data.errorMessage);
          throw new Error(
            ErrorHandler.getErrorMessage(error.response.data.errorMessage)
          );
        });
    },

    async deleteNotifications(
      { commit },
      [productType, productId, notificationsArray, userId]
    ) {
      const env = config.env;
      //const urlVal=`config.${env}.default.VUE_APP_VOICE_911_URL`;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}productInstance/${productType}/${productId}`;
      await AxiosInstance.createInstance()
        .put(url, { notifications: notificationsArray, userId: userId })
        .then()
        .catch(error => {
          throw new Error(ErrorHandler.getErrorMessage(error));
        });
    },
    async sendEMPmsg({ commit }, empMessage) {
      const env = config.env;
      const url = `${Utility.getConfig(env, "VUE_APP_VOICE_911_URL")}emp`;
      await AxiosInstance.createInstance()
        .post(url, empMessage, {
          headers: {
            "Content-type": "application/xml"
          }
        })
        .then(response => {
          commit("SET_API_DATA_ADD_SUCCESS", "Sucess");
        })
        .catch(error => {
          const errorMessage =
            "Error received while processing request. Please try again.";
          commit("SET_API_DATA_ADD_ERROR", errorMessage);
          throw new Error(ErrorHandler.getErrorMessage(error));
        });
    },
    async sendEMPmsg2({ commit }, [epochTime, base64String, payload]) {
      const env = config.env;
      const appKeyVal = `${Utility.getConfig(env, "")}`;
      const url = `${Utility.getConfig(env, "")}`;

      await AxiosInstance.createInstance3()
        .post(url, payload, {
          headers: {
            "Content-type": "application/xml",
            "X-Level3-Application-Key": appKeyVal,
            "X-Level3-Digest": base64String,
            "X-Level3-Digest-Time": epochTime
          }
        })
        .then(response => {
          commit("SET_API_DATA_ADD_SUCCESS", "Sucess");
        })
        .catch(error => {
          const errorMessage =
            "Error received while processing request. Please try again.";
          commit("SET_API_DATA_ADD_ERROR", errorMessage);
          throw new Error(ErrorHandler.getErrorMessage(error));
        });
    },
    async toggleDefaults(
      { commit, dispatch },
      [notif, productType, productId, userId]
    ) {
      const env = config.env;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}productInstance/default/${productType}/${productId}`;
      const notification = [notif.notification];
      await AxiosInstance.createInstance()
        .put(url, { notifications: notification, userId: userId })
        .then(response => {
          commit("updateNotifications", notif);
        })
        .catch(error => {
          throw new Error(ErrorHandler.getErrorMessage(error));
        });
    },
    async getAllTnNotifications(
      { commit },
      [productType, productId, groupId, userId]
    ) {
      const env = config.env;
      let url = "";
      if (_.isEmpty(groupId)) {
        url = `${Utility.getConfig(
          env,
          "VUE_APP_VOICE_911_URL"
        )}tn/${productType}/${productId}/${userId}`;
      } else {
        url = `${Utility.getConfig(
          env,
          "VUE_APP_VOICE_911_URL"
        )}tn/${productType}/${productId}/${groupId}/${userId}`;
      }

      await AxiosInstance.createInstance()
        .get(url)
        .then(response => {
          commit("setExportNotifications", response.data);
        })
        .catch(error => {
          const tnNotif: ExportNotification[] = [];
          commit("setExportNotifications", tnNotif);
          throw new Error(ErrorHandler.getErrorMessage(error));
        });
    },
    async checkNotifications(
      { commit },
      [productType, productId, siteId, tns]
    ) {
      const env = config.env;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}notifications/${productType}/${productId}`;

      if (!_.isEmpty(siteId)) {
        if (!_.isEmpty(tns)) {
          await AxiosInstance.createInstance()
            .post(url, { siteId: siteId, tns: tns })
            .then(response => {
              if (response.data.code == 404) {
                throw new Error(response.data.message);
              }
            })
            .catch(err => {
              throw new Error(ErrorHandler.getErrorMessage(err));
            });
        } else {
          console.log("site id : " + siteId);
          await AxiosInstance.createInstance()
            .post(url, { siteId: siteId })
            .then(response => {
              if (response.data.code == 404) {
                throw new Error(response.data.message);
              }
            })
            .catch(err => {
              throw new Error(ErrorHandler.getErrorMessage(err));
            });
        }
      } else {
        await AxiosInstance.createInstance()
          .post(url, {})
          .then(response => {
            if (response.data.code == 404) {
              throw new Error(response.data.message);
            }
          })
          .catch(err => {
            throw new Error(ErrorHandler.getErrorMessage(err));
          });
      }
    },
    async checkNotificationsInProgress(
      { commit },
      [productType, productId, siteId, tns]
    ) {
      const env = config.env;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}tn/pendingprocessing/${productType}/${productId}`;

      if (!_.isEmpty(siteId)) {
        if (!_.isEmpty(tns)) {
          await AxiosInstance.createInstance()
            .post(url, { siteId: siteId, tns: tns })
            .then(response => {
              console.log("response is " + response.data);

              if (response.data.code == 500) {
                console.log("response is " + response.data);
                throw new Error(response.data.message);
              }
            })
            .catch(err => {
              throw new Error(ErrorHandler.getErrorMessage(err));
            });
        } else {
          console.log("site id : " + siteId);
          await AxiosInstance.createInstance()
            .post(url, { siteId: siteId })
            .then(response => {
              if (response.data.code == 500) {
                throw new Error(response.data.message);
              }
            })
            .catch(err => {
              throw new Error(ErrorHandler.getErrorMessage(err));
            });
        }
      } else {
        await AxiosInstance.createInstance()
          .post(url, {})
          .then(response => {
            if (response.data.code == 500) {
              throw new Error(response.data.message);
            }
          })
          .catch(err => {
            throw new Error(ErrorHandler.getErrorMessage(err));
          });
      }
    },
    async encryptNotifications(
      { commit },
      [notifications, userId, productType, productId]
    ) {
      const env = config.env;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}encrypt/${productType}/${productId}`;
      await AxiosInstance.createInstance()
        .post(url, { notifications: notifications, userId: userId })
        .then(response => {
          commit("setEncryptedNotifications", response.data.notifications);
        })
        .catch(error => {
          throw new Error(ErrorHandler.getErrorMessage(error));
        });
    },
    async getEncryptedData(
      { commit },
      [notif, userId, productType, productId]
    ) {
      const env = config.env;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}encrypt/${productType}/${productId}/${userId}?queryParam=${notif}`;
      await AxiosInstance.createInstance()
        .get(url)
        .then(response => {
          commit("setEncryptedData", response.data);
        })
        .catch(error => {
          throw new Error(ErrorHandler.getErrorMessage(error));
        });
    }
  },
  getters: {
    notifications: state => state.notificationsEnhanced,
    notificationsOriginalGroup: state =>
      state.selectedGroupNotificationsOriginal,
    API_DATA: state => {
      return state.api;
    },
    totalRecords: state => state.notificationsEnhanced.length,
    chunkedNotifications: state => state.chunkOfNotifications,
    notificationSelected: state => state.selectedNotifications,
    exportNotif: state => state.exportNotification,
    encryptedNotifs: state => state.encryptedNotifications,
    encryptedData: state => state.encryptedData
  }
};
export default notificationModule;
