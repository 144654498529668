<template>
  <div>
    <div class="chi-card -portal">
      <div class="chi-card__header -sm">
        <div
          class="chi-card__title -d--flex -justify-content--between"
          style="width: 100%;"
        >
          <div>
            <label class="chi-label" for="example__xl"
              >Enter comma separated Telephone Number(s):</label
            >
          </div>
        </div>
      </div>

      <div class="chi-card__content">
        <div class="chi-card__caption">
          <div>
            <textarea
              class="chi-input"
              id="example__xl"
              @input="eventEmit($event.target.value)"
              :class="{ fieldColor: returnErrorField() }"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="chi-card -portal">
      <div class="chi-card__header -sm">
        <div
          class="chi-card__title -d--flex -justify-content--between"
          style="width: 100%;"
        >
          <div>
            <p class="-text--bold">Tn Ranges:</p>
          </div>
        </div>
      </div>
      <div class="chi-card__content">
        <div class="chi-card__caption">
          <div>
            <section class="chi-table -fixed--header" style="height: 250px;">
              <div>
                <table style="width:100%;">
                  <thead>
                    <tr>
                      <th style="width:23%;"><div>NPA</div></th>
                      <th style="width:23%;"><div>NXX</div></th>
                      <th style="width:30%;"><div>Last 4 digits</div></th>
                      <th style="width:24%;"><div>Last 4 digits</div></th>
                    </tr>
                  </thead>
                  <tbody>
                    <!--row 1-->
                    <tr
                      v-for="index1 in maxRows"
                      :key="index1"
                      :class="'row-' + index1"
                    >
                      <td style="width:23%;">
                        <div class="chi-grid">
                          <div class="chi-col">
                            <div class="chi-form__item">
                              <input
                                type="text"
                                class="chi-input"
                                :id="'npa' + index1"
                                maxlength="3"
                                v-model="npa[index1 - 1]"
                                @keyup="
                                  validateData(npa[index1 - 1], index1 - 1, 0)
                                "
                                v-bind:class="{
                                  fieldColor: fieldDataColorCheck(index1 - 1, 0)
                                }"
                              />
                            </div>
                          </div>
                          <div class="chi-col -w--1">
                            <p class="-text--bold">-</p>
                          </div>
                        </div>
                      </td>
                      <td style="width:23%;">
                        <div class="chi-grid">
                          <div class="chi-col">
                            <div class="chi-form__item">
                              <input
                                type="text"
                                class="chi-input"
                                :id="'nxx' + index1"
                                maxlength="3"
                                v-model="nxx[index1 - 1]"
                                @keyup="
                                  validateData(nxx[index1 - 1], index1 - 1, 1)
                                "
                                v-bind:class="{
                                  fieldColor: fieldDataColorCheck(index1 - 1, 1)
                                }"
                              />
                            </div>
                          </div>
                          <div class="chi-col -w--1">
                            <p class="-text--bold">-</p>
                          </div>
                        </div>
                      </td>
                      <td style="width:30%;">
                        <div class="chi-grid">
                          <div class="chi-col ">
                            <div class="chi-form__item">
                              <input
                                type="text"
                                class="chi-input"
                                :id="'from' + index1"
                                maxlength="4"
                                v-model="from[index1 - 1]"
                                @keyup="
                                  validateData(from[index1 - 1], index1 - 1, 2)
                                "
                                v-bind:class="{
                                  fieldColor: fieldDataColorCheck(index1 - 1, 2)
                                }"
                              />
                            </div>
                          </div>
                          <div class="chi-col -w--4">
                            <p class="-text--bold">TO</p>
                          </div>
                        </div>
                      </td>
                      <td style="width:24%;">
                        <div class="chi-grid">
                          <div class="chi-col">
                            <div class="chi-form__item">
                              <input
                                type="text"
                                class="chi-input"
                                :id="'to' + index1"
                                maxlength="4"
                                v-model="to[index1 - 1]"
                                @keyup="
                                  validateData(to[index1 - 1], index1 - 1, 3)
                                "
                                v-bind:class="{
                                  fieldColor: fieldDataColorCheck(index1 - 1, 3)
                                }"
                              />
                            </div>
                          </div>
                          <div class="chi-col -w--1">
                            <p class="-text" style="color: #ffffff;">-</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
          <div>
            <button
              id="tooltip-4"
              class="chi-button"
              data-tooltip="Add More.."
              @click="addNewRows()"
            >
              <chi-icon icon="plus" color="primary"></chi-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapActions } from "vuex";
declare let chi: any;

@Options({
  props: {
    npa: [],
    nxx: [],
    to: [],
    from: []
  },
  data() {
    return {
      maxRows: 2,
      telephoneNumbers: "",
      arr: [[]],
      errorField: false
    };
  },
  methods: {
    addNewRows() {
      this.maxRows += 1;
      this.arr[this.maxRows - 1] = [];
      for (let i = 0; i < 4; i++) {
        this.arr[this.maxRows - 1][i] = 0;
      }
    },
    eventEmit(value: string) {
      if (!this.splitNumbersAndValidate(value)) {
        this.errorField = true;
      } else {
        this.errorField = false;
      }
      this.$emit("telephone-numbers", value);
      this.telephoneNumbers = value;
    },
    validateData(val: string, row: number, col: number) {
      if (
        val != undefined &&
        (((col == 0 || col == 1) && val.length != 3) ||
          (!Number(val) && val != "0000" && val != "000") ||
          ((col == 2 || col == 3) && val.length != 4))
      ) {
        this.arr[row][col] = 1;
      } else {
        this.arr[row][col] = 0;
      }
    },
    setValues() {
      for (let i = 0; i < 2; i++) {
        this.arr[i] = [];
        for (let j = 0; j < 4; j++) {
          this.arr[i][j] = 0;
        }
      }
    },
    fieldDataColorCheck(row: number, column: number) {
      if (this.arr[row][column] != undefined) {
        if (this.arr[row][column] == 1) {
          return true;
        }
      }
      return false;
    },
    splitNumbersAndValidate(telephoneNumbers: string) {
      if (telephoneNumbers != "" && telephoneNumbers != null) {
        const arr: string[] = telephoneNumbers.split(",");
        if (arr == null) {
          return this.validateTelephoneNumber(telephoneNumbers);
        } else {
          for (const index in arr) {
            if (!this.validateTelephoneNumber(arr[index])) {
              return false;
            }
          }
        }
      }
      return true;
    },
    validateTelephoneNumber(telephoneNumber: string) {
      if (
        !parseInt(telephoneNumber) ||
        telephoneNumber.startsWith("0") ||
        telephoneNumber.length != 10
      ) {
        return false;
      }
      return true;
    },
    returnErrorField() {
      return this.errorField;
    }
  },
  mounted() {
    chi.tooltip(document.getElementById("tooltip-4"));
  },
  created() {
    this.setValues();
  }
})
export default class AddTelephoneNumbers extends Vue {}
</script>
<style scoped>
.fieldColor {
  border-color: red;
}
</style>
