import { render } from "./AddTelephoneNumbers.vue?vue&type=template&id=92246a32&scoped=true"
import script from "./AddTelephoneNumbers.vue?vue&type=script&lang=ts"
export * from "./AddTelephoneNumbers.vue?vue&type=script&lang=ts"

import "./AddTelephoneNumbers.vue?vue&type=style&index=0&id=92246a32&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-92246a32"
/* hot reload */
if (module.hot) {
  script.__hmrId = "92246a32"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('92246a32', script)) {
    api.reload('92246a32', script)
  }
  
  module.hot.accept("./AddTelephoneNumbers.vue?vue&type=template&id=92246a32&scoped=true", () => {
    api.rerender('92246a32', render)
  })

}

script.__file = "src/components/AddTelephoneNumbers.vue"

export default script