import { render } from "./DeleteDefaults.vue?vue&type=template&id=0b428e66"
import script from "./DeleteDefaults.vue?vue&type=script&lang=ts"
export * from "./DeleteDefaults.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0b428e66"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0b428e66', script)) {
    api.reload('0b428e66', script)
  }
  
  module.hot.accept("./DeleteDefaults.vue?vue&type=template&id=0b428e66", () => {
    api.rerender('0b428e66', render)
  })

}

script.__file = "src/components/DeleteDefaults.vue"

export default script