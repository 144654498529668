<template>
  <div class="chi-dropdown">
    <button
      id="vp-custom-dropdown-animate"
      class="chi-button chi-dropdown__trigger -animate"
      @click="callSite()"
    >
      {{ displayValue }}
    </button>
    <div class="chi-dropdown__menu" style="max-height: 200px;">
      <div
        v-if="showSearch === 'true'"
        class="chi-input__wrapper -icon--left chi-dropdown__menu-item"
        role="search"
      >
        <input
          class="chi-input"
          type="text"
          placeholder="Search"
          aria-label="Search"
          @keyup="filterItems"
        />
        <i class="chi-icon icon-search"></i>
      </div>
      <a
        class="chi-dropdown__menu-item"
        href="#"
        @click="selectItem(item)"
        v-for="item in filteredItems"
        :key="item.value"
        >{{ item.label
        }}{{ showItemValue === "true" ? " (" + item.value + ")" : "" }}</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import * as _ from "lodash";

declare let chi: any;

@Options({
  props: ["items", "showItemValue", "showSearch"],
  data() {
    return {
      selectedItemLabel: "- Select -",
      seletedItemValue: "",
      filteredItems: []
    };
  },
  methods: {
    selectItem(item: any) {
      console.log("item value: " + item.value);
      this.selectedItemValue = item.value;
      this.selectedItemLabel = item.label;
      this.$emit("on-select", item);
    },
    filterItems(event: any) {
      console.log("filter items");
      const showItemValueBoolean = this.showItemValue === "true";
      this.filteredItems = this.items.filter(function(item: any) {
        return showItemValueBoolean
          ? (item.label.toLowerCase() + " (" + item.value + ")").indexOf(
              event.target.value.toLowerCase()
            ) > -1
          : item.label.indexOf(event.target.value) > -1;
      });
    },
    setItemValue(itemVal: any, itemLabel: any) {
      this.selectedItemValue = itemVal;
      this.selectedItemLabel = itemLabel;
      this.displayValue;
    },
    callSite() {
      this.$emit("set-dispose");
    }
  },
  computed: {
    displayValue() {
      let displayValue = this.selectedItemLabel;
      if (this.showItemValue === "true") {
        if (this.selectedItemValue && this.selectedItemValue.length > 0) {
          displayValue = displayValue + " (" + this.selectedItemValue + ")";
        }
      }
      return displayValue;
    }
  },
  mounted() {
    chi.dropdown(document.getElementById("vp-custom-dropdown-animate"));
    this.filteredItems = _.cloneDeep(this.items);
  },
  watch: {
    items(newVal, oldVal) {
      this.filteredItems = _.cloneDeep(newVal);
    }
  }
})
export default class DropDown extends Vue {}
</script>

<style scoped></style>
