export class Command {
  public get getCommandId(): string {
    return this.commandId;
  }
  public set setCommandId(value: string) {
    this.commandId = value;
  }
  constructor(
    private messageType: string,
    private commandType: string,
    private eventTimestamp: string,
    private commandId: string,
    private messageSource: string,
    private businessKeys: BusinessKeys,
    private payload: Payload
  ) {}

  public get getPayload(): Payload {
    return this.payload;
  }
  public set setPayload(value: Payload) {
    this.payload = value;
  }
  public get getBusinessKeys(): BusinessKeys {
    return this.businessKeys;
  }
  public set setBusinessKeys(value: BusinessKeys) {
    this.businessKeys = value;
  }

  public get getEventTimestamp(): string {
    return this.eventTimestamp;
  }
  public set setEventTimestamp(value: string) {
    this.eventTimestamp = value;
  }

  public get getMessageSource(): string {
    return this.messageSource;
  }
  public set setMessageSource(value: string) {
    this.messageSource = value;
  }

  public get getMessageType(): string {
    return this.messageType;
  }
  public set setMessageType(value: string) {
    this.messageType = value;
  }
  public get getCommandType(): string {
    return this.commandType;
  }
  public set setCommandType(value: string) {
    this.commandType = value;
  }
}

export class SecurityHeader {
  constructor(
    private applicationKey: string,
    private digest: string,
    private digestTime: string
  ) {}

  public get getDigestTime(): string {
    return this.digestTime;
  }
  public set setdigestTime(value: string) {
    this.digestTime = value;
  }
  public get getdigest(): string {
    return this.digest;
  }
  public set setdigest(value: string) {
    this.digest = value;
  }
  public get getApplicationKey(): string {
    return this.applicationKey;
  }
  public set setApplicationKey(value: string) {
    this.applicationKey = value;
  }
}

export class BusinessKeys {
  public get getBusinessKey(): BusinessKey[] {
    return this.businessKey;
  }
  public set setBusinessKey(value: BusinessKey[]) {
    this.businessKey = value;
  }
  constructor(private businessKey: BusinessKey[]) {}
}

export class BusinessKey {
  constructor(
    private entity: string,
    private name: string,
    private value: string
  ) {}

  public get getValue(): string {
    return this.value;
  }
  public set setValue(value: string) {
    this.value = value;
  }
  public get getName(): string {
    return this.name;
  }
  public set setName(value: string) {
    this.name = value;
  }
  public get getEntity(): string {
    return this.entity;
  }
  public set setEntity(value: string) {
    this.entity = value;
  }
}

export class Payload {
  public addData: string | null | undefined;
  public deleteData: string | null | undefined;
}
