<template>
  <div>
    <button
      id="tooltip-8"
      class="chi-button chi-modal__trigger -primary"
      data-target="#modal-5"
      @click="applyNotification()"
      :disabled="isViewOnly"
    >
      {{ displayValue }}
    </button>
    <div class="chi-backdrop -closed">
      <div class="chi-backdrop__wrapper">
        <section
          id="modal-5"
          class="chi-modal -portal"
          role="dialog"
          aria-label="Modal description"
          aria-modal="true"
        >
          <header class="chi-modal__header">
            <h2 class="chi-modal__title">{{ popUpStatement }}</h2>
            <button
              class="chi-button -icon -close"
              data-dismiss="modal"
              aria-label="Close"
              @click="close()"
            >
              <div class="chi-button__content">
                <i class="chi-icon icon-x"></i>
              </div>
            </button>
          </header>
          <div class="chi-modal__content">
            <div
              v-if="isDangerAlert"
              class="chi-alert -danger -lg -b--danger-light -bg--danger-lighter"
              role="alert"
            >
              <i class="chi-alert__icon chi-icon icon-circle-info"></i>
              <div class="chi-alert__content">
                <p class="chi-alert__title -text--lg">Error</p>
                <p class="chi-alert__text">
                  {{ dangerAlert }}
                </p>
              </div>
            </div>
            <div class="chi-grid" style="padding-bottom: 10px;">
              <div class="chi-col">
                <div class="chi-form__item">
                  <div
                    v-if="
                      level == 'sites' && (isSaveNeeded || isSaveNeededForSite)
                    "
                    class="chi-alert -info -lg -b--info-light -bg--info-lighter"
                    role="alert"
                  >
                    <i class="chi-alert__icon chi-icon icon-circle-x"></i>
                    <div class="chi-alert__content">
                      <p class="chi-alert__title -text--lg">Info</p>
                      <p class="chi-alert__text">
                        Changes detected in the Global Notification Data table.
                        Please save the defaults before applying!
                      </p>
                    </div>
                  </div>

                  <chi-label for="reply_email" required
                    >Reply Email:
                  </chi-label>
                  <input
                    type="text"
                    class="chi-input"
                    placeholder="Enter Email. "
                    id="reply_email"
                    v-model="emailVal"
                  />
                  <p class="-text--info -text--sm">
                    These notifications will replace existing notifications.
                    <br />
                    <br />
                    Once applied, this request may take up to 24 hours to
                    complete. Please wait for your confirmation email before
                    applying additional notifications.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <footer class="chi-modal__footer">
            <button class="chi-button" data-dismiss="modal" @click="close()">
              Cancel
            </button>
            <button class="chi-button -primary" @click="apply()">
              Save
            </button>
          </footer>
        </section>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Notification, NotificationEnhanced } from "@/models/notification";
import Utility from "@/utilities/utility";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
declare let chi: any;
import ErrorHandler from "@/utilities/errorHandling";
import * as _ from "lodash";
import config from "@/utilities/config";
import { parseInt } from "lodash";
import EmpValidations from "@/utilities/empValidations";

@Options({
  props: {
    level: "",
    selectedGroupId: "",
    npa: [],
    nxx: [],
    to: [],
    from: [],
    telephoneNumbers: "",
    showGroupTns: false,
    selectedTns: [],
    displayValue: "",
    selectedNotifs: [],
    isViewOnly: false,
    popUpStatement: "",
    selectedGroupName: "",
    isDelete: false,
    isSaveNeeded: false,
    selectedNotificationsOnSite: []
  },
  data() {
    return {
      emailVal: "",
      groupId: "",
      errorMsg: "Please set default notifications",
      tnRange: [],
      selectedNotifications: Array<Notification>(),
      isDangerAlert: false,
      dangerAlert: "",
      isSaveNeededForSite: false
    };
  } /*,
  mounted() {
    //chi.modal(document.getElementById("tooltip-6"));
  }*/,
  methods: {
    ...mapActions([
      "setSuccessMessage",
      "sendEMPmsg",
      "hideMessage",
      "setErrorMessage",
      "checkNotificationsInProgress",
      "encryptNotifications",
      "getEncryptedData"
    ]),
    async apply() {
      const isFederalAccount = Utility.isFederalAccount(
        this.selectedProductType
      );
      const returnVal = EmpValidations.checkEmail(this.emailVal);
      if (!_.isEqual(returnVal, EmpValidations.SUCCESS)) {
        this.isDangerAlert = true;
        this.dangerAlert = returnVal;
        return false;
      }
      let emailId = this.emailVal;
      if (isFederalAccount) {
        await this.getEncryptedData([
          emailId,
          this.loggedInUser.id,
          this.selectedProductType,
          this.selectedProductId
        ]);
        emailId = this.encryptedData;
      }
      this.isDangerAlert = false;
      const util = new Utility();
      let applyNotificationToAll = "false";
      let applyNotificationToAllForSites = "null";
      if (this.level == "Tenant") {
        try {
          await this.checkNotificationsInProgress([
            this.selectedProductType,
            this.selectedProductId,
            "",
            ""
          ]);
        } catch (e) {
          this.setErrorMessage(e.message);
          chi.modal(document.getElementById("tooltip-8")).hide();
          return;
        }
        const notifications = this.notifications.filter(
          (notif: NotificationEnhanced) => notif.notification.defaulted == true
        );

        if (EmpValidations.checkForNullAndEmpty(notifications)) {
          this.setErrorMessage(this.errorMsg + " for this product.");
          chi.modal(document.getElementById("tooltip-8")).hide();
          return;
        }

        const notifs: Notification[] = [];
        for (const key in notifications) {
          notifs.push(notifications[key].notification);
        }
        const msg = Utility.checkSizeOfEmailsAndSMS(notifs, this.level);
        if (!_.isEqual(msg, "No error")) {
          this.setErrorMessage(msg);
          chi.modal(document.getElementById("tooltip-8")).hide();
          return;
        }
        applyNotificationToAll = "true";
      } else if (this.level == "sites") {
        try {
          await this.checkNotificationsInProgress([
            this.selectedProductType,
            this.selectedProductId,
            this.selectedGroupId,
            ""
          ]);
        } catch (e) {
          this.setErrorMessage(e.message);
          chi.modal(document.getElementById("tooltip-8")).hide();
          return;
        }
        if (EmpValidations.checkForNullAndEmpty(this.selectedNotifs)) {
          this.setErrorMessage(this.errorMsg + " for this site.");
          chi.modal(document.getElementById("tooltip-8")).hide();
          return;
        }
        const msg = Utility.checkSizeOfEmailsAndSMS(
          this.selectedNotifs,
          this.level
        );
        if (!_.isEqual(msg, "No error")) {
          this.setErrorMessage(msg);
          chi.modal(document.getElementById("tooltip-8")).hide();
          return;
        }
        this.groupId = this.selectedGroupId;
        this.groupName = this.selectedGroupName;
        applyNotificationToAllForSites = "true";
      } else if (this.level == "telephones") {
        this.groupId = this.selectedGroupId;
        applyNotificationToAllForSites = "false";
        this.groupName = this.selectedGroupName;
        if (isFederalAccount) {
          await this.encryptNotifications([
            this.selectedNotifications,
            this.loggedInUser.id,
            this.selectedProductType,
            this.selectedProductId
          ]);
          this.selectedNotifications = this.encryptedNotifs;
        }
        //this.applyNotification();
      }

      const empMsg = util.preparePayloadDataForEMPMsgForApply(
        this.customer,
        this.selectedProductId,
        this.selectedProductType,
        applyNotificationToAll,
        emailId.trim(),
        this.groupId,
        applyNotificationToAllForSites,
        this.tnRange,
        this.telephoneNumbers,
        this.selectedTns,
        this.selectedNotifications,
        this.showGroupTns,
        this.loggedInUser.id,
        this.selectedGroupName
      );
      this.sendEmpMsg(empMsg);
      chi.modal(document.getElementById("tooltip-8")).hide();
      if (
        this.level == "Tenant" ||
        this.level == "telephones" ||
        this.level == "sites"
      ) {
        this.$emit("set-change");
      }
    },
    hideMessages() {
      this.$store.commit("SET_API_DATA_DEFAULT");
      this.hideMessage();
      this.emailVal = "";
      //this.close();
    },
    close() {
      this.emailVal = "";
      this.tnRange = [];
      this.selectedNotifications = [];
      this.groupId = "";
      this.isDangerAlert = false;
      this.dangerAlert = "";
      this.isSaveNeededForSite = false;
    },
    async sendEmpMsg(empMsg: any) {
      try {
        await this.$store.dispatch("sendEMPmsg", empMsg);
        this.setSuccessMessage(
          "Notifications sent successfully. Your request will be completed within 24 hours and you will receive one email on completion of the request."
        );
      } catch (e) {
        this.setErrorMessage(this.API_DATA.save.message);
        chi.modal(document.getElementById("tooltip-8")).hide();
      }
    },
    async applyNotification(): Promise<boolean> {
      chi.modal(document.getElementById("tooltip-8")).dispose();
      this.hideMessages();
      this.tnRange = [];
      this.selectedNotifications = [];
      if (this.level == "telephones") {
        if (this.showGroupTns) {
          if (EmpValidations.checkForNullAndEmpty(this.selectedTns)) {
            this.setErrorMessage(
              "Please select one or more telephone numbers."
            );
            return false;
          } else {
            try {
              await this.checkNotificationsInProgress([
                this.selectedProductType,
                this.selectedProductId,
                this.selectedGroupId,
                this.selectedTns
              ]);
            } catch (e) {
              this.setErrorMessage(e.message);
              return false;
            }
          }

          if (!this.checkAndSetNotifications() && !this.isDelete) {
            return false;
          }
        } else {
          const val = EmpValidations.emptyValues(
            this.nxx,
            this.npa,
            this.to,
            this.from,
            this.telephoneNumbers
          );
          if (!_.isEqual(val, EmpValidations.SUCCESS)) {
            this.setErrorMessage(val);
            return false;
          } else {
            const val = EmpValidations.splitNumbersAndValidate(
              this.telephoneNumbers
            );
            if (!_.isEqual(val, EmpValidations.SUCCESS)) {
              this.setErrorMessage(
                "Invalid format for telephone number: " +
                  val +
                  ". Telephone number should be of 10 digits, can't start with 0 or have special characters."
              );
              return false;
            } else if (!_.isEmpty(this.telephoneNumbers)) {
              const arr: string[] = this.telephoneNumbers
                .split(";")
                .filter((item: any) => item);
              try {
                await this.checkNotificationsInProgress([
                  this.selectedProductType,
                  this.selectedProductId,
                  this.selectedGroupId,
                  arr
                ]);
              } catch (e) {
                this.setErrorMessage(e.message);
                return false;
              }
            }
            const valForTns = EmpValidations.validateTns(
              this.npa,
              this.nxx,
              this.to,
              this.from
            );
            if (typeof valForTns != "string") {
              this.tnRange = valForTns;
              try {
                await this.checkNotificationsInProgress([
                  this.selectedProductType,
                  this.selectedProductId,
                  this.selectedGroupId,
                  this.tnRange
                ]);
              } catch (e) {
                this.setErrorMessage(e.message);
                return false;
              }
            } else {
              this.setErrorMessage(valForTns);
              return false;
            }
          }
          if (!this.checkAndSetNotifications() && !this.isDelete) {
            return false;
          }
        }

        chi.modal(document.getElementById("tooltip-8")).show();
      } else {
        if (
          this.level == "sites" &&
          this.selectedNotificationsOnSite.length != this.selectedNotifs.length
        ) {
          this.isSaveNeededForSite = true;
        }
        chi.modal(document.getElementById("tooltip-8")).show();
      }
      return true;
    },
    checkAndSetNotifications(): boolean {
      if (EmpValidations.checkForNullAndEmpty(this.selectedNotifs)) {
        this.setErrorMessage(
          "Please select notification(s) from the Notification Table."
        );
        return false;
      }
      for (const key in this.selectedNotifs) {
        this.selectedNotifications[key] = this.selectedNotifs[key].notification;
      }
      const msg = Utility.checkSizeOfEmailsAndSMS(
        this.selectedNotifications,
        this.level
      );
      if (!_.isEqual(msg, "No error")) {
        this.setErrorMessage(msg);
        return false;
      }
      return true;
    }
  },
  computed: {
    ...mapGetters([
      "isProductIdSelected",
      "customer",
      "notifications",
      "selectedProductType",
      "selectedProductId",
      "notificationsOriginalGroup",
      "API_DATA",
      "notificationSelected",
      "loggedInUser",
      "encryptedNotifs",
      "encryptedData"
    ])
  }
})
export default class ReplyEmail extends Vue {}
</script>
