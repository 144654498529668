export default {
  VUE_APP_CHI: "https://assets.ctl.io/chi/3.1.0/",
  VUE_APP_VOICE_911_URL: "https://voip-itv1.lumen.com/911managerAPI/",
  VUE_APP_RECORD_COUNT: 25,
  VUE_APP_COMMAND_TYPE: "Apply_Notification_Preference",
  VUE_APP_MSG_TYPE:
    "E.ServiceDelivery.Voice.Voice911Manager.NotificationPreference.Command",
  VUE_APP_MSG_SOURCE: "BVOIP_PORTAL",
  VUE_APP_TOKEN_SERVICE: "https://voip-itv1.lumen.com/911managerTokenUI",
  VUE_APP_DELETE_COMMAND_TYPE: "Delete_Notification_Preference",
  VUE_APP_ADD_DELETE_COMMAND_TYPE: "Apply_Delete_Notification_Preference",
  VUE_PRODUCT_TYPE: "ucaas"
};
