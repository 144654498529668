<template>
  <div>
    <button
      id="tooltip-2"
      class="chi-button chi-modal__trigger"
      data-target="#modal-1"
      data-tooltip="Delete Selected Notifications"
      @click="checkNotificationSelected"
      :disabled="isViewOnly"
    >
      <chi-icon
        icon="delete"
        :color="[isViewOnly ? 'grey' : 'warning']"
      ></chi-icon>
    </button>
    <!-- Modal 1-->
    <div class="chi-backdrop -closed">
      <div class="chi-backdrop__wrapper">
        <section
          id="modal-1"
          class="chi-modal"
          role="dialog"
          aria-label="Modal description"
          aria-modal="true"
        >
          <header class="chi-modal__header">
            <h2 class="chi-modal__title">Confirm to delete</h2>
            <button
              class="chi-button -icon -close"
              data-dismiss="modal"
              aria-label="Close"
              @click="close()"
            >
              <div class="chi-button__content">
                <i class="chi-icon icon-x"></i>
              </div>
            </button>
          </header>
          <div class="chi-modal__content">
            <p class="-text -m--0">
              Are you sure want to delete the selected notifications?
            </p>
            <div
              class="chi-alert -info -lg -b--info-light -bg--info-lighter"
              role="alert"
            >
              <i class="chi-alert__icon chi-icon icon-circle-info"></i>
              <div class="chi-alert__content">
                <p class="chi-alert__title -text--lg">Info</p>
                <p class="chi-alert__text">
                  There will be no changes to the applied notifications and the
                  notifications will be removed from the Global Notification
                  Data table only!
                </p>
              </div>
            </div>
          </div>
          <footer class="chi-modal__footer">
            <button class="chi-button" data-dismiss="modal" @click="close()">
              Cancel
            </button>
            <button
              class="chi-button -primary"
              data-dismiss="modal"
              @click="checkNotificationSelectedAndDelete()"
            >
              Delete
            </button>
          </footer>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Notification } from "@/models/notification";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import * as _ from "lodash";
import ErrorHandler from "@/utilities/errorHandling";

declare let chi: any;

@Options({
  props: {
    notificationsArray: [],
    isViewOnly: false
  },
  mounted() {
    this.hideMessage();
    chi.tooltip(document.getElementById("tooltip-2"));
  },
  methods: {
    ...mapActions([
      "deleteNotifications",
      "setSuccessMessage",
      "hideMessage",
      "setErrorMessage",
      "fetchNotifications"
    ]),
    checkNotificationSelected() {
      this.hideMessage();
      chi.modal(document.getElementById("tooltip-2")).dispose();
      if (
        this.notificationsArray != null &&
        !_.isEmpty(this.notificationsArray)
      ) {
        chi.modal(document.getElementById("tooltip-2")).show();
      } else {
        this.setErrorMessage("Please select notifications to delete.");

        chi.modal(document.getElementById("tooltip-2")).hide();
      }
    },
    async checkNotificationSelectedAndDelete() {
      const notificationsEnhancedArray: Notification[] = [];

      for (let i = 0; i < this.notificationsArray.length; i++) {
        notificationsEnhancedArray[i] = this.notificationsArray[i].notification;
      }
      try {
        await this.deleteNotifications([
          this.selectedProductType,
          this.selectedProductId,
          notificationsEnhancedArray,
          this.loggedInUser.id
        ]);
        this.setSuccessMessage(
          notificationsEnhancedArray.length +
            " record(s) deleted successfully! This will not delete applied notifications."
        );
        this.$emit("clicked", "removeNotifications");
      } catch (e) {
        this.setErrorMessage("Delete not successful!");
      }

      try {
        await this.fetchNotifications([
          {
            id: this.selectedProductId,
            type: this.selectedProductType
          },
          this.loggedInUser.id
        ]);
      } catch (e) {
        if (e.message.indexOf("404", 0) > -1) {
          this.setInfoMessage(e.message.substring(0, e.message.indexOf("(")));
        } else {
          this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        }
      }
    },
    close() {
      this.isNotificationSelected = true;
    }
  },
  computed: {
    ...mapGetters([
      "notifications",
      "selectedProductType",
      "selectedProductId",
      "API_DATA",
      "loggedInUser"
    ])
  }
})
export default class DeleteDefaults extends Vue {}
</script>
