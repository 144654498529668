
import { Notification, NotificationEnhanced } from "@/models/notification";
import Utility from "@/utilities/utility";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
declare let chi: any;
import ErrorHandler from "@/utilities/errorHandling";
import * as _ from "lodash";
import config from "@/utilities/config";
import { parseInt } from "lodash";
import EmpValidations from "@/utilities/empValidations";

@Options({
  props: {
    level: "",
    selectedGroupId: "",
    npa: [],
    nxx: [],
    to: [],
    from: [],
    telephoneNumbers: "",
    showGroupTns: false,
    selectedTns: [],
    displayValue: "",
    selectedNotifs: [],
    isViewOnly: false,
    popUpStatement: "",
    selectedGroupName: ""
  },
  data() {
    return {
      emailVal: "",
      groupId: "",
      errorMsg: "Please set default notifications",
      //tns: "",
      tnRange: [],
      selectedNotifications: Array<Notification>(),
      isDangerAlert: false,
      dangerAlert: ""
    };
  } /*,
  mounted() {
    //chi.modal(document.getElementById("tooltip-6"));
  }*/,
  methods: {
    ...mapActions([
      "setSuccessMessage",
      "sendEMPmsg",
      "hideMessage",
      "setErrorMessage",
      "checkNotifications",
      "setInfoMessage",
      "checkNotificationsInProgress",
      "getEncryptedData"
    ]),
    async apply() {
      const isFederalAccount = Utility.isFederalAccount(
        this.selectedProductType
      );
      const returnVal = EmpValidations.checkEmail(this.emailVal);
      if (!_.isEqual(returnVal, EmpValidations.SUCCESS)) {
        this.isDangerAlert = true;
        this.dangerAlert = returnVal;
        return false;
      }
      let emailId = this.emailVal;
      if (isFederalAccount) {
        await this.getEncryptedData([
          emailId,
          this.loggedInUser.id,
          this.selectedProductType,
          this.selectedProductId
        ]);
        emailId = this.encryptedData;
      }
      this.isDangerAlert = false;
      const util = new Utility();
      this.setDataForDelete(emailId);
      chi.modal(document.getElementById("tooltip-10")).hide();
    },
    hideMessages() {
      this.$store.commit("SET_API_DATA_DEFAULT");
      this.hideMessage();
      this.emailVal = "";
      //this.close();
    },
    close() {
      this.emailVal = "";
      this.tnRange = [];
      this.selectedNotifications = [];
      this.groupId = "";
      this.isDangerAlert = false;
      this.dangerAlert = "";
      //chi.modal(document.getElementById("tooltip-10")).hide();
      //chi.modal(document.getElementById("tooltip-10")).dispose();
    },
    async sendEmpMsg(xml: any) {
      try {
        await this.$store.dispatch("sendEMPmsg", xml);
        this.setSuccessMessage(
          "Delete request sent successfully. Your request will be completed within 24 hours and you will receive one email on completion of the request."
        );
      } catch (e) {
        this.setErrorMessage(this.API_DATA.save.message);
        chi.modal(document.getElementById("tooltip-10")).hide();
      }
    },
    async applyNotification() {
      chi.modal(document.getElementById("tooltip-10")).dispose();
      this.hideMessages();
      this.tnRange = [];
      this.selectedNotifications = [];
      if (this.level == "telephones") {
        if (this.showGroupTns) {
          if (EmpValidations.checkForNullAndEmpty(this.selectedTns)) {
            this.setErrorMessage(
              "Please select one or more telephone numbers."
            );
            return false;
          }
          this.tnRange = this.selectedTns;
        } else {
          const val = EmpValidations.emptyValues(
            this.nxx,
            this.npa,
            this.to,
            this.from,
            this.telephoneNumbers
          );
          if (!_.isEqual(val, EmpValidations.SUCCESS)) {
            this.setErrorMessage(val);
            return false;
          } else {
            const val = EmpValidations.splitNumbersAndValidate(
              this.telephoneNumbers
            );
            if (!_.isEqual(val, EmpValidations.SUCCESS)) {
              this.setErrorMessage(
                "Invalid format for telephone number: " +
                  val +
                  ". Telephone number should be of 10 digits,can't start with 0 or have special characters."
              );
              return false;
            }
            const valForTns = EmpValidations.validateTns(
              this.npa,
              this.nxx,
              this.to,
              this.from
            );
            if (typeof valForTns != "string") {
              this.tnRange = valForTns;
            } else {
              this.setErrorMessage(valForTns);
              return false;
            }
            if (!_.isEmpty(this.telephoneNumbers)) {
              EmpValidations.getArrayOfTns(this.tnRange, this.telephoneNumbers);
            }
          }
        }
      }
      try {
        await this.checkNotificationsInProgress([
          this.selectedProductType,
          this.selectedProductId,
          this.selectedGroupId,
          this.tnRange
        ]);
      } catch (e) {
        this.setErrorMessage(e.message);
        return false;
      }
      try {
        await this.checkNotifications([
          this.selectedProductType,
          this.selectedProductId,
          this.selectedGroupId,
          this.tnRange
        ]);
      } catch (e) {
        this.setErrorMessage(e.message);
        return false;
      }
      chi.modal(document.getElementById("tooltip-10")).show();
      return true;
    },
    checkAndSetNotifications(): boolean {
      if (EmpValidations.checkForNullAndEmpty(this.selectedNotifs)) {
        this.setErrorMessage(
          "Please select notification(s) from the Notification Table."
        );
        return false;
      }
      for (const key in this.selectedNotifs) {
        this.selectedNotifications[key] = this.selectedNotifs[key].notification;
      }
      return true;
    },
    setDataForDelete(emailId: string) {
      let deleteAllNotifications = false;
      let deleteAllSiteNotifications = false;
      if (this.level == "Tenant") {
        deleteAllNotifications = true;
      } else if (this.level == "sites") {
        deleteAllSiteNotifications = true;
      }
      const xml = new Utility().prepareEmpDataForDelete(
        this.customer,
        this.selectedProductId,
        this.selectedProductType,
        this.loggedInUser.id,
        deleteAllNotifications,
        emailId,
        this.selectedGroupName,
        this.selectedGroupId,
        deleteAllSiteNotifications,
        this.tnRange
      );
      this.sendEmpMsg(xml);
      chi.modal(document.getElementById("tooltip-10")).hide();
    }
  },
  computed: {
    ...mapGetters([
      "isProductIdSelected",
      "customer",
      "notifications",
      "selectedProductType",
      "selectedProductId",
      "notificationsOriginalGroup",
      "API_DATA",
      "notificationSelected",
      "loggedInUser",
      "encryptedData"
    ])
  }
})
export default class DeleteNotifications extends Vue {}
