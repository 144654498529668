import { createLogger, Module } from "vuex";
import Customer, { Product, Group } from "@/models/customer";
import config from "@/utilities/config";
import Utility from "@/utilities/utility";
import { find } from "lodash";
import User from "@/models/user";
import AxiosInstance from "@/utilities/http";
import ErrorHandler from "@/utilities/errorHandling";
import * as _ from "lodash";

const customerModule: Module<any, any> = {
  state: {
    loggedInUser: new User("", "", false, false),
    customer: new Customer("", "", []),
    selectedProductId: " - Select - ",
    groups: [],
    tns: [],
    validatedUser: false,
    allowChange: false
  },
  mutations: {
    setCustomer(state, data: Customer) {
      state.customer = data;
    },
    setLoggedInUser(state, data: User) {
      state.loggedInUser = data;
    },
    updateSelectedProductId(state, id: string) {
      state.selectedProductId = id;
    },
    setGroups(state, data: any) {
      for (const index in state.customer.products) {
        if (state.customer.products[index].id === data.productId) {
          state.customer.products[index].groups = data.groups;
          const groups = [];
          for (const group of data.groups) {
            groups.push({ value: group.id, label: group.name });
          }
          state.groups = groups;
          console.log(index, data.groups, groups);
          break;
        }
      }
    },
    setTns(state, data: any) {
      const product = find(state.customer.products, { id: data.productId });
      const group = find(product.groups, { id: data.siteId });
      group.tns = data.tns;
      state.tns = data.tns;
    },
    setvalidateToken(state, data) {
      if (!_.isEmpty(data)) {
        state.validatedUser = data.validation;
      }
    },
    setAllowChange(state, data) {
      state.allowChange = _.isEqual(data, "Y") ? true : false;
    }
  },
  actions: {
    setCustomer({ commit }, customer: Customer) {
      commit("setCustomer", customer);
    },
    setLoggedInUser({ commit }, user: User) {
      commit("setLoggedInUser", user);
    },
    updateSelectedProductId({ commit }, id) {
      commit("updateSelectedProductId", id);
    },
    fetchGroupsForSelectedProduct({ dispatch, getters }) {
      dispatch("fetchGroups", [
        {
          id: getters.selectedProductId,
          type: getters.selectedProductType
        },
        getters.loggedInUser.id
      ]);
    },
    async fetchGroups({ commit, getters }, [product, userId]) {
      let isRequiredToPopulateGroupNames = false;
      if (
        getters.selectedProductGroups &&
        getters.selectedProductGroups.length > 0
      ) {
        if (getters.selectedProductGroups[0].name !== "") {
          commit("setGroups", {
            productId: product.id,
            groups: getters.selectedProductGroups
          });
          return;
        } else {
          isRequiredToPopulateGroupNames = true;
        }
      }
      const env = config.env;
      const url = `${Utility.getConfig(env, "VUE_APP_VOICE_911_URL")}${
        product.type
      }/${product.id}/${userId}?queryParam=sites`;
      await AxiosInstance.createInstance()
        .get(url)
        .then(response => {
          const groups: Group[] = [];
          for (const siteInstance of response.data.sites) {
            if (isRequiredToPopulateGroupNames) {
              for (const group of getters.selectedProductGroups) {
                if (group.id === siteInstance.siteId) {
                  groups.push(
                    new Group(siteInstance.siteId, siteInstance.siteName)
                  );
                  break;
                }
              }
            } else {
              groups.push(
                new Group(siteInstance.siteId, siteInstance.siteName)
              );
            }
          }
          commit("setGroups", { productId: product.id, groups: groups });
        })
        .catch(err => {
          commit("setGroups", { productId: product.id, groups: [] });
          throw err;
        });
    },
    async fetchTns(
      { commit, getters },
      [productType, productId, siteId, userId]
    ) {
      const product = find(getters.customer.products, { id: productId });
      if (product) {
        const group = find(product.groups, { id: siteId });
        if (group && group.tns !== undefined) {
          commit("setTns", {
            productId: productId,
            siteId: siteId,
            tns: group.tns
          });
          return;
        }
      }

      const env = config.env;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}site/${productType}/${productId}/${siteId}/${userId}?queryParam=tns`;
      await AxiosInstance.createInstance()
        .get(url)
        .then(response => {
          const tns: string[] = [];
          for (const tnInstance of response.data.tnInstances) {
            tns.push(tnInstance.tn);
          }
          commit("setTns", { productId: productId, siteId: siteId, tns: tns });
        })
        .catch(err => {
          commit("setTns", { productId: productId, siteId: siteId, tns: [] });
          throw err;
        });
    },
    async validateToken({ commit }, [userId, token]) {
      const env = config.env;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_TOKEN_SERVICE"
      )}/validation/${userId}/${token}`;
      await AxiosInstance.createInstance2()
        .get(url)
        .then(response => {
          commit("setvalidateToken", response.data);
        })
        .catch(err => {
          throw new Error(ErrorHandler.getErrorMessage(err));
        });
    },
    async getAllowChange({ commit }, [productType, productId]) {
      const env = config.env;
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}product/${productType}/${productId}`;
      await AxiosInstance.createInstance()
        .get(url)
        .then(response => {
          console.log("response allow change " + JSON.stringify(response.data));
          commit("setAllowChange", response.data.allowChange);
        })
        .catch(error => {
          throw new Error(ErrorHandler.getErrorMessage(error));
        });
    },
    async toggleSwitch({ commit }, [productType, productId, allowChange]) {
      const env = config.env;
      const allowChangeVal = allowChange == true ? "Y" : "N";
      const url = `${Utility.getConfig(
        env,
        "VUE_APP_VOICE_911_URL"
      )}productInstance/indicator/${productType}/${productId}`;
      await AxiosInstance.createInstance()
        .put(url, { allowChange: allowChangeVal })
        .then(response => {
          commit("setAllowChange", allowChangeVal);
          console.log("toggle switch response " + JSON.stringify(response));
        })
        .catch(error => {
          commit("setAllowChange", !allowChange);
          throw new Error(ErrorHandler.getErrorMessage(error));
        });
    }
  },
  getters: {
    customer: state => state.customer,
    loggedInUser: state => state.loggedInUser,
    selectedProductId: state => state.selectedProductId,
    groups: state => state.groups,
    selectedGroup911Tns: state => state.tns,
    allowChange: state => state.allowChange,
    isProductIdSelected: state => {
      if (
        state.selectedProductId &&
        state.selectedProductId !== " - Select - "
      ) {
        return true;
      } else {
        return false;
      }
    },
    selectedProductType: state => {
      let productType = "";
      if (state.customer.isHasSingleProduct()) {
        productType = state.customer.products[0].type;
      } else {
        const products: Product[] = state.customer.products;
        products.forEach(product => {
          if (state.selectedProductId === product.id) {
            productType = product.type;
          }
        });
      }
      return productType;
    },
    productIds: state => {
      const productIds: string[] = [];
      if (state.customer.isHasSingleProduct()) {
        state.selectedProductId = state.customer.products[0].id;
        productIds.push(state.selectedProductId);
      } else {
        state.customer.products.forEach((product: { id: string }) => {
          productIds.push(product.id);
        });
      }
      return productIds;
    },
    selectedProductGroups: state => {
      for (const product of state.customer.products) {
        if (product.id === state.selectedProductId) {
          return product.groups;
        }
      }
      return [];
    },
    selectedProductGroupsItems: state => {
      const items: Array<{ value: string; label: string }> = [];
      for (const product of state.customer.products) {
        if (product.id === state.selectedProductId) {
          for (const group of product.groups) {
            items.push({ value: group.id, label: group.name });
          }
        }
      }
      return items;
    },
    userValidated: state => state.validatedUser
  }
};
export default customerModule;
