export class Group {
  constructor(
    private _id: string,
    private _name: string,
    private _tns?: string[] | undefined
  ) {}
  get id() {
    return this._id;
  }
  set id(id: string) {
    this._id = id;
  }
  get name() {
    return this._name;
  }
  set name(name: string) {
    this._name = name;
  }
  get tns(): string[] | undefined {
    return this._tns;
  }
  set tns(tns: string[] | undefined) {
    this._tns = tns;
  }
}

export class Product {
  constructor(
    private _id: string,
    private _type: string,
    private _groups: Group[]
  ) {
    // Short hand declaraion of class properties
  }
  get id() {
    return this._id;
  }
  set id(id: string) {
    this._id = id;
  }
  get type() {
    return this._type;
  }
  set type(type: string) {
    this._type = type;
  }

  get groups() {
    return this._groups;
  }
  set groups(groups: Group[]) {
    this._groups = groups;
  }
}

export default class Customer {
  constructor(
    private _id: string,
    private _name: string,
    private _products: Product[]
  ) {
    // Short hand declaraion of class properties
  }

  get id() {
    return this._id;
  }
  set id(id: string) {
    this._id = id;
  }
  get name() {
    return this._name;
  }
  set name(name: string) {
    this._name = name;
  }
  get products() {
    return this._products;
  }
  set products(products: Product[]) {
    this._products = products;
  }
  isHasSingleProduct(): boolean {
    if (this.products && this.products.length === 1) {
      return true;
    } else {
      return false;
    }
  }
}
