<template>
  <chi-button color="primary" @click="exportToExcel"
    >Export Notification Data
  </chi-button>
</template>
<script lang="ts">
import ErrorHandler from "@/utilities/errorHandling";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
import xlsx from "xlsx";

@Options({
  props: [
    /*"json_to_sheet", "aoa_to_sheet", "sheetName", "fileName"*/
    "level",
    "header",
    "sheetName",
    "fileName"
  ],
  data() {
    return {
      jsonToSheet: {},
      /*aoa_to_sheet:""*/
      flag: false
    };
  },
  methods: {
    ...mapActions([
      "hideMessage",
      "setErrorMessage",
      "setWarningMessage",
      "setInfoMessage",
      "getAllTnNotifications"
    ]),
    async exportToExcel() {
      if (this.level == "Tenant") {
        await this.exportNotifications();
        if (!this.flag) {
          return;
        }
      }
      const wb = xlsx.utils.book_new(); // Creates workbook
      //let ws: any;
      const wsSHeet = xlsx.utils.sheet_add_aoa(wb, this.header);
      const ws = xlsx.utils.sheet_add_json(wsSHeet, this.jsonToSheet, {
        origin: "A2",
        skipHeader: true
      });
      xlsx.utils.book_append_sheet(wb, ws, this.sheetName); // Appends a sheet to workbook with sheet name
      xlsx.writeFile(wb, this.fileName); // Downloads the workbook as a file on browser
    },
    async exportNotifications() {
      console.log("TBD: export");
      this.hideMessage();
      try {
        await this.$store.dispatch("getAllTnNotifications", [
          this.selectedProductType,
          this.selectedProductId,
          this.loggedInUser.id
        ]);
        this.flag = true;
      } catch (e) {
        this.flag = false;
        if (e.message.indexOf("404", 0) > -1) {
          this.setInfoMessage(e.message);
        } else {
          this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
        }
      }

      this.jsonToSheet = this.exportNotif;
    }
  },
  computed: {
    ...mapGetters([
      "exportNotif",
      "selectedProductType",
      "selectedProductId",
      "loggedInUser"
    ])
  }
})
export default class ExportToExcel extends Vue {}
</script>
