<template>
  <div class="tn">
    <div class="-m--2">
      <message-alert alertClass="-mb--2" />
      <ContentHeader :level="level" />
      <div v-if="isProductIdSelected">
        <div class="-d--flex">
          <div class="-flex--fill">
            <p class="-text--h5">
              Telephone Number Notification Data Management
            </p>
          </div>
          <div class="-flex--fill">
            <import-from-excel
              @on-import="onImport"
              :header="header"
              :raw="raw"
              skipHeader="true"
              :isDisabled="loggedInUser.isViewOnly"
            />
          </div>
        </div>

        <p class="-text--sm -mt--0">
          This section is used to manage 911 capable telephone number's
          notifications
        </p>
        <br />
        <div class="-d--flex -justify-content--start">
          <div class="-pr--2">
            <chi-label>Select Group:</chi-label>
          </div>
          <div class="-pr--2">
            <drop-down
              :items="groups"
              @on-select="onSelectGroup($event)"
              show-item-value="true"
              show-search="true"
              data-target="#modalId2"
              id="tooltip-81"
              ref="dropDownRef"
              @set-dispose="disposeModal"
            />

            <div class="chi-backdrop -closed">
              <div class="chi-backdrop__wrapper">
                <section
                  id="modalId2"
                  class="chi-modal"
                  role="dialog"
                  aria-label="Modal description"
                  aria-modal="true"
                >
                  <header class="chi-modal__header">
                    <h2 class="chi-modal__title">Confirm to navigate</h2>
                    <button
                      class="chi-button -icon -close"
                      data-dismiss="modal"
                      aria-label="Close"
                      @click="close()"
                    >
                      <div class="chi-button__content">
                        <i class="chi-icon icon-x"></i>
                      </div>
                    </button>
                  </header>
                  <div class="chi-modal__content">
                    <p class="-text -m--0">
                      Changes detected for Group {{ selectedGroupId }}. <br />
                      Do you wish to navigate to another group before applying
                      the changes?
                      <br />
                      Click on "Continue" to continue the navigation or on
                      "Cancel" to stay on this group.
                    </p>
                  </div>
                  <footer class="chi-modal__footer">
                    <button
                      class="chi-button"
                      data-dismiss="modal"
                      @click="close()"
                    >
                      Cancel
                    </button>
                    <button
                      class="chi-button -primary"
                      data-dismiss="modal"
                      @click="setGroupId()"
                    >
                      Continue
                    </button>
                  </footer>
                </section>
              </div>
            </div>
          </div>
          <div class="-pr--2">
            <chi-button
              color="primary"
              :disabled="selectedGroupId === ''"
              @click="get()"
              >Get
            </chi-button>
          </div>
          <div v-if="showNotifications && showGroupTns">
            <chi-button color="primary" @click="enterManually()"
              >Enter Telephone Numbers Manually
            </chi-button>
          </div>
          <div v-if="showNotifications && !showGroupTns">
            <chi-button color="primary" @click="populateTns()"
              >Show Me My Numbers
            </chi-button>
          </div>
        </div>
        <br />
        <div v-if="showNotifications" class="chi-grid">
          <div
            class="chi-col -w--6"
            v-if="showNotifications && !showGroupTns"
            style="min-width: 400px;"
          >
            <!-- <AddTelephoneNumbers
              :npa="npa"
              :nxx="nxx"
              :to="to"
              :from="from"
              v-on:telephone-numbers="addTelephoneNumbers"
            /> -->
            <comma-seperated-tns @on-change="onChangeCommaSeperatedTns" />
            <br />
            <tn-ranges title="TN Ranges" @on-change="onChangeTnRanges" />
          </div>
          <div class="chi-col -w--6" v-if="showNotifications && showGroupTns">
            <MultiSelector
              :items="selectedGroup911Tns"
              @on-select="onSelectTns($event)"
              showSelected="true"
              titleForItems="911 Capable Telephone Numbers"
              titleForSelected="Selected Telephone Numbers"
            />
          </div>
          <div class="chi-col -w--6">
            <Notifications
              :global-notifications="notifications"
              disable-defaults="true"
              level="tn"
              show-delete="false"
              ref="notificationsRef"
              v-on:selected-notifs="setSelectedNotifications"
              :isViewOnly="loggedInUser.isViewOnly"
            />
          </div>
        </div>
        <br />
        <div v-if="showNotifications" class="-d--flex -flex--row">
          <div class="-pr--2">
            <ReplyEmail
              :level="level"
              :npa="npa"
              :nxx="nxx"
              :to="to"
              :from="from"
              :telephoneNumbers="telephoneNumbers"
              :selectedGroupId="selectedGroupId"
              :showGroupTns="showGroupTns"
              :selectedTns="selectedTns"
              :displayValue="displayVal"
              v-model:selectedNotifs="selectedNotifs"
              :isViewOnly="loggedInUser.isViewOnly"
              popUpStatement="Confirm to submit"
              :selectedGroupName="selectedGroupName"
              v-on:set-change="setChange"
            />
          </div>
          <div class="-pr--2">
            <DeleteNotifications
              :level="level"
              :npa="npa"
              :nxx="nxx"
              :to="to"
              :from="from"
              :telephoneNumbers="telephoneNumbers"
              :selectedGroupId="selectedGroupId"
              :showGroupTns="showGroupTns"
              :selectedTns="selectedTns"
              :displayValue="displayValDelete"
              :isViewOnly="loggedInUser.isViewOnly"
              :selectedGroupName="selectedGroupName"
              popUpStatement="Confirm to delete"
            />
          </div>
        </div>
      </div>
    </div>
    <br />
    <div v-if="isProductIdSelected">
      <div>
        <!--<chi-button color="primary" variant="flat" @click="tenantLevel()"
          >Enable unique email notification settings at the Tenant level
        </chi-button>-->
        <route-to-site-or-tn-or-tenant
          :isChanged="isChangeNeeded"
          level="Tenant"
          v-on:tenant-level="tenantLevel"
          modalId="modal-30"
          tooltipId="tooltip-30"
          level2="Telephone number"
          classType="-flat"
          title="Enable unique email notification settings at the Tenant level"
          :npa="npa"
          :nxx="nxx"
          :to="to"
          :from="from"
          :telephoneNumbers="telephoneNumbers"
          :selectedTns="selectedTns"
          v-model:selectedNotifs="selectedNotifs"
        />
      </div>
      <div>
        <!--<chi-button color="primary" variant="flat" @click="siteLevel()"
          >Enable unique email notification settings at the Group / Location
          level
        </chi-button>-->
        <route-to-site-or-tn-or-tenant
          :isChanged="isChangeNeeded"
          level="Group / Location"
          v-on:site-level="siteLevel"
          modalId="modal-31"
          tooltipId="tooltip-31"
          level2="Telephone number"
          classType="-flat"
          title="Enable unique email notification settings at the Group / Location level"
          :npa="npa"
          :nxx="nxx"
          :to="to"
          :from="from"
          :telephoneNumbers="telephoneNumbers"
          :selectedTns="selectedTns"
          v-model:selectedNotifs="selectedNotifs"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapActions } from "vuex";
import ContentHeader from "@/components/ContentHeader.vue";
import MessageAlert from "@/components/MessageAlert.vue";
import DropDown from "@/components/DropDown.vue";
import Notifications from "@/components/Notifications.vue";
import AddTelephoneNumbers from "@/components/AddTelephoneNumbers.vue";
import ErrorHandler from "@/utilities/errorHandling";
import MultiSelector from "@/components/MultiSelector.vue";
import ReplyEmail from "@/components/ReplyEmail.vue";
import CommaSeperatedTns from "@/components/CommaSeperatedTns.vue";
import TnRanges from "@/components/TnRanges.vue";
import TnRange from "@/models/tnRange";
import * as _ from "lodash";
import ImportFromExcel from "@/components/ImportFromExcel.vue";
import Utility from "@/utilities/utility";
import config from "@/utilities/config";
import { utils } from "xlsx/types";
import DeleteNotifications from "@/components/DeleteNotifications.vue";
import RouteToSiteOrTnOrTenant from "@/components/RouteToSiteOrTnOrTenant.vue";
declare let chi: any;

import {
  Payload,
  Command,
  SecurityHeader,
  BusinessKeys,
  BusinessKey
} from "@/models/commandForImport";
import Customer from "@/models/customer";
import * as js2parser from "js2xmlparser";
import EmpValidations from "@/utilities/empValidations";
import { SiteInstanceVO } from "@/models/notificationPreference";

@Options({
  components: {
    ContentHeader,
    DropDown,
    MessageAlert,
    AddTelephoneNumbers,
    Notifications,
    MultiSelector,
    ReplyEmail,
    CommaSeperatedTns,
    TnRanges,
    ImportFromExcel,
    DeleteNotifications,
    RouteToSiteOrTnOrTenant
  },
  data() {
    return {
      selectedGroupId: "",
      selectedGroupName: "",
      showNotifications: false,
      showGroupTns: false,
      selectedTns: [],
      telephoneNumbers: "",
      npa: [],
      nxx: [],
      to: [],
      from: [],
      tns: "",
      level: "telephones",
      displayVal: "Apply notifications to the Selected Telephone Numbers",
      displayValDelete:
        "Delete default notifications from Selected Telephone Numbers",
      selectedNotifs: [],
      header: [
        "action",
        "tn",
        "group",
        "smsnotifications",
        "emailnotifications"
      ],
      raw: false,
      isChangeNeeded: true
    };
  },
  methods: {
    ...mapActions([
      "hideMessage",
      "setErrorMessage",
      "setSuccessMessage",
      "fetchTns",
      "fetchNotifications",
      "setInfoMessage",
      "sendEMPmsg",
      "checkNotifications",
      "checkNotificationsInProgress",
      "encryptNotifications",
      "getEncryptedData"
    ]),
    async onImport(data: any) {
      console.log("imported data: ", JSON.stringify(data.jsonData, null, 2));
      if (data.invalidData) {
        this.$store.commit(
          "SET_API_DATA_IMPORT_ERROR",
          "Invalid file, please select a .xlsx or .xls file."
        );
        return;
      }
      if (_.isEmpty(data.jsonData)) {
        this.$store.commit(
          "SET_API_DATA_IMPORT_ERROR",
          "Data not found or Invalid data."
        );
        return;
      } else {
        const isFederalAccount = Utility.isFederalAccount(
          this.selectedProductType
        );
        const util = new Utility();
        const dataDel = data.jsonData.filter(
          (data: any) =>
            _.isEmpty(data.action) &&
            !_.isEqual(_.toLower(data.action), "apply all") &&
            !_.isEqual(_.toLower(data.action), "remove all")
        );

        if (!_.isEmpty(dataDel)) {
          this.$store.commit(
            "SET_API_DATA_IMPORT_ERROR",
            "Action field is either empty or contains value other than Apply All or Remove All."
          );
          return;
        }
        const applyAllData = data.jsonData.filter((data: any) =>
          _.isEqual(_.toLower(data.action), "apply all")
        );

        const deletAllData = data.jsonData.filter((data: any) =>
          _.isEqual(_.toLower(data.action), "remove all")
        );
        let emailVal = data.emailVal;
        if (isFederalAccount) {
          await this.getEncryptedData([
            emailVal,
            this.loggedInUser.id,
            this.selectedProductType,
            this.selectedProductId
          ]);
          emailVal = this.encryptedData;
        }
        let notificationPreference: any;
        let deletePref: any;
        let returnVal = false;
        if (!_.isEmpty(applyAllData)) {
          notificationPreference = util.prepareEMPdataForAPply(
            this.customer,
            this.selectedProductId,
            emailVal,
            applyAllData
          );
          if (_.isString(notificationPreference)) {
            this.$store.commit(
              "SET_API_DATA_IMPORT_ERROR",
              notificationPreference
            );
            return;
          }
          const sites: SiteInstanceVO[] =
            notificationPreference.productInstance.sites;
          let site: any;
          const tns = [];
          let tnInstance: any;
          for (site of sites) {
            const siteId = site.siteId;
            const tnInstances = site.tnInstances;
            for (tnInstance of tnInstances) {
              if (isFederalAccount) {
                try {
                  await this.encryptNotifications([
                    tnInstance.notifications,
                    this.loggedInUser.id,
                    this.selectedProductType,
                    this.selectedProductId
                  ]);
                } catch (e) {
                  this.setErrorMessage(e.message);
                  return;
                }

                tnInstance.notifications = this.encryptedNotifs;
              }

              tns.push(tnInstance.tn);
            }
            try {
              await this.checkNotificationsInProgress([
                this.selectedProductType,
                this.selectedProductId,
                siteId,
                tns
              ]);
            } catch (e) {
              this.setErrorMessage(e.message);
              return;
            }
          }
        }

        if (!_.isEmpty(deletAllData)) {
          const sites = util.createDeleteSitesArray(deletAllData);
          if (_.isString(sites)) {
            this.$store.commit("SET_API_DATA_IMPORT_ERROR", sites);
            return;
          }

          for (const key in sites) {
            try {
              await this.checkNotifications([
                this.selectedProductType,
                this.selectedProductId,
                sites[key].siteId,
                sites[key].tns
              ]);
            } catch (e) {
              this.setErrorMessage(e.message);
              return;
            }

            try {
              await this.checkNotificationsInProgress([
                this.selectedProductType,
                this.selectedProductId,
                sites[key].siteId,
                sites[key].tns
              ]);
            } catch (e) {
              this.setErrorMessage(e.message);
              return;
            }
          }

          deletePref = util.prepareEmpDataForRemove(
            this.customer,
            this.selectedProductId,
            emailVal,
            sites
          );
        }

        const payloadObj = new Payload();
        if (deletePref != null && deletePref != undefined) {
          payloadObj.deleteData = JSON.stringify(deletePref);
        }
        if (
          notificationPreference != null &&
          notificationPreference != undefined
        ) {
          payloadObj.addData = JSON.stringify(notificationPreference);
        }

        if (_.isEmpty(payloadObj)) {
          this.$store.commit(
            "SET_API_DATA_IMPORT_ERROR",
            "Data not found or Invalid data."
          );
          return;
        }
        const xml = this.prepareEmpDataForAddANdDelete(
          this.customer,
          this.selectedProductId,
          this.selectedProductType,
          this.loggedInUser.id,
          payloadObj
        );
        returnVal = await this.sendEmpMsg(xml);

        if (returnVal) {
          this.setSuccessMessage(
            "Request sent successfully. Your request will be completed within 24 hours and you will receive one email on completion of the request."
          );
        }
      }
    },
    prepareEmpDataForAddANdDelete(
      customer: Customer,
      selectedProductId: string,
      selectedProductType: string,
      userId: string,
      payloadObj: Payload
    ) {
      let id: string;
      const env = config.env;

      if (
        customer != null &&
        customer != undefined &&
        customer.id != undefined
      ) {
        id = customer.id;
      } else {
        id = selectedProductId;
      }

      const businesskey1 = new BusinessKey("Customer", "customerId", id);
      const businessKey2 = new BusinessKey(
        "ProductInstance",
        "productInstanceId",
        selectedProductId
      );

      const businessKey3 = new BusinessKey(
        "ProductInstance",
        "productInstanceType",
        selectedProductType
      );

      const businessKey4 = new BusinessKey("User", "userId", userId);
      const businessKeyArray: BusinessKey[] = [
        businesskey1,
        businessKey2,
        businessKey3,
        businessKey4
      ];

      const businessKeys = new BusinessKeys(businessKeyArray);
      const timeVal = new Date().toISOString();
      const messageType = `${Utility.getConfig(env, "VUE_APP_MSG_TYPE")}`;
      const commandType = `${Utility.getConfig(
        env,
        "VUE_APP_ADD_DELETE_COMMAND_TYPE"
      )}`;
      const messageSource = `${Utility.getConfig(env, "VUE_APP_MSG_SOURCE")}`;

      const commandVal = new Command(
        messageType,
        commandType,
        timeVal,
        id + "-" + timeVal,
        messageSource,
        businessKeys,
        payloadObj
      );
      const xml = js2parser.parse("command", commandVal);
      return xml;
    },
    async sendEmpMsg(xml: any) {
      try {
        await this.$store.dispatch("sendEMPmsg", xml);
        return true;
      } catch (e) {
        this.setErrorMessage(this.API_DATA.save.message);
      }
      return false;
    },
    onChangeCommaSeperatedTns(val: string) {
      this.telephoneNumbers = val;
      if (!_.isEmpty(this.telephoneNumbers)) {
        this.isChangeNeeded = true;
      }
    },
    onChangeTnRanges(val: TnRange[]) {
      const valArray = val.filter(
        obj =>
          !_.isEmpty(obj.npa) ||
          !_.isEmpty(obj.nxx) ||
          !_.isEmpty(obj.from) ||
          !_.isEmpty(obj.to)
      );
      this.npa = valArray.map(obj => obj.npa);
      this.nxx = valArray.map(obj => obj.nxx);
      this.from = valArray.map(obj => obj.from);
      this.to = valArray.map(obj => obj.to);
      this.isChangeNeeded = true;
    },
    setSelectedNotifications(selectedNotifs: []) {
      this.selectedNotifs = selectedNotifs;
    },
    async tenantLevel() {
      this.$router.push("/");
    },
    async siteLevel() {
      this.$router.push("site");
    },
    setChange() {
      this.isChangeNeeded = false;
    },
    async onSelectGroup(item: any) {
      await chi.modal(document.getElementById("tooltip-81")).dispose();
      this.clear();
      await this.hideMessage();
      //chi.modal(document.getElementById("tooltip-80")).dispose();
      this.itemVal = item;
      console.log(
        "selected group " +
          this.selectedGroupId +
          " and item val : " +
          this.itemVal.value
      );
      if (
        this.isChangeNeeded &&
        !_.isEmpty(this.selectedGroupId) &&
        this.selectedGroupId !== item.value &&
        this.checkIfTnsAndNotifsSelected()
      ) {
        console.log("value of isChanged is: " + this.isChanged);
        chi.modal(document.getElementById("tooltip-81")).show();
      } else {
        console.log("chi log");
        if (this.selectedGroupId !== item.value) {
          this.selectedGroupId = item.value;
          this.selectedGroupName = item.label;
          this.showNotifications = false;
        }
      }
    },
    async setGroupId() {
      if (this.selectedGroupId !== this.itemVal.value) {
        this.selectedGroupId = this.itemVal.value;
        this.selectedGroupName = this.itemVal.label;
        this.showNotifications = false;
        this.showNoDefaultsFoundNote = false;
      }
      this.isChanged = false;
      await chi.modal(document.getElementById("tooltip-81")).hide();
    },
    async close() {
      await chi.modal(document.getElementById("tooltip-81")).hide();
      await this.$refs.dropDownRef.setItemValue(
        this.selectedGroupId,
        this.selectedGroupName
      );
    },
    async disposeModal() {
      await chi.modal(document.getElementById("tooltip-81")).dispose();
    },
    get() {
      this.hideMessage();
      this.isChangeNeeded = false;
      if (this.selectedGroupId == "") {
        return;
      }
      try {
        if (this.showGroupTns) {
          this.fetchTns([
            this.selectedProductType,
            this.selectedProductId,
            this.selectedGroupId,
            this.loggedInUser.id
          ]);
        }
      } catch (e) {
        this.setErrorMessage(ErrorHandler.getDisplayErrorMessage(e));
      }
      this.showNotifications = true;
    },
    populateTns() {
      this.hideMessage();
      this.isChangeNeeded = false;
      this.npa = [];
      this.nxx = [];
      this.to = [];
      this.from = [];
      this.telephoneNumbers = "";
      this.showGroupTns = true;
      this.fetchTns([
        this.selectedProductType,
        this.selectedProductId,
        this.selectedGroupId
      ]);
    },
    enterManually() {
      this.hideMessage();
      this.showGroupTns = false;
      this.selectedTns = "";
    },
    apply() {
      this.hideMessage();
      this.tns = "";
      if (!this.splitNumbersAndValidate(this.telephoneNumbers)) {
        return false;
      }

      if (
        this.nxx == null &&
        this.npa == null &&
        this.to == null &&
        this.from == null
      ) {
        console.log("not found");
      } else {
        const npaLen = this.npa.length;
        const nxxLen = this.nxx.length;
        const toLen = this.to.length;
        const fromLen = this.from.length;

        const maxlen = this.calculateMaxLen(npaLen, nxxLen, toLen, fromLen);
        for (let i = 0; i < maxlen; i++) {
          if (
            !this.checkTnRanges(this.npa[i], "NPA", 3, "") ||
            !this.checkTnRanges(this.nxx[i], "NXX", 3, "") ||
            !this.checkTnRanges(this.from[i], "FROM", 4, "") ||
            !this.checkTnRanges(this.to[i], "TO", 4, this.from[i])
          ) {
            return;
          } else {
            if (this.tns != null && this.tns != "") {
              this.tns += ",";
            }
            this.tns += this.npa[i] + this.nxx[i] + this.from[i];
          }
        }
      }
    },
    checkTnRanges(val: string, type: string, tnLen: number, fromVal: string) {
      let errorMsg = "";
      if (val == null || val == "") {
        errorMsg =
          type + " cannot be empty . Fill it with " + tnLen + " digits.";
      } else if (!parseInt(val)) {
        errorMsg =
          type + " can only contain digits." + "Change the value : " + val;
      } else if (val.length != tnLen) {
        errorMsg =
          type +
          " with value " +
          val +
          " is of incorrect length. Enter " +
          tnLen +
          " digits.";
      } else if (type == "TO" && val < fromVal) {
        errorMsg =
          " FROM value cannot be greater than TO value for FROM value : " +
          fromVal +
          " and TO value : " +
          val;
      }
      if (errorMsg != "") {
        this.setErrorMessage(errorMsg);
        return false;
      }

      return true;
    },
    calculateMaxLen(
      num1: number,
      num2: number,
      num3: number,
      num4: number
    ): number {
      let maxlen = num1;
      if (num2 > maxlen) {
        maxlen = num2;
        if (num3 > maxlen) {
          maxlen = num3;
        } else if (num4 > maxlen) {
          maxlen = num4;
        }
      } else if (num3 > maxlen) {
        maxlen = num3;
        if (num4 > maxlen) {
          maxlen = num4;
        }
      } else if (num4 > maxlen) {
        maxlen = num4;
      }

      return maxlen;
    },
    splitNumbersAndValidate(telephoneNumbers: string) {
      if (telephoneNumbers != "" && telephoneNumbers != null) {
        const arr: string[] = telephoneNumbers.split(",");
        if (arr == null) {
          return this.validateTelephoneNumber(telephoneNumbers);
        } else {
          for (const index in arr) {
            if (!this.validateTelephoneNumber(arr[index])) {
              return false;
            }
          }
        }
      }
      return true;
    },
    validateTelephoneNumber(telephoneNumber: string) {
      if (
        !parseInt(telephoneNumber) ||
        telephoneNumber.startsWith("0") ||
        telephoneNumber.length != 10
      ) {
        this.setErrorMessage(
          "Invalid format for telephone number: " +
            telephoneNumber +
            ". Telephone number should be of 10 digits,can't start with 0 or have special characters."
        );
        return false;
      }
      return true;
    },
    onSelectTns(data: any) {
      this.selectedTns = data;
      this.isChangeNeeded = true;
    },
    addTelephoneNumbers(telephoneNumbers: string) {
      this.telephoneNumbers = telephoneNumbers;
    },
    clear() {
      this.telephoneNumbers = "";
      this.npa = [];
      this.nxx = [];
      this.to = [];
      this.from = [];
      this.tns = "";
    },
    checkIfTnsAndNotifsSelected() {
      if (
        !_.isEmpty(this.selectedNotifs) &&
        (!_.isEmpty(this.selectedTns) ||
          !_.isEmpty(this.telephoneNumbers) ||
          (!this.checkLengthAndEmpty(this.npa) &&
            !this.checkLengthAndEmpty(this.nxx) &&
            !this.checkLengthAndEmpty(this.from) &&
            !this.checkLengthAndEmpty(this.to)))
      ) {
        console.log(
          "telephone numbers : " +
            JSON.stringify(this.nxx) +
            JSON.stringify(this.npa) +
            JSON.stringify(this.to) +
            JSON.stringify(this.from) +
            this.npa.length
        );
        return true;
      }
      return false;
    },
    checkLengthAndEmpty(val: string[]) {
      if (
        EmpValidations.checkForNullAndEmpty(val) ||
        (val.length == 1 && val[0] == "")
      ) {
        return true;
      }
      return false;
    }
  },
  computed: {
    ...mapGetters([
      "isProductIdSelected",
      "selectedProductId",
      "selectedProductType",
      "groups",
      "notificationsOriginalGroup",
      "notifications",
      "notificationsGroup",
      "selectedGroup911Tns",
      "loggedInUser",
      "customer",
      "API_DATA",
      "loggedInUser",
      "encryptedNotifs",
      "encryptedData"
    ])
  }
})
export default class TelephoneNumber extends Vue {}
</script>
<style scoped></style>
