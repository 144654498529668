<template>
  <div>
    <div class="chi-card -portal">
      <div class="chi-card__header -sm">
        <div
          class="chi-card__title -d--flex -justify-content--between"
          style="width: 100%;"
        >
          <div>{{ titleForItems }}</div>
          <div class="chi-form__item">
            <div class="chi-checkbox">
              <input
                type="checkbox"
                class="chi-checkbox__input"
                id="selectAll"
                @change="selectAll"
              />
              <label class="chi-checkbox__label" for="selectAll"
                >Select All</label
              >
            </div>
          </div>
        </div>
      </div>
      <div class="chi-card__content">
        <div class="chi-card__caption">
          <div>
            <div class="chi-input__wrapper -icon--left " role="search">
              <input
                class="chi-input"
                type="text"
                placeholder="Search"
                aria-label="Search"
                @keyup="filterItems"
              />
              <i class="chi-icon icon-search"></i>
            </div>
          </div>
          <div
            class="-d--flex -flex--wrap "
            style="border: .0625rem solid #d0d4d9; margin-top:-1px; max-height: 220px; overflow-y: auto;"
          >
            <div
              class="chi-dropdown__menu-item"
              v-for="(item, index) in filteredItems"
              :key="index"
            >
              <div class="chi-checkbox">
                <input
                  v-model="selectedItemValues"
                  :value="item"
                  class="chi-checkbox__input"
                  type="checkbox"
                  :id="'checkbox' + index"
                  @change="onSelect(item)"
                  multi-selector
                />
                <label class="chi-checkbox__label" :for="'checkbox' + index">{{
                  item
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="chi-card -portal" v-if="showSelected === 'true'">
      <div class="chi-card__header -sm">
        <div class="chi-card__title">{{ titleForSelected }}</div>
      </div>
      <div class="chi-card__content">
        <div class="chi-card__caption">
          {{ selectedItemValues.join() }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { remove, sortBy } from "lodash";

@Options({
  props: ["items", "showSelected", "titleForItems", "titleForSelected"],
  data() {
    return {
      filteredItems: [],
      selectedItemValues: []
    };
  },
  methods: {
    onSelect(item: any) {
      this.$emit("on-select", this.selectedItemValues);
    },
    selectAll(event: any) {
      const checkboxes = document.querySelectorAll("input[multi-selector]");
      for (const index in checkboxes) {
        const value = (checkboxes[index] as HTMLInputElement).value;
        if (value !== undefined) {
          (checkboxes[index] as HTMLInputElement).checked =
            event.target.checked;
          if (event.target.checked) {
            if (this.selectedItemValues.indexOf(value) < 0) {
              this.selectedItemValues.push(value);
            }
          } else {
            remove(this.selectedItemValues, function(n) {
              return n === value;
            });
          }
        }
      }
      this.$emit("on-select", this.selectedItemValues);
    },
    filterItems(event: any) {
      this.filteredItems = this.items.filter(function(item: any) {
        return item.indexOf(event.target.value) > -1;
      });
    }
  },
  mounted() {
    this.filteredItems = sortBy(this.items);
  },
  watch: {
    items(newVal, oldVal) {
      this.filteredItems = sortBy(newVal);
    }
  }
})
export default class MultiSelector extends Vue {}
</script>
