import xlsx from "xlsx";
import axios from "axios";
import * as _ from "lodash";

export default class ExportToExcel {
  static exportToExcel(
    header: [[]],
    fileName: string,
    sheetName: string,
    jsonData: string[][],
    colInfoArray: []
  ) {
    const wb = xlsx.utils.book_new(); // Creates workbook
    const wsSHeet = xlsx.utils.sheet_add_aoa(wb, header);

    if (!_.isEmpty(colInfoArray)) {
      wsSHeet["!cols"] = colInfoArray;
    }
    const ws = xlsx.utils.sheet_add_json(wsSHeet, jsonData, {
      origin: "A2",
      skipHeader: true
    });

    xlsx.utils.book_append_sheet(wb, ws, sheetName); // Appends a sheet to workbook with sheet name
    xlsx.writeFile(wb, fileName);
  }

  static exportToExcel2(
    header: [[]],
    fileName: string,
    sheetName: string,
    jsonData: []
  ) {
    const url = "/Bulk_911_Notifications_Template_1_without_action.xlsx"; // Files placed in the public directory can be accessed directly

    axios
      .get(url, { responseType: "arraybuffer" })
      .then(res => {
        const data = new Uint8Array(res.data);

        const wb = xlsx.read(data, { type: "array" });
        const firstSheet = wb.Sheets[wb.SheetNames[0]];
        const ws = xlsx.utils.sheet_add_json(firstSheet, jsonData, {
          origin: "A2",
          skipHeader: true
        });
        xlsx.utils.book_append_sheet(wb, firstSheet); // Appends a sheet to workbook with sheet name
        xlsx.writeFile(wb, fileName); // Downloads the workbook as a file on browser
      })
      .catch(err => {
        console.log(err);
      });
  }

  static exportToExcel3(
    header: [[]],
    fileName: string,
    sheetName: string,
    jsonData: []
  ) {
    const url = "/bulk_export.html";

    axios
      .get(url, { responseType: "document" })
      .then(res => {
        const data = document.getElementById("bulk_export");
        const wb = xlsx.utils.table_to_book(data);
        // Appends a sheet to workbook with sheet name
        xlsx.writeFile(wb, fileName);
      })
      .catch(err => {
        console.log(err);
      });
  }
}
