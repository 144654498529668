export default class Spinner {
  static start() {
    const targetNode = document.getElementById("app");
    const spinner = document.createElement("chi-spinner");
    spinner.setAttribute("color", "light");
    spinner.setAttribute("backdrop", "");
    targetNode?.appendChild(spinner);
    console.log("Spinner started: ");
  }

  static stop() {
    const targetNode = document.getElementById("app");
    const spinners = document.getElementsByTagName("chi-spinner");
    if (spinners && spinners.length > 0) {
      for (const spinner of spinners) targetNode?.removeChild(spinner);
    }
    console.log("Spinner stopped: ");
  }
}
