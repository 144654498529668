import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Tenant from "@/views/Tenant.vue";
import Site from "@/views/Site.vue";
import TelephoneNumber from "@/views/TelephoneNumber.vue";
import store from "@/store/index";
import Utility from "@/utilities/utility";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "App",
    component: Tenant
  },
  {
    path: "/site",
    name: "site",
    component: Site
  },
  {
    path: "/tn",
    name: "tn",
    component: TelephoneNumber
  },
  {
    path: "/testDropDown",
    name: "testDropDown",
    component: import("@/views/TestDropDown.vue")
  },
  {
    path: "/testMultiSelector",
    name: "testMultiSelector",
    component: import("@/views/TestMultiSelector.vue")
  },
  {
    path: "/testExport",
    name: "testExport",
    component: import("@/views/TestExportToExcel.vue")
  },
  {
    path: "/testImport",
    name: "testImport",
    component: import("@/views/TestImportFromExcel.vue")
  },
  {
    path: "/testTnRanges",
    name: "testTnRanges",
    component: import("@/views/TestTnRanges.vue")
  },
  {
    path: "/testCommaSeperatedTns",
    name: "testCommaSeperatedTns",
    component: import("@/views/TestCommaSeperatedTns.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  store.dispatch("hideMessage");
  /* console.log("router.beforeEach: from: ", JSON.stringify(from, null, 2));
  console.log("router.beforeEach: to: ", JSON.stringify(to, null, 2)); */
  if(to.name === "site" || to.name === "tn"){
    store.dispatch("fetchGroupsForSelectedProduct");
  }
  next();
});

export default router;
