import { render } from "./RouteToSiteOrTnOrTenant.vue?vue&type=template&id=29285a75"
import script from "./RouteToSiteOrTnOrTenant.vue?vue&type=script&lang=ts"
export * from "./RouteToSiteOrTnOrTenant.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "29285a75"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('29285a75', script)) {
    api.reload('29285a75', script)
  }
  
  module.hot.accept("./RouteToSiteOrTnOrTenant.vue?vue&type=template&id=29285a75", () => {
    api.rerender('29285a75', render)
  })

}

script.__file = "src/components/RouteToSiteOrTnOrTenant.vue"

export default script