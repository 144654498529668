import { render } from "./Tenant.vue?vue&type=template&id=8462d338"
import script from "./Tenant.vue?vue&type=script&lang=ts"
export * from "./Tenant.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "8462d338"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8462d338', script)) {
    api.reload('8462d338', script)
  }
  
  module.hot.accept("./Tenant.vue?vue&type=template&id=8462d338", () => {
    api.rerender('8462d338', render)
  })

}

script.__file = "src/views/Tenant.vue"

export default script