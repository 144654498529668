<template>
  <!-- Trigger -->
  <button
    id="modal-trigger-10"
    class="chi-button chi-modal__trigger -primary -flat"
    data-target="#modal-10"
    @click="close()"
    :disabled="isDisabled"
  >
    Import From Excel
  </button>

  <!-- Modal -->
  <div class="chi-backdrop -closed">
    <div class="chi-backdrop__wrapper">
      <section
        id="modal-10"
        class="chi-modal -portal"
        role="dialog"
        aria-label="Modal description"
        aria-modal="true"
      >
        <header class="chi-modal__header">
          <h2 class="chi-modal__title">Import From Excel</h2>
          <button
            class="chi-button -icon -close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
          >
            <div class="chi-button__content">
              <i class="chi-icon icon-x"></i>
            </div>
          </button>
        </header>
        <div class="chi-modal__content">
          <div
            v-if="API_DATA.import.error"
            class="chi-alert -danger -lg -b--danger-light -bg--danger-lighter"
            role="alert"
          >
            <i class="chi-alert__icon chi-icon icon-circle-x"></i>
            <div class="chi-alert__content">
              <p class="chi-alert__title -text--lg">Error</p>
              <p class="chi-alert__text">
                {{ API_DATA.import.message }}
              </p>
            </div>
          </div>
          <div class="-d--flex">
            <div class="-flex--fill">
              <p class="-text -m--0">Please upload valid excel file.</p>
            </div>
            <div class="-flex--fill">
              <!-- <form
                method="get"
                action="../templates/Bulk_911_Notifications_Template_2_with_action.xlsx"
              >
                <button type="submit">Template.xlsx</button>
              </form> -->
              <a
                href="Bulk_911_Notifications_Template_2_with_action.xlsx"
                download="Template.xlsx"
                >Template.xlsx</a
              >
            </div>
          </div>
          <br />
          <input
            type="file"
            class="chi-file-input"
            id="file01"
            aria-label="Choose file"
            ref="fileInput"
          />
          <label for="file01">{{ fileLabel }}</label>
          <br />
          <chi-label for="reply_email" required>Reply Email: </chi-label>
          <input
            type="text"
            class="chi-input"
            placeholder="Enter Email. "
            id="reply_email"
            v-model="emailVal"
          />
          <p class="-text--info -text--sm">
            (Once applied, this request may take upto 24 hrs to complete.)
          </p>
        </div>
        <footer class="chi-modal__footer">
          <button
            class="chi-button -primary -lg -text--uppercase"
            @click="importFromExcel"
          >
            Import
          </button>
        </footer>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import xlsx from "xlsx";
import MessageAlert from "@/components/MessageAlert.vue";
import { mapGetters } from "vuex";
import Utility from "@/utilities/utility";
import * as _ from "lodash";

declare let chi: any;

@Options({
  props: ["header", "raw", "skipHeader", "isDisabled"], // both header and raw are mandatory properties
  data() {
    return {
      jsonData: {},
      emailVal: "",
      fileLabel: "No file chosen",
      invalidData: false
    };
  },
  components: {
    MessageAlert
  },
  methods: {
    close() {
      this.$store.commit("SET_API_DATA_IMPORT_DEFAULT");
      this.emailVal = "";
      this.jsonData = {};
      this.fileLabel = "No file chosen";
    },
    async importFromExcel() {
      this.$store.commit("SET_API_DATA_IMPORT_DEFAULT");
      if (_.isEmpty(this.emailVal)) {
        this.$store.commit(
          "SET_API_DATA_IMPORT_ERROR",
          "Reply Email value cannot be empty."
        );
        return;
      }
      const val = Utility.validateEmail(this.emailVal);
      if (!_.isEmpty(val)) {
        this.$store.commit("SET_API_DATA_IMPORT_ERROR", val);
        return;
      }
      await this.$emit("on-import", {
        jsonData: this.jsonData,
        emailVal: this.emailVal.trim(),
        invalidData: this.invalidData
      });
      if (!this.API_DATA.import.error) {
        chi.modal(document.getElementById("modal-trigger-10")).hide();
      }
    }
  },
  mounted() {
    // Modal Window'
    this.fileLabel = "No file chosen";
    chi.modal(document.getElementById("modal-trigger-10"));

    // Upload file
    const inputFiles = document.querySelectorAll(
      'input[type="file"].chi-file-input'
    );
    Array.prototype.forEach.call(inputFiles, input => {
      const label = input.nextElementSibling;

      input.addEventListener("change", (e: Event) => {
        let fileName: string | undefined = "";
        let file: File | null | undefined;

        const inputElement = e.target;
        if (inputElement instanceof HTMLInputElement) {
          if (inputElement.files && inputElement.files.length > 1) {
            fileName = inputElement.files.length + " files selected";
          } else {
            fileName = inputElement.value.split("\\").pop();
          }
          file = inputElement.files?.item(0);
        }
        if (fileName) {
          this.fileLabel = fileName;
        }
        console.log("file type is " + file?.type);
        if (
          file?.type !=
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
          file?.type != "application/vnd.ms-excel"
        ) {
          this.jsonData = {};
          this.invalidData = true;
          return;
        } else {
          this.invalidData = false;
        }
        console.log("file name " + fileName);
        // Reading file content into workbook
        const reader = new FileReader();

        reader.onload = pe => {
          if (pe.target?.result instanceof ArrayBuffer) {
            const data = new Uint8Array(pe.target?.result);
            const workbook = xlsx.read(data, { type: "array" });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const sheetData = xlsx.utils.sheet_to_json(firstSheet, {
              header: this.header,
              raw: this.raw
            });
            if (this.skipHeader === "true") {
              sheetData.splice(0, 1); // removing the header
            }
            this.jsonData = sheetData;
          }
        };
        if (file instanceof File) {
          reader.readAsArrayBuffer(file);
        }

        this.$refs.fileInput.value = "";
      });
    });
  },
  computed: {
    ...mapGetters(["API_DATA"])
  }
})
export default class ImportFromExcel extends Vue {}
</script>
