const environment = process.env.NODE_ENV || "development";

const config = {
  development: require("../../config/dev.js"),
  dev: require("../../config/dev.js"),
  prod: require("../../config/prod.js"),
  test1: require("../../config/test1.js"),
  test2: require("../../config/test2.js"),
  e2e: require("../../config/e2e.js"),
  env: environment
};

export default config;
